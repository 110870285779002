import { AppDispatch, RootState } from "../../../../../Util/Redux/Schema";
import { Grid, TextareaAutosize, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { fillGratitudeResponse } from "../../../../../Util/Redux/Middleware";
import { updateGoldenHourCompletion } from "../../../../../Util/Redux/AsyncMiddleware";

export const CheckinEditView = () => {
  const gratitude1Ref = useRef<HTMLDivElement | null>(null);
  const gratitude2Ref = useRef<HTMLDivElement | null>(null);
  const gratitude3Ref = useRef<HTMLDivElement | null>(null);
  const [editableIdx, setEditableIdx] = useState<number | null>(null);
  const gratitudeResponse = useSelector(
    (state: RootState) => state.dailyCheckin.gratitudes
  );

  const handleBoxClick = (event: MouseEvent) => {
    const isInsideBox1 =
      gratitude1Ref?.current?.contains(event.target as Node) ||
      gratitude1Ref?.current?.contains(event.target as Node);
    const isInsideBox2 =
      gratitude2Ref?.current?.contains(event.target as Node) ||
      gratitude2Ref?.current?.contains(event.target as Node);
    const isInsideBox3 =
      gratitude3Ref?.current?.contains(event.target as Node) ||
      gratitude3Ref?.current?.contains(event.target as Node);
    setEditableIdx(
      isInsideBox1 ? 0 : isInsideBox2 ? 1 : isInsideBox3 ? 2 : null
    );
  };

  // Add a click event listener to the document to handle page clicks
  useEffect(() => {
    document.addEventListener("click", handleBoxClick);
    return () => {
      document.removeEventListener("click", handleBoxClick);
    };
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  const [gratitude1, setGratitude1] = useState(gratitudeResponse[0]);
  const [gratitude2, setGratitude2] = useState(gratitudeResponse[1]);
  const [gratitude3, setGratitude3] = useState(gratitudeResponse[2]);
  const mindGoldenHour = useSelector(
    (state: RootState) => state.wellbeing.mind.completed
  );
  const handleChange = (fn: (text: string) => void, text: string) => {
    fn(text);
  };
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    ref: React.MutableRefObject<HTMLInputElement | null>
  ) => {
    const newEditableIdx = editableIdx === 2 ? null : (editableIdx || 0) + 1;
    if (event.key === "Enter") {
      dispatch(fillGratitudeResponse([gratitude1, gratitude2, gratitude3]));
      setEditableIdx(newEditableIdx);
      event.preventDefault(); // Prevent new line
      const isComplete =
        gratitude1.length > 0 && gratitude2.length > 0 && gratitude3.length > 0;
      if (mindGoldenHour !== isComplete) {
        dispatch(updateGoldenHourCompletion("Mind", isComplete));
      }
    }
  };

  useEffect(() => {
    if (editableIdx === 0 && gratitude1Ref.current) {
      gratitude1Ref.current.focus();
    } else if (editableIdx === 1 && gratitude2Ref.current) {
      gratitude2Ref.current.focus();
    } else if (editableIdx === 2 && gratitude3Ref.current) {
      gratitude3Ref.current.focus();
    } else {
      gratitude3Ref.current?.blur();
    }
  }, [editableIdx]);
  const emotion = useSelector((state: RootState) =>
    state.dailyCheckin.emotions ? state.dailyCheckin.emotions[0] : ""
  );
  return (
    <Grid
      container
      item
      xs
      direction="column"
      sx={{
        backgroundColor: "#F8F8F8",
        borderRadius: "8px",
        paddingLeft: "8px",
      }}
    >
      <Grid container item xs direction="column" height="100%">
        <Grid container item xs direction="column">
          <Grid container item xs direction="column">
            {[
              [gratitude1, setGratitude1, gratitude1Ref],
              [gratitude2, setGratitude2, gratitude2Ref],
              [gratitude3, setGratitude3, gratitude3Ref],
            ].map((gratitudeData, idx) => {
              const [gratitude, setGratitudeFn, ref] = gratitudeData;
              return (
                <Grid container item xs key={idx} alignItems="center">
                  <Grid container item sx={{ width: "16px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontFamily: "arial",
                        color: "#5C4033",
                        marginRight: "8px",
                      }}
                    >
                      {`${idx + 1}.`}
                    </Typography>
                  </Grid>
                  <Grid container item xs>
                    <TextareaAutosize
                      ref={ref}
                      inputRef={ref}
                      value={gratitude}
                      maxRows={2}
                      style={{
                        fontFamily: "arial",
                        color: "#0A090B",
                        fontWeight: 450,
                        backgroundColor: "transparent",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "-0.05px",
                        width: "100%",
                        border: "none",
                        outline: "none",
                        resize: "none",
                        maxHeight: "32px",
                        minHeight: "32px",
                        paddingTop: "12px",
                        // borderBottom: "1px solid black",
                        // padding: 0,
                      }}
                      onChange={(e) =>
                        handleChange(setGratitudeFn, e?.target.value)
                      }
                      onKeyDown={(e) => handleKeyDown(e, ref)}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export function QuestionText(props: {
  text: string;
  icon?: boolean;
  children?: React.ReactNode;
}) {
  return (
    // <Grid item xs={2}>
    <Grid
      container
      item
      alignItems="center"
      marginBottom="12px"
      alignItems="center"
    >
      <Typography
        // marginBottom="12px"
        sx={{
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "22px",
          fontFamily: "arial",
          color: "#5C4033",
        }}
      >
        {props.text}
      </Typography>
      {props.children}
    </Grid>
    // </Grid>
  );
}
