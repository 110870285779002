import { Check, CheckRounded, Circle, Star } from '@mui/icons-material';
import { Grid, Tooltip, Typography } from '@mui/material';
import {
	getCtaColor,
	getGoldenHourColor,
	getSentimentColor,
	getWellbeingImage
} from '../../../Util/Helper';

import { RootState } from '../../../Util/Redux/Schema';
import { propsToClassKey } from '@mui/styles';
import rocket from '../../../assets/images/svg-files/rocket.svg';
import starColored from '../../../../assets/images/svg-files/starColored.svg';
import { useSelector } from 'react-redux';

export default function WellbeingStatsView() {
	const week = useSelector((state: RootState) => state.stats.weeklyWellbeing);
	const dalyWellbeing = useSelector(
		(state: RootState) => state.wellbeing.taskCompletion
	);
	const activity = [...week, dalyWellbeing];

	const weekday = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
	const goldstars = activity.map(
		(day) => day.mind === true && day.body === true && day.spirit === true
	);
	const presenceColor = getGoldenHourColor('Spirit')['color'];
	const learnColor = getGoldenHourColor('Mind')['color'];
	const moveColor = getGoldenHourColor('Body')['color'];
	const colors = [presenceColor, learnColor, moveColor];
	const today = new Date().getDay();
	return (
		<Grid
			container
			item
			alignItems="center"
			justifyContent="center"
			// sx={{ margin: '0 -6px' }}
		>
			{weekday.map((day, index) => {
				return (
					<Grid
						container
						item
						xs
						sx={{
							backgroundColor: goldstars[index] ? '#FCF3D5' : ''
						}}
						borderRadius="16px"
						direction="column"
						justifyContent="center"
						alignItems="center"
						marginRight={index < 6 ? '8px' : ''}
					>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							direction="column"
						>
							{/* <Grid container width="8px" height="8px">
								{activity[index] && (
									<CheckRounded
										style={{ width: '8px', height: '8px' }}
									/>
								)}
							</Grid> */}
							<Grid
								container
								item
								height="2.4px"
								marginTop="4px"
								width="2.4px"
								marginBottom="4px"
								sx={{
									backgroundColor:
										today === index + 1
											? '#959397'
											: undefined,
									borderRadius: '50%'
								}}
							/>
							{/* <Grid
								container
								width="20px"
								height="20px"
								justifyContent="center"
							>
								{today === index + 1 && (
									<Typography
										sx={{
											fontSize: '12px',
											lineHeight: '16px',
											fontWeight: 450,
											textAlign: 'center',
											color: '#7F7D83'
										}}
									>
										{day[0]}
									</Typography>
								)}
							</Grid> */}
						</Grid>

						{/* <Grid container height="20px" justifyContent="center">
							{today === index + 1 && (
								<Typography
									sx={{
										fontSize: '12px',
										lineHeight: '20px',
										fontWeight: 450,
										textAlign: 'center',
										color: '#7F7D83'
									}}
								>
									{day[0]}
								</Typography>
							)}
						</Grid> */}

						<Grid
							width="32px"
							height="32px"
							container
							item
							alignItems="center"
							justifyContent="center"
							sx={{
								// width: 200px; /* Adjust size as needed */
								// height: 200px; /* Adjust size as needed */
								// border-radius: 50%; /* Makes it circular */

								backgroundImage: activity[index]
									? `conic-gradient(${
											getSentimentColor('positive').color
									  } 0% 33%, ${
											getSentimentColor('negative').color
									  } 33% 66%, ${
											getSentimentColor('reframed').color
									  } 66% 100%)`
									: `conic-gradient(${
											getSentimentColor('positive')
												.backgroundColor
									  } 0% 33%, ${
											getSentimentColor('negative')
												.backgroundColor
									  } 33% 66%, ${
											getSentimentColor('reframed')
												.backgroundColor
									  } 66% 100%)`,
								backgroundColor: '#EFEFEF'
								// position: relative; /* Allows for absolute positioning of labels */
							}}
							padding="4px"
							borderRadius="50%"
						>
							<Grid
								container
								item
								width="32px"
								height="24px"
								alignItems="center"
								justifyContent="center"
							>
								{activity[index] ? (
									<Grid
										container
										item
										alignItems="center"
										justifyContent="center"
										sx={{
											width: '24px',
											height: '24px',
											borderRadius: '50%',
											backgroundColor: 'white'
										}}
										position="relative"
									>
										<img
											src={rocket}
											style={{ width: '16px' }}
										/>
										{/* <Typography
											sx={{
												zIndex: '1',
												fontSize: '12px',
												lineHeight: '20px',
												fontWeight: 550,
												textAlign: 'center',
												color: '#7F7D83'
											}}
										>
											{day[0]}s
										</Typography> */}
									</Grid>
								) : (
									<Grid
										container
										item
										alignItems="center"
										justifyContent="center"
										sx={{
											width: '24px',
											height: '24px',
											borderRadius: '50%',
											backgroundColor: 'white'
										}}
									>
										{/* <img
											src={rocket}
											width="16px"
											style={{ opacity: 0.3 }}
										/>
										{today === index + 1 && (
											<Typography
												sx={{
													position: 'absolute',
													fontSize: '12px',
													lineHeight: '20px',
													fontWeight: 450,
													textAlign: 'center',
													color: '#7F7D83'
												}}
											>
												{day[0]}
											</Typography>
										)} */}
										{today === index + 1 ? (
											<Typography
												sx={{
													fontSize: '12px',
													lineHeight: '20px',
													fontWeight: 450,
													textAlign: 'center',
													color: '#7F7D83'
												}}
											>
												{day[0]}
											</Typography>
										) : (
											<img
												src={rocket}
												width="16px"
												style={{ opacity: 0.3 }}
											/>
										)}
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
}
