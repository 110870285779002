import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import MainLayout from '../components/templates/MainTemplate';
import Settings from '../Tools/Breathe/Settings';

const RelaxPage: React.FC = () => {
	return (
		<MainLayout page="Relax">
			<RelaxBody />
		</MainLayout>
	);
};
const RelaxBody = () => {
	const [timerSize, setTimerSize] = useState(0);
	const handleGridSize = () => {
		const timerGrid = document.getElementById('bodyGrid'); // Replace 'myGrid' with the actual ID or ref of your Grid component
		if (timerGrid) {
			const height = timerGrid.getBoundingClientRect().height;
			setTimerSize(height / 1.5);
		}
	};
	useEffect(() => {
		handleGridSize();
		// You might want to add an event listener for window resize if the grid size can change dynamically
		window.addEventListener('resize', handleGridSize);

		// Cleanup the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleGridSize);
		};
	}, []); // Empty dependency array ensures that this effect runs once after initial render

	return (
		<>
			{/* <Grid container item xs={8} direction="column" justifyContent="center" marginRight={3} >

                                <Grid container item xs >
                            <Grid container item xs height="100%" direction="column" sx={{ backgroundColor: "#F6F9F9", borderRadius: 3, paddingBottom: 3, width: "100%" }}>
                                <Grid container item xs={1} sx={{backgroundColor: "white", borderRadius: 3, borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
                                    
                                </Grid>
                                <Grid container item xs>
                                    <Breathe size={timerSize} />
                                </Grid>
                                
                            </Grid>
                        </Grid>
                        </Grid> */}
			<Grid
				container
				item
				xs
				direction="column"
				id="bodyGrid"
				justifyContent="center"
				alignItems="center"
			>
				<Grid container justifyContent="center" alignItems="center">
					{/* <Grid container item xs={1}  borderRadius={20} alignItems="center" justifyContent="center">
                <DropdownMenu menu={["Breathe", "Meditate"]} selectedMenu={view} setSelectedMenu={setView}/>
            </Grid> */}
				</Grid>
				<Settings size={timerSize} />
			</Grid>
		</>
	);
};

export default RelaxPage;
