import { AnyAction, Dispatch } from 'redux';
import { AppDispatch, RootState } from './Schema';
import { GoldenHourAction, ThoughtsAction } from './Action';
import {
	addSageThought,
	addSageThoughtUuidToThought,
	addThought,
	addThoughtSentiment,
	fetchGoldenHour,
	fetchSentimentScore,
	fetchThoughtNouns,
	fetchThoughts,
	fetchUser,
	getDailyThoughts,
	getMonthlyGoldenHourStats,
	getMonthlyThoughts,
	getThoughtCounts,
	getThoughtNouns,
	getWeeklyGoldenHourStats,
	getWeeklyThoughts,
	removeThought,
	removeThoughtEmotions,
	setAuthenticated,
	setAuthorized,
	setSessionToken,
	updateThought
} from './Middleware';

import { ThunkAction } from 'redux-thunk';
import { UUID } from 'uuid';
import axios from '../axiosConfig';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { useAuth0 } from '@auth0/auth0-react';

export const updateGoldenHourCompletion =
	(type: 'Mind' | 'Body' | 'Spirit', isCompleted: boolean) =>
	async (dispatch: Dispatch<GoldenHourAction>) => {
		const idx = type === 'Mind' ? 0 : type === 'Body' ? 1 : 2;
		axios
			.post('/api/completeGoldenHourActivity', {
				index: idx,
				isCompleted: isCompleted
			})
			.then(function (response) {
				dispatch(fetchGoldenHour(response.data.today));
				// dispatch(getWeeklyGoldenHourStats(response.data.weekly));
			})
			.catch(function (error) {});
	};

export const postThought = (
	message: string
): ThunkAction<void, RootState, null, AnyAction> => {
	return async (dispatch: Dispatch) => {
		axios
			.post(
				'/api/thought',
				{
					text: message
					// timezone: new Date().toUTCString(),
				},
				{ headers: { 'Content-Type': 'application/json' } }
			)
			.then(function (response) {
				dispatch(
					addThoughtSentiment(
						response.data.uuid,
						'',
						response.data.computedTopEmotions
					)
				);
			})
			.catch(function (error) {
				console.log('Error posting thought: ', error);
				dispatch(removeThought());
			});
	};
};

export const postReframedThought = (
	message: string,
	uuid: UUID
): ThunkAction<void, RootState, null, AnyAction> => {
	return async (dispatch: Dispatch) => {
		// axios.post()
		dispatch(updateThought(uuid, { reframed: message }));
	};
};

export const getSageThought =
	(thoughtUuid: UUID): ThunkAction<void, RootState, null, AnyAction> =>
	async (dispatch) => {
		try {
			const response = await axios.get('/api/thought/reframe', {
				params: { thoughtUuid: thoughtUuid }
			});

			if (!response) {
				return; // Exit if the response is empty or undefined
			}

			await dispatch(
				addSageThought(thoughtUuid, {
					uuid: response.data.sageThoughtUuid,
					empathy: response.data.empathy,
					biases: response.data.biases,
					challenge: response.data.challenge,
					reframedThought: response.data.reframedThought,
					healthySuggestions: response.data.healthySuggestions,
					challengeResponse: ''
				})
			);
			await dispatch(
				addSageThoughtUuidToThought(
					thoughtUuid,
					response.data.sageThoughtUuid
				)
			);
		} catch (error) {
			console.log('Error posting thought: ', error);
			dispatch(removeThought());
		}
	};

export const postSearch = async (search: string) => {
	return axios
		.post(
			'/api/thoughts/search',
			{
				query: search
			},
			{ headers: { 'Content-Type': 'application/json' } }
		)
		.then(function (response) {
			return response.data.thoughts;
		})
		.catch(function (error) {
			console.log('Error posting thought: ', error);
			// dispatch(removeThought());
		});
};

// const xyz = () => async (dispatch) => {

export const postEmotions = async (thoughtId: UUID, emotions: string[]) => {
	try {
		const response = await axios.post(
			'/api/thought/emotions',
			{
				thoughtId: thoughtId,
				emotions: emotions
			},
			{ headers: { 'Content-Type': 'application/json' } }
		);

		return response; // Ensure you return the sentiment here
	} catch (error) {
		console.log('Error posting thought: ', error);
		return null;
	}
};

export const login =
	(username: string, password: string) => async (dispatch: AppDispatch) => {
		axios
			.post(
				'/login',
				{
					username: username,
					password: password
				},
				{ headers: { 'Content-Type': 'application/json' } }
			)
			.then(function (response) {
				dispatch(setSessionToken(response.data.token));
				dispatch(setAuthenticated(true));
				axios.defaults.headers['Authorization'] =
					`Bearer ${response.data.token}`;
			})
			.catch(function (error) {});
	};

export const authenticateUser = () => async (dispatch: AppDispatch) => {
	await axios
		.post('/api/pinAuthentication', {
			headers: { 'Content-Type': 'application/json' }
		})
		.then(function (response) {
			dispatch(setAuthenticated(true));
			dispatch(setSessionToken(response.data.token));
			axios.defaults.headers['Authorization-PIN'] =
				`Bearer ${response.data.token}`;
		})
		.catch(function (error) {
			console.log(error.response.status);
			if (error.response.status === 403) {
				dispatch(setAuthenticated(false));
			}
		});
};
export const loginWithPin = (pin: string) => async (dispatch: AppDispatch) => {
	await axios
		.post(
			'/api/pinLogin',
			{
				pin: pin
			},
			{ headers: { 'Content-Type': 'application/json' } }
		)
		.then(function (response) {
			sessionStorage.setItem('isPinAuthorized', 'True');
			dispatch(setAuthorized(true));
		})
		.catch(function (error) {
			if (error.response.status === 401) {
				sessionStorage.setItem('isPinAuthorized', 'False');
				dispatch(setAuthorized(false));
			}
		});
};

export const setBearerTokenToAxios = () => {
	const token = sessionStorage.getItem('bearerToken');
	axios.defaults.headers['Authorization'] = `Bearer ${token}`;
	axios.defaults.headers['TimezoneInfo'] =
		Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getHomepageContents = () => async (dispatch: AppDispatch) => {
	await axios
		.get('/api/homepageContents')
		.then(function (response) {
			dispatch(fetchGoldenHour(response.data.dailyBalance));
			sessionStorage.setItem('homepageFetched', 'True');
		})
		.catch(function (error) {});
};

export const getThoughtsDaily = () => async (dispatch: AppDispatch) => {
	const startDate = convertToTimezoneMidnightIsoString(
		new Date(),
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
	await axios
		.get('/api/thoughts', { params: { startDate: startDate } })
		.then(function (response) {
			dispatch(getDailyThoughts(cloneDeep(response.data.thoughts)));
		});
};

export const getThoughtsMonthly = () => async (dispatch: AppDispatch) => {
	const firstDayOfMonth = new Date(
		new Date().getFullYear(),
		new Date().getMonth(),
		1
	);

	const startDate = convertToTimezoneMidnightIsoString(
		firstDayOfMonth,
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
	await axios
		.get('/api/thoughts', {
			params: {
				startDate: startDate
			}
		})
		.then(function (response) {
			dispatch(getMonthlyThoughts(cloneDeep(response.data.thoughts)));
		});
};

export const getWeeklyStats = () => async (dispatch: AppDispatch) => {
	// if (sessionStorage.getItem('weeklyFetched') === 'True') return;
	await axios
		.get('/api/weeklyStats')
		.then(function (response) {
			// dispatch(getWeeklyThoughts(cloneDeep(response.data.thoughts)));
			dispatch(
				getWeeklyGoldenHourStats(response.data.goldenHourCompletion)
			);
			dispatch(
				getThoughtNouns(
					'Week',
					response.data.positiveThoughtNouns,
					response.data.neutralThoughtNouns,
					response.data.negativeThoughtNouns
				)
			);
			sessionStorage.setItem('weeklyFetched', 'True');
		})
		.catch(function (error) {});
};

export function convertToTimezoneMidnightIsoString(
	date: any,
	timezone: string
) {
	const momentDate = moment(date).tz(timezone);
	const midnight = momentDate.startOf('day');
	return midnight.toISOString();
}

export const getMonthlyStats = () => async (dispatch: AppDispatch) => {
	// if (sessionStorage.getItem('monthlyFetched') === 'True') return;

	axios
		.get('/api/monthlyStats')
		.then(function (response) {
			dispatch(
				getMonthlyGoldenHourStats(response.data.goldenHourCompletion)
			);
			dispatch(
				getThoughtNouns(
					'Month',
					response.data.positiveThoughtNouns,
					response.data.neutralThoughtNouns,
					response.data.negativeThoughtNouns
				)
			);
			sessionStorage.setItem('monthlyFetched', 'True');
		})
		.catch(function (error) {});
};

export const getAllTimeStats = () => async (dispatch: AppDispatch) => {
	axios
		.get('/api/allTimeStats')
		.then(function (response) {
			dispatch(
				getThoughtNouns(
					'All Time',
					response.data.positiveThoughtNouns,
					response.data.neutralThoughtNouns,
					response.data.negativeThoughtNouns
				)
			);
			dispatch(
				getThoughtCounts(
					'All Time',
					response.data.numPositiveThoughts,
					response.data.numNeutralThoughts,
					response.data.numNegativeThoughts
				)
			);
		})
		.catch(function (error) {});
};

export const addToWaitlist =
	(email: string) => async (dispatch: AppDispatch) => {
		axios
			.post('/addEmailToWaitlist', {
				email: email
			})
			.then(function (response) {
				dispatch(
					getThoughtNouns(
						'All Time',
						response.data.positiveThoughtNouns,
						response.data.neutralThoughtNouns,
						response.data.negativeThoughtNouns
					)
				);
				dispatch(
					getThoughtCounts(
						'All Time',
						response.data.numPositiveThoughts,
						response.data.numNeutralThoughts,
						response.data.numNegativeThoughts
					)
				);
			})
			.catch(function (error) {});
	};

export const getThoughtEntries = async (month: string) => {
	function getLastDayOfMonth(year: number, month: number) {
		return new Date(year, month + 1, 0).getDate();
	}
	const monthIndex = {
		Jan: 0,
		Feb: 1,
		Mar: 2,
		Apr: 3,
		May: 4,
		Jun: 5,
		Jul: 6,
		Aug: 7,
		Sep: 8,
		Oct: 9,
		Nov: 10,
		Dec: 11
	}[month];
	if (monthIndex === undefined) {
		throw `Enter valid month ${month}`;
	}
	const thisYear = new Date().getFullYear();
	const firstDayOfMonth = new Date(thisYear, monthIndex, 1);
	const lastDayOfMonth = new Date(
		thisYear,
		monthIndex,
		getLastDayOfMonth(thisYear, monthIndex)
	);
	const startDate = convertToTimezoneMidnightIsoString(
		firstDayOfMonth,
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
	const endDate = convertToTimezoneMidnightIsoString(
		lastDayOfMonth,
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
	setBearerTokenToAxios();
	const data = axios
		.get('/api/thoughts', {
			params: {
				startDate: startDate,
				endDate: endDate
			}
		})
		.then(function (response) {
			// console.log('Return', response.data.thoughts);
			return cloneDeep(response.data.thoughts);
		})
		.catch((err) => console.log(err));
	return data;
	// return response.data.thoughts;
};
