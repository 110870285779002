import * as React from 'react';

import {
	ArrowDownward,
	ArrowDownwardRounded,
	ArrowDropDownRounded,
	ChevronLeft,
	ChevronRight,
	CloudRounded,
	TimelineRounded
} from '@mui/icons-material';
import {
	Button,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Typography
} from '@mui/material';
import {
	GradientCloudIcon,
	PhSortAscendingLight,
	PhSortDescendingLight
} from '../../assets/svgFiles';
import { alpha, styled } from '@mui/material/styles';
import { getCtaColor, getSentimentColor, getYear } from '../../Util/Helper';

import { MenuProps } from '@mui/material/Menu';
import dropdownSvg from '../../assets/images/svg-files/dropdown.svg';

export const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'left'
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === 'light'
				? 'rgb(55, 65, 81)'
				: theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0'
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5)
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				)
			}
		}
	}
}));

export default function MonthDropdown(props: {
	selected: string;
	onUpdateMonth: (month: string) => void;
	descending: boolean;
	onReverseSortOrder: () => void;
	onEnterSearch: (query: string) => void;
	sentimentFilterIndex: number;
	onChangeSentimentView: () => void;
	monthView: string;
	onChangeMonthView: (month: string) => void;
}) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const onSelect = (value: string) => {
		handleClose();
		props.onUpdateMonth(value);
	};
	const currMonthIdx = new Date().getMonth();
	return (
		<Grid container item width="120px" alignItems="center">
			<Button
				aria-controls={open ? 'demo-customized-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				variant="contained"
				disableElevation
				onClick={handleClick}
				// endIcon={<img src={dropdownSvg} />}
				sx={{
					backgroundColor: 'white',
					textTransform: 'none',
					height: '36px',
					'&:hover': {
						backgroundColor: getCtaColor(0.1)
					},
					borderRadius: '100px'
				}}
			>
				{/* <TimelineRounded sx={{ color: 'gray' }} /> */}
				<Typography
					sx={{
						marginLeft: '8px',
						fontWeight: 450,
						fontSize: '12px',
						lineHeight: '16px',
						letterSpacing: '-0.05px',
						fontFamily: 'arial',
						color: '#4F4D55'
					}}
				>
					{props.selected}, {getYear()}
				</Typography>
				<ArrowDropDownRounded sx={{ color: getCtaColor() }} />
			</Button>

			<StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<Grid container item xs direction="column" padding="8px 4px">
					{/* <Grid
						container
						item
						xs
						sx={{ paddingRight: '8px' }}
						marginBottom="16px"
					>
						<Grid container item xs justifyContent="center">
							<Typography
								sx={{ fontSize: '14px', color: '#0A090B' }}
							>
								{props.selected}, 2024
							</Typography>
						</Grid>
					</Grid> */}
					<Tooltip
						title={
							props.sentimentFilterIndex === 0
								? 'all thoughts'
								: props.sentimentFilterIndex === 1
								  ? 'joyful thoughts'
								  : props.sentimentFilterIndex === 2
								    ? 'harsh thoughts'
								    : 'unclassified thoughts'
						}
					></Tooltip>

					<Grid container item direction="column">
						{[
							['Jan', 'Feb', 'Mar'],
							['Apr', 'May', 'Jun'],
							['Jul', 'Aug', 'Sep'],
							['Oct', 'Nov', 'Dec']
						].map((months, rowIdx) => (
							<Grid
								container
								item
								xs
								key={rowIdx}
								// sx={{ backgroundColor: "red" }}
								// margin="4px 4px"
							>
								{months.map((month, colIdx) => (
									<MenuItem
										// margin={0}
										sx={{
											margin: '4px',
											width: '56px',
											'&.Mui-selected': {
												backgroundColor: getCtaColor(),
												color: 'white',
												'&:hover': {
													backgroundColor:
														getCtaColor()
												}
											}
										}}
										key={rowIdx * 3 + colIdx}
										onClick={() => onSelect(month)}
										disableRipple
										style={{ borderRadius: '4px' }}
										selected={props.selected === month}
										disabled={
											currMonthIdx < rowIdx * 3 + colIdx
										}
									>
										<Typography
											sx={{
												fontWeight: 450,
												fontSize: '14px',
												lineHeight: '20px',
												letterSpacing: '-0.05px',
												fontFamily: 'arial'
											}}
										>
											{month}
										</Typography>
									</MenuItem>
								))}
							</Grid>
						))}
					</Grid>
				</Grid>
			</StyledMenu>
		</Grid>
	);
}
