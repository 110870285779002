import Grid from '@mui/material/Grid';
import { Button, Divider, IconButton, Tooltip } from '@mui/material';
import breathe from '../../../assets/images/breathe.png';
import inhale from '../../../assets/images/inhale.png';
import hold from '../../../assets/images/hold.png';
import exhale from '../../../assets/images/exhale.png';
import { useNavigate } from 'react-router-dom';
import { SubHeader, Title } from '../../../Util/Components/Typography';
import { ReactElement, ReactNode, useState } from 'react';
import { Close, Info } from '@mui/icons-material';
import TooltipIconButton from '../../../components/molecules/TooltipIconButton';
import Instruction from './Instruction';
import Benefits from './Benefits';
import DropdownMenu from '../../DropdownMenu';

const Instructions = () => {
	return (
		<Grid container item xs justifyContent={'center'} alignItems={'center'}>
			<Instruction
				stepNumber={1}
				title="Inhale"
				description="through your nose for 4 seconds."
				imgSrc={inhale}
				currentStepNumber={1}
			/>
			<Instruction
				stepNumber={2}
				title="Hold"
				description="your breath for 7 seconds."
				imgSrc={hold}
			/>
			<Instruction
				stepNumber={3}
				title="Exhale"
				description="completely through your mouth for 8 seconds."
				imgSrc={exhale}
			/>
		</Grid>
	);
};

const PracticeBox = () => {
	const [inPracticeMode, setInPracticeMode] = useState(false);
	const startPracticeMode = () => setInPracticeMode(true);
	return (
		<Grid container item xs>
			<Instruction
				stepNumber={1}
				title="Inhale"
				description="Inhale through your nose for 4 seconds."
				imgSrc={inhale}
			/>
		</Grid>
	);
};

const StartButton = (props: {
	setView: (x: string) => void;
	secondaryAction: string;
}) => {
	return (
		<Grid
			container
			item
			xs={1}
			spacing={3}
			paddingLeft={'10%'}
			paddingRight={'10%'}
			marginTop={1}
		>
			<Grid item xs>
				<Button
					fullWidth
					variant="contained"
					sx={{
						border: '1px solid #A1D0F9',
						borderRadius: 30,
						backgroundColor: '#A1D0F9',
						fontWeight: 'bold',
						fontSize: 15,
						textTransform: 'none',
						'&:hover': {
							backgroundColor: '#A1D0F9'
						}
					}}
					onClick={() => props.setView('start')}
				>
					Start
				</Button>
			</Grid>
		</Grid>
	);
};

const Preset = (props: {
	title: string;
	description: string;
	instructions: ReactElement;
	setActivity: (x: string) => void;
}) => {
	const navigate = useNavigate();
	const [view, setView] = useState('benefits'); // benefits, instructions, start
	let selectedView = <></>;
	let secondaryAction = 'View Instructions';
	if (view === 'start') {
		navigate('/space/breathe');
	} else if (view === 'instructions') {
		selectedView = props.instructions;
		secondaryAction = 'Practice';
	} else {
		selectedView = <Benefits description={props.description} />;
	}
	return (
		<Grid
			container
			item
			xs
			height="100%"
			paddingLeft={5}
			paddingRight={5}
			paddingBottom={3}
		>
			<Grid
				container
				item
				xs
				padding={5}
				direction="column"
				alignItems="flex-start"
				sx={{ backgroundColor: '#f6fafd', borderRadius: 5 }}
			>
				<Grid
					container
					item
					xs={1}
					paddingLeft="5%"
					paddingRight="5%"
					alignItems="center"
					paddingTop="3%"
				>
					<Grid item xs={1} />
					<Grid
						container
						item
						xs
						justifyContent="center"
						alignItems="center"
					>
						<DropdownMenu
							menu={[
								'4-7-8 Breathing',
								'Box Breathing',
								'Diaphragmatic Breathing',
								'Equal Breathing',
								'Alternate Nostril Breathing'
							]}
							selectedMenu={props.title}
							setSelectedMenu={props.setActivity}
						/>
						{/* <Title>{props.title}</Title> */}
					</Grid>
					<Grid container item xs={1} justifyContent="flex-end">
						{view === 'instructions' ? (
							<TooltipIconButton
								title="Close instructions"
								icon={<Close />}
								onClick={() => setView('benefits')}
							/>
						) : (
							<TooltipIconButton
								title="View instructions"
								icon={<Info />}
								onClick={() => setView('instructions')}
							/>
						)}
					</Grid>
				</Grid>

				{selectedView}
				{view === 'benefits' && (
					<StartButton
						setView={setView}
						secondaryAction={secondaryAction}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default Preset;
