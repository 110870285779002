import { AppDispatch, RootState } from "../../../../Util/Redux/Schema";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import NotebookLayout from "../../../templates/NotebookTemplate";
import { updateGoldenHourCompletion } from "../../../../Util/Redux/AsyncMiddleware";

export default function MoveJournal() {
  const [selectedValues, setSelectedValues] = useState([]);
  const isBodyCompleted = useSelector(
    (state: RootState) => state.wellbeing.body.completed
  );
  const dispatch = useDispatch<AppDispatch>();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (isBodyCompleted !== Boolean(selectedValues.length)) {
      dispatch(
        updateGoldenHourCompletion("Body", Boolean(selectedValues.length))
      );
    }
  }, [selectedValues]);
  const handleDelete = (chipToDelete: string) => {
    setSelectedValues((prev) =>
      prev.filter((activity) => activity !== chipToDelete)
    );
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      const newChip = inputValue.trim();
      if (selectedValues.length < 5 && !selectedValues.includes(newChip)) {
        setSelectedValues([...selectedValues, newChip]);
      }
      setInputValue("");
      event.preventDefault();
    }
  };
  return (
    // <NotebookLayout title="Be Active">
    <Grid
      container
      item
      // xs
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "#F8F8F8", borderRadius: "8px", height: "48px" }}
    >
      <Grid
        container
        item
        xs
        direction="column"
        sx={{ height: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid container item xs sx={{ height: "100%" }}>
          <Autocomplete
            clearIcon={false}
            options={[]}
            freeSolo
            fullWidth
            disabled={selectedValues.length > 4}
            // height="100%"
            value={selectedValues}
            multiple
            onChange={(event, newValue) => {
              if (newValue.length > 0) {
                setSelectedValues(newValue);
              }
            }}
            // style={{ width: '240px' }}
            renderTags={(value, props) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...props({ index })}
                  onDelete={() => handleDelete(option)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                onKeyDown={handleKeyDown}
                sx={{
                  "& .MuiInputBase-input": {
                    "&:hover": {
                      cursor: "default",
                    },
                    fontSize: "14px",
                    fontWeight: 450,
                    fontFamily: "arial",
                    color: "#5C4033",
                    lineHeight: "18px",
                  },
                  "& .MuiInputBase-root": {
                    paddingTop: "0px", // Adjust as needed
                    paddingBottom: "0px", // Adjust as needed
                    display: "flex",
                    alignItems: "flex-start",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "100%",
                    "& fieldset": {
                      color: "transparent",
                      border: "0.1px solid transparent",
                      height: "100%",
                    },
                    "&:hover fieldset": {
                      color: "transparent",
                      border: "0.1px solid transparent",
                      height: "100%",
                    },
                    "&.Mui-focused fieldset": {
                      border: "0.1px solid transparent",
                      height: "100%",
                    },
                  },
                }}
                {...params}
                disabled={selectedValues.length > 4}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
    // </NotebookLayout>
    // <Grid sx={{ backgroundColor: 'white', borderRadius: '8px' }}>

    // </Grid>
  );
}
