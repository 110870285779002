import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import {
	StatsCountTypography,
	StatsHeaderTypography
} from '../../Util/Components/Typography';
import { Button, Divider, IconButton } from '@mui/material';
import meditate from '../../assets/images/meditate.png';
import breathe from '../../assets/images/breathe.png';
import FourSevenEight from './Presets/478';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import BoxBreathing from './Presets/BoxBreathing';
import DeepDiaphragmatic from './Presets/DeepDiaphragmatic';

const Preset = (props: { isMeditateView: boolean }) => {
	const navigate = useNavigate();
	return (
		<Grid
			container
			item
			xs
			direction="column"
			sx={{ borderRadius: 10, height: '100%', padding: 5 }}
		>
			{/* <Grid container item xs={1} justifyContent="center" alignItems="center" marginBottom={2}>
      <SubHeader>Breathe</SubHeader>
      </Grid> */}
			<Grid
				container
				item
				xs
				justifyContent="center"
				alignItems="center"
				marginBottom={5}
			>
				<img
					src={props.isMeditateView ? meditate : breathe}
					width="50%"
					alt="relaxing"
				/>
			</Grid>

			<Grid
				container
				item
				xs={1}
				justifyContent="center"
				alignItems="center"
			>
				Inhale: 4 Seconds
			</Grid>
			<Grid
				container
				item
				xs={1}
				justifyContent="center"
				alignItems="center"
			>
				Hold: 7 Seconds
			</Grid>
			<Grid
				container
				item
				xs={1}
				justifyContent="center"
				alignItems="center"
			>
				Exhale: 8 Seconds
			</Grid>
			{/* <Grid container item xs={1} justifyContent="center" alignItems="center">
        Duration: 5 Minutes
      </Grid> */}
			{/* <Grid container item xs={1} justifyContent="center" alignItems="center">
        Ending Sound: Basu
      </Grid> */}
			<Grid container item xs justifyContent="center" alignItems="center">
				<Button
					variant="outlined"
					sx={{ border: '1px solid #A1Df09', borderRadius: 15 }}
					onClick={() =>
						navigate(
							`/${props.isMeditateView ? 'meditate' : 'breathe'}`
						)
					}
				>
					{' '}
					Start{' '}
				</Button>
			</Grid>
			{/* <Grid item xs sx={{borderRadius: 30}}>
        Duration: 5 Minutes
      </Grid> */}
		</Grid>
	);
};

const Stats = () => {
	return (
		<Grid container item xs padding={3} direction="column" marginTop={1}>
			<Grid container item sx={{ borderRadius: 5 }}>
				<Grid
					container
					item
					xs
					direction="column"
					marginRight={1}
					sx={{ borderRadius: '3%' }}
				>
					<Grid
						item
						xs
						direction="column"
						justifyContent="center"
						alignItems="center"
					>
						<Grid
							container
							item
							xs
							justifyContent="center"
							alignItems="center"
						>
							<StatsHeaderTypography>
								Total minutes breathing
							</StatsHeaderTypography>
						</Grid>
						<Grid
							container
							item
							xs
							justifyContent="center"
							alignItems="center"
						>
							<StatsCountTypography>3</StatsCountTypography>
						</Grid>
					</Grid>
					<Grid item xs direction="column">
						<Grid
							container
							item
							xs
							justifyContent="center"
							alignItems="center"
						>
							<StatsHeaderTypography>
								Total breathing exercises completed
							</StatsHeaderTypography>
						</Grid>
						<Grid
							container
							item
							xs
							justifyContent="center"
							alignItems="center"
						>
							<StatsCountTypography>1</StatsCountTypography>
						</Grid>
					</Grid>
				</Grid>
				<Divider orientation="vertical" />
				<Grid
					container
					item
					xs
					direction="column"
					marginLeft={1}
					sx={{ borderRadius: '3%' }}
					justifyContent="center"
				>
					{/* <StatsHeaderTypography>
            Longest Streak
            </StatsHeaderTypography>
            <StatsCountTypography>
            30 days
            </StatsCountTypography> */}
					<Grid item xs direction="column">
						<Grid
							container
							item
							xs
							justifyContent="center"
							alignItems="center"
						>
							<StatsHeaderTypography>
								Longest Streak
							</StatsHeaderTypography>
						</Grid>
						<Grid
							container
							item
							xs
							justifyContent="center"
							alignItems="center"
						>
							<StatsCountTypography>1 day</StatsCountTypography>
						</Grid>
					</Grid>
					<Grid item xs>
						<Grid
							container
							item
							xs
							justifyContent="center"
							alignItems="center"
						>
							<StatsHeaderTypography>
								Current Streak
							</StatsHeaderTypography>
						</Grid>
						<Grid
							container
							item
							xs
							justifyContent="center"
							alignItems="center"
						>
							<StatsCountTypography>1 day</StatsCountTypography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
const Breathe = ({ size }: { size: number }) => {
	const [tabIdx, setTabIdx] = useState(0);
	const [showMeditateView, setShowMeditateView] = useState(false);
	const [activity, setActivity] = useState('4-7-8 Breathing');
	return (
		// <Grid container item xs direction="column" justifyContent="center" alignItems="center" height="100vh">

		<Grid
			container
			item
			xs
			sx={{
				backgroundColor: 'white',
				width: '60%',
				borderRadius: 5
			}}
		>
			{/* <Grid container item xs={1} justifyContent="center" alignItems="center" position="relative" width="100%" >
          <Tab tabIdx={tabIdx} setTabIdx={setTabIdx} tabElements={[<></>,<></>]} tabs={["Meditate", "Breathe"]} />
        </Grid> */}
			<Grid
				container
				item
				xs
				direction="column"
				paddingTop="3%"
				sx={{ backgroundColor: 'white', borderRadius: 5 }}
			>
				{/* <Grid
					container
					item
					xs={1}
					justifyContent="center"
					alignItems="center"
					marginTop={3}
				>
					<Grid item xs={1} />
					<Grid container item xs alignItems="center">
						<StatsCountTypography>
							{showMeditateView ? 'Meditate' : 'Breathe'}
						</StatsCountTypography>
						<IconButton
							onClick={() =>
								setShowMeditateView(!showMeditateView)
							}
						>
							<ChevronRight />
						</IconButton>
					</Grid>
				</Grid> */}
				<Grid
					container
					item
					xs
					justifyContent="center"
					alignItems="center"
					position="relative"
					sx={{ borderRadius: 5, backgroundColor: 'white' }}
				>
					{activity === '4-7-8 Breathing' && (
						<FourSevenEight setActivity={setActivity} />
					)}
					{activity === 'Box Breathing' && (
						<BoxBreathing setActivity={setActivity} />
					)}
					{activity === 'Diaphragmatic Breathing' && (
						<DeepDiaphragmatic setActivity={setActivity} />
					)}
					{activity === 'Equal Breathing' && (
						<DeepDiaphragmatic setActivity={setActivity} />
					)}
					{activity === 'Alternate Nostril Breathing' && (
						<DeepDiaphragmatic setActivity={setActivity} />
					)}

					{/* <VerticalTab
						tabIdx={tabIdx}
						setTabIdx={setTabIdx}
						tabElements={[
							<FourSevenEight />,
							<BoxBreathing />,
							<DeepDiaphragmatic />
						]}
						tabs={[
							'4-7-8',
							'Box',
							'Deep Diaphragmatic',
							'Equal',
							'Alternate Nostril'
						]}
						// showAddButton
					/> */}
				</Grid>
				{/* <Stats /> */}
			</Grid>
		</Grid>
	);
};

export default Breathe;
