import { AppDispatch, RootState, Thought } from '../../../../Util/Redux/Schema';
import { Grid, Typography } from '@mui/material';
import {
	LightningSmallFilled,
	LineMdSunnyFilled,
	LineMdSunnyFilledAnimated,
	MeteoconsLightningBoltFill,
	SunSmallFilled
} from '../../../../assets/svgFiles';
import React, { useRef } from 'react';
import { getCtaColor, getToday, onAddThought } from '../../../../Util/Helper';
import { useDispatch, useSelector } from 'react-redux';

import ChatBar from './Chatbar';
import { Cloud } from '@mui/icons-material';
import Entries from '../../../organisms/Thoughts/ThoughtEntries';
import SagePointCircle from '../../../../app/SagePointCircle';
import SectionHeaderTypography from '../../../atoms/SectionHeaderTypography';
import ThoughtEntry from '../../../organisms/Thoughts/ThoughtEntry';
import ThoughtSentimentBar from '../../../organisms/Thoughts/ThoughtSentimentBar';
import ThoughtsEmptyState from '../../../organisms/Thoughts/ThoughtsEmptyState';
import { postThought } from '../../../../Util/Redux/AsyncMiddleware';
import { propsToClassKey } from '@mui/styles';
import sagePointCoin from '../../../../assets/images/svg-files/sagePointCoin.svg';

export const isSameDay = (date1String: string, date2String: string) => {
	const date1 = new Date(date1String);
	const date2 = new Date(date2String);
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
};

export const ThoughtsEditor: React.FC = () => {
	const boxRef = React.useRef<HTMLDivElement | null>(null);
	const thoughts = useSelector(
		(state: RootState) => state.thoughts.dailyThoughts
	);
	const totalJoy = thoughts.filter(
		(thought) =>
			thought.sentiment?.toLowerCase() === 'positive' || thought.reframed
	).length;
	const totalWithoutSentiment =
		thoughts.length -
		thoughts.filter((thought) => thought.userSelectedEmotions.length > 0)
			.length;
	const totalHarsh = thoughts.filter(
		(thought) => thought.sentiment?.toLowerCase() === 'negative'
	).length;
	const maxSentimentGroup = Math.max(
		totalHarsh,
		totalJoy,
		totalWithoutSentiment
	);
	const priority = {
		joy: 1,
		harsh: 2,
		undefined: 3
	};

	const sortedSentimentGroup = [
		['joy', totalJoy],
		['undefined', totalWithoutSentiment],
		['harsh', totalHarsh]
	].sort((a, b) => {
		// First, sort by number (in descending order)
		if (b[1] !== a[1]) {
			return b[1] - a[1];
		}
		// If numbers are the same, sort by priority
		return priority[a[0]] - priority[b[0]];
	});

	console.log(thoughts.map((t) => t.sentiment));
	console.log(sortedSentimentGroup, totalHarsh);
	return (
		<Grid
			container
			item
			xs={8}
			sx={{ backgroundColor: 'white' }}
			border="1px solid #F1F1F1"
			direction="column"
			marginRight="8px"
			alignItems="center"
			borderRadius="16px"
			justifyContent="flex-start"
			alignContent="flex-start"
			ref={boxRef}
		>
			<Grid
				container
				alignItems="center"
				padding="16px"
				justifyContent="space-between"
			>
				{/* <SectionHeaderTypography title="Today's thoughts" /> */}
				<Grid container item xs>
					<Typography
						sx={{
							textAlign: 'center',
							cursor: 'default',
							fontFamily: 'arial',
							fontSize: '12px',
							lineHeight: '24px',
							fontWeight: 550,
							color: '#345247'
						}}
					>
						{getToday()}
					</Typography>
				</Grid>
				<Grid
					container
					item
					xs={3}
					justifyContent="flex-end"
					alignItems="center"
					// sx={{ backgroundColor: "red" }}
				>
					<Typography
						sx={{
							cursor: 'default',
							fontFamily: 'arial',
							fontSize: '12px',
							lineHeight: '24px',
							color: '#345247',
							textAlign: 'flex-end',
							marginRight: '8px'
						}}
					>
						38 Sage Points
					</Typography>
					<img src={sagePointCoin} width="24px" height="24px" />
					{/* <Grid
						container
						item
						// xs
						width="24px"
						height="24px"
						borderRadius="50%"
						sx={{
							backgroundImage: `linear-gradient(145deg, ${getCtaColor()}, white, ${getCtaColor()})`
						}}
					></Grid> */}
				</Grid>
			</Grid>
			<Grid container item xs direction="column">
				<Typography
					sx={{
						cursor: 'default',
						fontFamily: 'arial',
						fontSize: '12px',
						lineHeight: '24px',
						color: '#345247',
						marginRight: '4px',
						textAlign: 'flex-end',
						paddingLeft: '16px'
					}}
				>
					{sortedSentimentGroup[0][0] === 'harsh'
						? "Today's Sentiment so far: Harsh"
						: sortedSentimentGroup[0][0] === 'joy'
						  ? "Today's Sentiment: Joy"
						  : "Today's Sentiment so far: Undefined"}
				</Typography>

				{/* <ThoughtSentimentBar
					current={maxSentimentGroup}
					total={thoughts.length}
					sortedSentimentGroup={sortedSentimentGroup}
				/> */}
				<Entries thoughts={thoughts} mutable dayView />
				<Grid container padding="16px">
					<ChatBar />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ThoughtsEditor;
