import * as React from 'react';

import {
	ArrowDropDown,
	Cloud,
	Home,
	Lock,
	LockOpen,
	SelfImprovement
} from '@mui/icons-material';
import {
	Avatar,
	Divider,
	Grid,
	IconButton,
	Slide,
	Tooltip
} from '@mui/material';
import {
	fetchThoughts,
	setAuthenticated,
	setAuthorized
} from '../../Util/Redux/Middleware';
import { getCtaColor, getToday } from '../../Util/Helper';
import { useDispatch, useSelector } from 'react-redux';

import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { RootState } from '../../Util/Redux/Schema';
import RotatingHeader from '../pages/LandingPage/RotatingHeader';
import Typography from '@mui/material/Typography';
import { clearPersistedState } from '../../Util/Redux/Store';
import fontQuest from '../../assets/images/fontQuest.png';
import fontSage from '../../assets/images/fontSage.png';
import fontSageThat from '../../assets/images/fontSageThat.png';
import fontSpace from '../../assets/images/fontSpace.png';
import fontVault from '../../assets/images/fontVault.png';
import { headerHeight } from '../../Util/Config';
import helpCenterIcon from '../../assets/images/svg-files/helpCenter.svg';
import homepageTitle from '../../assets/images/homepageTitle.png';
// import lockOpen from '../../assets/images/svg-files/lockOpen.svg';
import lockImg from '../../assets/images/svg-files/lock.svg';
import logoutIcon from '../../assets/images/svg-files/logout.svg';
import sageCircle from '../../assets/images/logo/sageCircle.svg';
import sageJourney from '../../assets/images/fontSageJourney.png';
import sageSpace from '../../assets/images/fontSageSpace.png';
import sageThought from '../../assets/images/fontSageThatThought.png';
// import '@fontsource/fira-code';
import selectedHomeIcon from '../../assets/images/svg-files/homeSelected.svg';
import selectedInsightsIcon from '../../assets/images/svg-files/insightsSelected.svg';
import selectedThoughtsIcon from '../../assets/images/svg-files/thoughtsSelected.svg';
import settingsIcon from '../../assets/images/svg-files/settings.svg';
import subscriptionsIcon from '../../assets/images/svg-files/subscriptions.svg';
import unlock from '../../assets/gifs/unlock.gif';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export interface ToolbarOptionDefinition {
	text: ToolbarOption;
	icon: React.ReactElement;
}

export type ToolbarOption = 'Meditate' | 'Frequency Sound' | 'Lock';

const Bar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onLock = () => {
		sessionStorage.removeItem('isPinAuthorized');
		setAnimateLock(true);
		navigate('/space/gringotts');
	};
	const [animateLock, setAnimateLock] = React.useState(false);
	return (
		<Grid
			container
			item
			// xs
			width="140px"
			// marginRight="24px"
			justifyContent="flex-end"
		>
			{/* <Tooltip title="Breathe">
				<IconButton
					sx={{
						color: '#AEB2BC',
						border: '1px solid #F8F8F8',
						width: '40px',
						height: '40px'
					}}
				>
					<SelfImprovement width="32px" height="32px" />
				</IconButton>
			</Tooltip>

			<Divider
				orientation="vertical"
				sx={{ margin: '0 12px', height: '40px', color: '#ECECED' }}
			/> */}

			<Tooltip title="Lock screen">
				<IconButton
					sx={{
						marginRight: '16px',
						color: '#AEB2BC',
						// border: '1.5px solid #F8F8F8',
						width: '46px',
						height: '46px'
					}}
					onMouseEnter={() => setAnimateLock(true)}
					onMouseLeave={() => setAnimateLock(false)}
					onClick={onLock}
				>
					{/* <Lock /> */}
					{/* {
						animateLock ? <img src={unlock} width="32px" height="32px" /> : (
							<svg xmlns="http://www.w3.org/2000/svg" width={20}
								height={24} viewBox="0 0 24 24">
								<g fill="none" fill-rule="evenodd">
									<path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
									<path fill="#7D7D7D" d="M6 8a6 6 0 1 1 12 0h1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2zm6-4a4 4 0 0 1 4 4H8a4 4 0 0 1 4-4m2 10a2 2 0 0 1-1 1.732V17a1 1 0 1 1-2 0v-1.268A2 2 0 0 1 12 12a2 2 0 0 1 2 2" />
								</g>
							</svg>
						)
					} */}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
					>
						<path
							fill="#7D7D7D"
							d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"
						/>
					</svg>
					{/* <img src={animateLock ? unlock : lockImg} width="28px" height="28px" /> */}
					{/* <LockOpen width="36px" height="36px" /> */}
				</IconButton>
			</Tooltip>
			{/* <AccountMenu /> */}
		</Grid>
	);
};

export function AccountMenu() {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const name = useSelector((selector: RootState) => selector.user.firstName);
	const dispatch = useDispatch();
	const { logout } = useAuth0();
	const userPic = sessionStorage.getItem('userPic');
	const signout = () => {
		dispatch(setAuthorized(false));
		dispatch(setAuthenticated(null));
		dispatch(fetchThoughts([]));
		clearPersistedState();
		sessionStorage.clear();
		logout({
			logoutParams: {
				returnTo: window.location.origin
			}
		});
	};
	return (
		<>
			<Tooltip title="Account">
				<Grid
					container
					item
					xs
					alignItems="center"
					onClick={handleClick}
					sx={{ '&:hover': { cursor: 'pointer' } }}
				>
					<IconButton
						sx={{
							width: '40px',
							height: '40px',
							backgroundColor: '#E4F2Ec'
						}}
						disableRipple
					>
						<a href={userPic} target="_blank" rel="noreferrer">
							<Avatar
								sx={{
									color: '#74A899',
									bgcolor: '#E4F2EC',
									border: '1px solid #F8F8F8',
									width: '40px',
									height: '40px'
								}}
								alt="Uu Profile Picture"
								src={userPic}
							/>
						</a>
					</IconButton>

					<ArrowDropDown
						sx={{ color: '#AEB2BC', marginLeft: '4px' }}
					/>
				</Grid>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 1,
					sx: {
						width: '180px',
						borderRadius: '8px',
						filter: 'drop-shadow(0px 2px 8px #0A090B12)',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1
						},
						'&::before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0
						}
					}
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem onClick={() => navigate('/settings')}>
					<ListItemIcon>
						<img src={settingsIcon} />
					</ListItemIcon>

					<Typography
						sx={{
							color: '#7F7D83',
							fontWeight: 450,
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '-0.5px'
						}}
					>
						Settings
					</Typography>
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<ListItemIcon>
						<img src={subscriptionsIcon} />
					</ListItemIcon>

					<Typography
						sx={{
							color: '#7F7D83',
							fontWeight: 450,
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '-0.5px'
						}}
					>
						Subscriptions
					</Typography>
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<ListItemIcon>
						<img src={helpCenterIcon} />
					</ListItemIcon>

					<Typography
						sx={{
							color: '#7F7D83',
							fontWeight: 450,
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '-0.5px'
						}}
					>
						Help Center
					</Typography>
				</MenuItem>
				<Divider />
				<MenuItem onClick={signout}>
					<ListItemIcon>
						<img src={logoutIcon} />
					</ListItemIcon>
					<Typography
						sx={{
							color: '#F75656',
							fontWeight: 450,
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '-0.5px'
						}}
					>
						Logout
					</Typography>
				</MenuItem>
			</Menu>
		</>
	);
}

export default function Header(props: { page: string }) {
	const title = props.page === 'Home' ? `homepage` : props.page.toLowerCase();
	const titleImage = {
		homepage: fontSpace,
		vault: fontVault,
		insights: fontQuest
	}[title];
	return (
		<Grid
			container
			item
			xs
			// justifyContent="flex-end"
			alignItems="center"
			sx={{
				position: 'fixed',
				height: headerHeight,
				top: 0,
				border: '1px solid #F1F1F1'
				// paddingRight: '24px',
				// paddingLeft: '24px'
			}}
		>
			{/* <Grid container item xs alignItems="center" width="80px">
				<Grid container width="80px" justifyContent="center" alignItems="center">
					<img
						src={sageCircle}
						style={{ width: '42px', marginRight: '4px' }}
					/>
					<ArrowDropDown
						sx={{ color: '#AEB2BC', marginLeft: '0px' }}
					/>
				</Grid>
			</Grid> */}
			<Grid
				container
				item
				xs
				alignItems="center"
				justifyContent="center"
				overflow="hidden"
				className={`cube-face visible`}
			>
				<Grid container alignItems="center" justifyContent="center">
					{/* <Grid container item xs> */}
					<img
						src={sageCircle}
						style={{
							height: '42px',
							paddingTop: '8px'
						}}
					/>
					{/* <img
						src={fontSageThat}
						alt={title}
						width="198px"
						style={{ paddingTop: '-4px', marginRight: '-24px' }}
					/> */}
					<img
						src={fontSage}
						alt={title}
						width="108px"
						style={{ marginRight: '-19.5px' }}
					/>
					{/* </Grid> */}
					{/* <Typography
						sx={{
							marginRight: '8px',
							fontSize: '42px',
							fontFamily: 'Song Myung',
							color: '#345247'
						}}
					>
						sage
					</Typography> */}
					<Slide
						direction="up"
						in={true}
						mountOnEnter
						unmountOnExit
						key={title}
					>
						{/* <Grid container item xs> */}
						{/* <img src={iconImage} alt={title} height="24px" /> */}

						<img src={titleImage} alt={title} width="108px" />
						{/* <Typography
							sx={{
								fontSize: '42px',
								fontFamily: 'Song Myung',
								color: '#345247'
							}}
						>
							{pageTitle}
						</Typography> */}
						{/* </Grid> */}
						{/* <Typography
						sx={{
							// paddingBottom: '8px',
							cursor: 'default',
							fontSize: '16px',
							lineHeight: '28px',
							fontWeight: 800,
							fontFamily: 'arial',
							color: '#2c2c2c',
							// marginBottom: '16px'
						}}
					>
						{title}
					</Typography> */}
					</Slide>
				</Grid>
			</Grid>
			<Grid container item xs justifyContent="flex-end">
				<Bar />
			</Grid>
		</Grid>
	);
}
