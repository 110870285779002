import { Grid } from '@mui/material';
import {
	InstructionDescriptionTypography,
	InstructionTitleTypography,
	InstructionsStepTypography
} from '../../../Util/Components/Typography';

const Instruction = (props: {
	stepNumber: number;
	title: string;
	description: string;
	imgSrc: string;
	currentStepNumber?: number;
}) => {
	return (
		<Grid
			container
			item
			xs
			direction="column"
			justifyContent="center"
			alignItems="center"
			height="80%"
			// sx={{ backgroundColor: 'red', borderRadius: 10 }}
		>
			<Grid item xs={1}>
				<InstructionsStepTypography>
					STEP {props.stepNumber}
				</InstructionsStepTypography>
			</Grid>
			<Grid
				container
				item
				xs={6}
				justifyContent="center"
				alignItems="center"
				// marginTop="10%"
			>
				<img src={props.imgSrc} width="50%" />
			</Grid>

			<Grid item xs={1}>
				<InstructionTitleTypography>
					{props.title}
				</InstructionTitleTypography>
			</Grid>
			<Grid item xs={1} marginTop={'3%'}>
				<InstructionDescriptionTypography textAlign="center">
					{props.description}
				</InstructionDescriptionTypography>
			</Grid>
		</Grid>
	);
};
export default Instruction;
