import { UUID } from 'uuid';
import { getUtcTimestamp } from '../Helper';
import store from './Store';
export type AppDispatch = typeof store.dispatch;

export interface QuestionId {
	questionId: number;
	questionText: string;
}

export interface ReframedThought {
	thought: string;
	createdAt: string;
	uuid: UUID;
}

export interface Thought {
	thought: string;
	uuid: UUID;
	userSelectedEmotions: string[];
	createdAt: string;
	sentiment: 'positive' | 'negative' | 'neutral' | 'unknown';
	computedTopEmotions: string[];
	sageThoughtUuid: UUID;
	reframed: string;
	// parentThoughtUuid: UUID | null;
}

export interface Bias {
	bias_name: string;
	reason: string;
}

export interface SageThought {
	uuid: UUID;
	reframedThought: string;
	challenge: string;
	challengeResponse: string;
	empathy: string;
	biases: Bias[];
	healthySuggestions: string;
}

export interface SageThoughts {
	[key: string]: SageThought;
}

export interface Thoughts {
	dailyThoughts: Thought[];
	weeklyThoughts: Thought[];
	monthlyThoughts: Thought[];
	previousThoughts: Thought[];
}

export type NounCount = { noun: string; occurrences: number };
export type SentimentNounCounts = {
	positive: NounCount;
	negative: NounCount;
	neutral: NounCount;
};

export type SentimentThoughtCounts = {
	positive: number;
	negative: number;
	neutral: number;
};

export interface Stats {
	nouns: NounCount | null;
	sentimentScore: number;
	weeklyWellbeing: WellbeingCompletion[];
	weeklyFetched: boolean;
	monthlyFetched: boolean;
	monthlyWellbeing: WellbeingCompletion[];
	weeklyThoughtNouns: SentimentNounCounts | null;
	monthlyThoughtNouns: SentimentNounCounts | null;
	allTimeThoughtNouns: SentimentNounCounts | null;
	allTimeThoughtCounts: SentimentThoughtCounts | null;
	weeklyThoughtSentiment: {
		positive: number;
		negative: number;
		neutral: number;
	};
}

export interface User {
	// userId: number;
	firstName: string;
	// lastName: string;
	// email: string;
}

export interface Question {
	questionId: number;
	questionText: string;
}

export interface QuestionResponse {
	questionId: number;
	response: string;
	updateTimestamp: number;
}

export interface Response {
	// question: string;
	response: string;
	updateTimestamp: string;
}

export interface Checkin {
	checkinId: number;
	checkinType: 'morning' | 'evening';
	userId: number;
	timestamp: number; // Date.now()
	questionResponses: QuestionResponse[];
}

export interface Gratitude {
	id: number;
	response: string;
	updateTimestamp: string;
}

export interface Task {
	taskId: number;
	taskName: string;
}

export interface Intention {
	id: number;
	text: string;
	completed: boolean;
	editable: boolean; // for editing
	completedTimestamp: string | null;
	textUpdatedTimestamp: string | null;
}

export interface Priority {
	task: string;
	completed: boolean;
	completedTimestamp: string | null;
	taskUpdatedTimestamp: string;
}

export interface TaskResult {
	taskId: number;
	completed: boolean;
	updateTimestamp: number;
}

export interface WellbeingCompletion {
	mind: boolean;
	body: boolean;
	spirit: boolean;
}

export interface Spirit {
	setsCompleted: number;
	completed: boolean;
}

export interface Body {
	activities: string[];
	completed: boolean;
}

export interface Mind {
	gratitudes: string[];
	completed: boolean;
}

export interface Wellbeing {
	spirit: Spirit;
	body: Body;
	mind: Mind;
	taskCompletion: WellbeingCompletion;
	currentStreak: number[];
}

export interface DailyCheckin {
	submitted: boolean;
	// gratitudes: Gratitude[];
	meditationTimeRemaining: number;
	gratitudes: string[];
	emotions: string[];
	socialChallengeCompleted: boolean;
}

export interface List {
	id: number;
	name: string;
	tasks: Task[];
}

export interface Auth {
	sessionToken: string | null;
	pinToken: string | null;
	isAuthenticated: boolean | null;
	isAuthorized: boolean;
}

export interface Config {
	timerDuration: number;
}

export interface RootState {
	user: User;
	auth: Auth;
	config: Config;
	wellbeing: Wellbeing;
	dailyCheckin: DailyCheckin;
	thoughts: Thoughts;
	sageThoughts: SageThoughts;
	stats: Stats;
}

export const initialState: RootState = {
	user: {
		firstName: ''
	},
	config: {
		timerDuration: 20
	},
	auth: {
		sessionToken: null,
		pinToken: null,
		isAuthenticated: null,
		isAuthorized: false
	},
	stats: {
		nouns: null,
		sentimentScore: 0,
		weeklyWellbeing: [],
		monthlyWellbeing: [],
		weeklyFetched: false,
		monthlyFetched: false,
		weeklyThoughtNouns: null,
		monthlyThoughtNouns: null,
		allTimeThoughtNouns: null,
		allTimeThoughtCounts: null,
		weeklyThoughtSentiment: {
			positive: 0,
			negative: 0,
			neutral: 0
		}
	},
	thoughts: {
		dailyThoughts: [],
		weeklyThoughts: [],
		monthlyThoughts: [],
		previousThoughts: []
	},
	sageThoughts: {},
	wellbeing: {
		mind: {
			gratitudes: [],
			completed: false
		},
		body: {
			activities: [],
			completed: false
		},
		spirit: {
			setsCompleted: 0,
			completed: false
		},
		taskCompletion: {
			mind: false,
			body: false,
			spirit: false
		},
		currentStreak: [0, 0, 0]
	},
	dailyCheckin: {
		meditationTimeRemaining: 300,
		submitted: false,
		gratitudes: ['', '', ''],
		socialChallengeCompleted: false,
		emotions: []
	}
};
