import { GoldenHour, NounCount, SageThought, Thought, User } from './Schema';

import { UUID } from 'uuid';
import { getUtcTimestamp } from '../Helper';

// Authentication types
export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';
export const SET_PIN_TOKEN = 'SET_PIN_TOKEN';
export const SET_AUTHORIZED = 'SET_AUTHORIZED';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const setAuthorized = (isAuthorized: boolean) => ({
	type: SET_AUTHORIZED,
	payload: { isAuthorized }
});

export const setAuthenticated = (isAuthenticated: boolean) => ({
	type: SET_AUTHENTICATED,
	payload: { isAuthenticated }
});

export const setSessionToken = (token: string) => ({
	type: SET_SESSION_TOKEN,
	payload: { token }
});

export const setPinToken = (token: string) => ({
	type: SET_PIN_TOKEN,
	payload: { token }
});

// Golden Hour Types
export const FETCH_GOLDEN_HOUR = 'FETCH_GOLDEN_HOUR';
export const fetchGoldenHour = (goldenhour: GoldenHour) => ({
	type: FETCH_GOLDEN_HOUR as typeof FETCH_GOLDEN_HOUR,
	payload: { goldenhour }
});

// Daily Checkin Types
export const SET_CHECKIN_SUBMITTED = 'SET_CHECKIN_SUBMITTED';
export const FILL_GRATITUDE_RESPONSE = 'FILL_GRATITUDE_RESPONSE';
export const FILL_SOCIAL_CHALLENGE_COMPLETED =
	'FILL_SOCIAL_CHALLENGE_COMPLETED';
export const SET_EMOTIONS = 'SET_EMOTIONS';
export const UPDATE_MEDITATION_TIME_REMAINING =
	'UPDATE_MEDITATION_TIME_REMAINING';

export const updateMeditationTimeRemaining = (seconds: number) => ({
	type: UPDATE_MEDITATION_TIME_REMAINING,
	payload: { seconds }
});

export const setCheckinSubmitted = (isSubmitted: boolean) => ({
	type: SET_CHECKIN_SUBMITTED,
	payload: { isSubmitted }
});

export const fillGratitudeResponse = (response: string[]) => {
	return {
		type: FILL_GRATITUDE_RESPONSE,
		payload: { response }
	};
};
export const fillSocialChallengeCompleted = (response: boolean) => ({
	type: FILL_SOCIAL_CHALLENGE_COMPLETED,
	payload: { response }
});

export const setEmotions = (computedTopEmotions: string[]) => ({
	type: SET_EMOTIONS,
	payload: { computedTopEmotions: computedTopEmotions }
});

// Stats Types
export const FETCH_THOUGHT_NOUNS = 'FETCH_THOUGHT_NOUNS';
export const FETCH_SENTIMENT_SCORE = 'FETCH_SENTIMENT_SCORE';
export const GET_WEEKLY_GOLDEN_HOUR_STATS = 'GET_WEEKLY_GOLDEN_HOUR_STATS';
export const GET_MONTHLY_GOLDEN_HOUR_STATS = 'GET_MONTHLY_GOLDEN_HOUR_STATS';
export const GET_THOUGHT_COUNTS = 'GET_THOUGHT_COUNTS';
export const GET_THOUGHT_NOUNS = 'GET_THOUGHT_NOUNS';

export const fetchThoughtNouns = (nouns: NounCount) => ({
	type: FETCH_THOUGHT_NOUNS as typeof FETCH_THOUGHT_NOUNS,
	payload: { nouns }
});

export const fetchSentimentScore = (score: number) => ({
	type: FETCH_SENTIMENT_SCORE as typeof FETCH_SENTIMENT_SCORE,
	payload: { score }
});

export const getWeeklyGoldenHourStats = (weeklyGoldenHour: number[][]) => ({
	type: GET_WEEKLY_GOLDEN_HOUR_STATS as typeof GET_WEEKLY_GOLDEN_HOUR_STATS,
	payload: { weeklyGoldenHour }
});

export const getMonthlyGoldenHourStats = (monthlyGoldenHour: number[][]) => ({
	type: GET_MONTHLY_GOLDEN_HOUR_STATS as typeof GET_MONTHLY_GOLDEN_HOUR_STATS,
	payload: { monthlyGoldenHour }
});

export const getThoughtNouns = (
	type: string,
	positiveThoughtNouns: NounCount,
	neutralThoughtNouns: NounCount,
	negativeThoughtNouns: NounCount
) => ({
	type: GET_THOUGHT_NOUNS as typeof GET_THOUGHT_NOUNS,
	payload: {
		type,
		positiveThoughtNouns,
		neutralThoughtNouns,
		negativeThoughtNouns
	}
});

export const getThoughtCounts = (
	type: string,
	numPositiveThoughts: number,
	numNeutralThoughts: number,
	numNegativeThoughts: number
) => ({
	type: GET_THOUGHT_COUNTS as typeof GET_THOUGHT_COUNTS,
	payload: {
		type,
		numPositiveThoughts,
		numNeutralThoughts,
		numNegativeThoughts
	}
});

// Config Types
export const MODIFY_TIMER_DURATION = 'MODIFY_TIMER_DURATION';
export const modifyTimerDuration = (duration: number) => ({
	type: MODIFY_TIMER_DURATION,
	payload: { duration }
});

//Thought Types
export const ADD_THOUGHT = 'ADD_THOUGHT';
export const ADD_THOUGHT_SENTIMENT = 'ADD_THOUGHT_SENTIMENT';
export const UPDATE_THOUGHT = 'UPDATE_THOUGHT';
export const SELECT_THOUGHT_EMOTIONS = 'SELECT_THOUGHT_EMOTIONS';
export const REMOVE_THOUGHT = 'REMOVE_THOUGHT';
export const REMOVE_THOUGHT_EMOTIONS = 'REMOVE_THOUGHT_EMOTIONS';
export const FETCH_THOUGHTS = 'FETCH_THOUGHTS';
export const GET_DAILY_THOUGHTS = 'GET_DAILY_THOUGHTS';
export const GET_WEEKLY_THOUGHTS = 'GET_WEEKLY_THOUGHTS';
export const GET_MONTHLY_THOUGHTS = 'GET_MONTHLY_THOUGHTS';
export const ADD_SAGE_THOUGHT_UUID_TO_THOUGHT =
	'ADD_SAGE_THOUGHT_UUID_TO_THOUGHT';

export const addSageThoughtUuidToThought = (
	thoughtUuid: UUID,
	sageThoughtUuid: UUID
) => ({
	type: ADD_SAGE_THOUGHT_UUID_TO_THOUGHT,
	payload: { thoughtUuid, sageThoughtUuid }
});
export const addThought = (thought: Thought) => ({
	type: ADD_THOUGHT,
	payload: { thought }
});

export const updateThought = (
	uuid: UUID,
	updatedThought: { [key: string]: string }
) => ({
	type: UPDATE_THOUGHT,
	payload: { uuid, updatedThought }
});

export const addThoughtSentiment = (
	uuid: UUID,
	sentiment: string,
	computedTopEmotions: string[]
) => ({
	type: ADD_THOUGHT_SENTIMENT,
	payload: { uuid, sentiment, computedTopEmotions }
});

export const selectThoughtEmotions = (
	uuid: UUID,
	userSelectedEmotions: string[],
	sentiment: string
) => ({
	type: SELECT_THOUGHT_EMOTIONS,
	payload: { uuid, userSelectedEmotions, sentiment }
});

export const removeThoughtEmotions = (uuid: UUID) => ({
	type: REMOVE_THOUGHT_EMOTIONS,
	payload: { uuid }
});

export const removeThought = () => ({
	type: REMOVE_THOUGHT,
	payload: {}
});

export const fetchThoughts = (thoughts: Thought[]) => ({
	type: FETCH_THOUGHTS as typeof FETCH_THOUGHTS,
	payload: { thoughts }
});

export const getDailyThoughts = (dailyThoughts: Thought[]) => ({
	type: GET_DAILY_THOUGHTS as typeof GET_DAILY_THOUGHTS,
	payload: { dailyThoughts }
});

export const getWeeklyThoughts = (weeklyThoughts: Thought[]) => ({
	type: GET_WEEKLY_THOUGHTS as typeof GET_WEEKLY_THOUGHTS,
	payload: { weeklyThoughts }
});

export const getMonthlyThoughts = (monthlyThoughts: Thought[]) => ({
	type: GET_MONTHLY_THOUGHTS as typeof GET_MONTHLY_THOUGHTS,
	payload: { monthlyThoughts }
});

// Sage Thought Types
export const ADD_SAGE_THOUGHT = 'ADD_SAGE_THOUGHT';
export const addSageThought = (
	thoughtUuid: UUID,
	sageThought: SageThought
) => ({
	type: ADD_SAGE_THOUGHT as typeof ADD_SAGE_THOUGHT,
	payload: { thoughtUuid, sageThought }
});

// User Types
export const FETCH_USER = 'FETCH_USER';
export const fetchUser = (user: User) => ({
	type: FETCH_USER as typeof FETCH_USER,
	payload: { user }
});
