import ReactDOM from 'react-dom';
import './index.css';
import React from 'react';
import App from './app/app';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const providerConfig = {
	domain: '',
	clientId: '',
	redirectUri: 'http://localhost:8888/api/callback'
};
const onRedirectCallback = (appState: any) => {
	window.history.replaceState(
		{},
		document.title,
		appState && appState.returnTo
			? appState.returnTo
			: window.location.pathname
	);
};

ReactDOM.render(
	<Auth0Provider
		useRefreshTokens={true}
		authorizationParams={{
			redirectUri: `${window.location.origin}/space/gringotts`,
			audience: 'https://dev-jlcvb3a4okf2ouva.us.auth0.com/api/v2/',
			response_type: 'code',
			grant_type: 'client_code',
			scope: 'openid profile email offline_access'
		}}
		useRefreshTokensFallback={false}
		domain="dev-jlcvb3a4okf2ouva.us.auth0.com"
		clientId="AESxPXirpHW9sFkXv6jCtv9wHhfz2G6x"
	>
		<App />
	</Auth0Provider>,
	document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
