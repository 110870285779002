import './LandingPage.css';

import {
	AppBar,
	Box,
	Button,
	CircularProgress,
	Fade,
	Grid,
	InputAdornment,
	Slide,
	TextField,
	TextareaAutosize,
	Toolbar,
	Tooltip,
	Typography
} from '@mui/material';
// import sagethat from '../../../assets/images/logo/sagethat.png';
import { ArrowDownward, ChevronRight } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { getCtaColor, getSentimentColor } from '../../../Util/Helper';

import AnimatedCursor from 'react-animated-cursor';
import { AppDispatch } from '../../../Util/Redux/Schema';
import Breathe from '../../../Tools/Breathe/Main';
import BreathingCircle from './BreathingCircle';
import RotatingHeader from './RotatingHeader';
import { addToWaitlist } from '../../../Util/Redux/AsyncMiddleware';
import analytics from '../../../assets/images/svg-files/analytics.svg';
import checkin from '../../../assets/images/svg-files/checkin.svg';
import clouds from '../../../assets/images/svg-files/clouds.svg';
import goldenHour from '../../../assets/images/svg-files/goldenHour.svg';
import { headerHeight } from '../../../Util/Config';
import landingLeft from '../../../assets/images/svg-files/landingLeft.svg';
import numThoughtsGif from '../../../assets/gifs/38kthoughts.gif';
import numThoughtsImg from '../../../assets/images/svg-files/38kthoughts.svg';
import sageCircle from '../../../assets/images/logo/sageCircle.gif';
import sageCircleImg from '../../../assets/images/logo/sageCircle.svg';
import sageCircleShadow from '../../../assets/images/logo/sageCircleShadow.gif';
import sageYourThought from '../../../assets/images/svg-files/sageYourThought.svg';
import sagethat from '../../../assets/images/logo/sagethat.png';
import scrollDownGif from '../../../assets/gifs/scrollDown.gif';
import thoughtSentiment from '../../../assets/images/svg-files/thoughtSentiment.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
// import airballoon from '../../../assets/gifs/airballoon.gif'

export default function LandingPage() {
	const [currentSection, setCurrentSection] = useState('Main');
	const containerRef = useRef(null);
	const mainRef = useRef(null);
	const infoRef = useRef(null);

	const [collapsed, setCollapsed] = useState(false);
	const initialRef = useRef(null);
	const [state, setState] = useState(0);

	const handleScroll = () => {
		console.log('SCROLL Y', window.scrollY, state);
		if (initialRef.current) {
			if (window.scrollY > 30) {
				console.log('TRUE');
				if (!collapsed) {
					setCollapsed(true);
				}
				if (window.scrollY > 300) {
					setState(2);
				}
			} else {
				if (collapsed) {
					setCollapsed(false);
				}
				if (window.scrollY <= 300) {
					setState(0);
				}
				// setState(0)
			}
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [collapsed]);
	return (
		<Grid
			container
			item
			xs
			direction="column"
			ref={containerRef}
			alignItems="center"
			justifyContent="center"
			sx={{
				height: `200vh`,
				overflow: 'hidden',
				backgroundImage:
					'linear-gradient(145deg, rgba(255, 244, 225, 0.42), rgba(217, 240, 231, 0.42), rgba(255, 227, 227, 0.42))'
			}}
			style={{ cursor: 'default' }}
		>
			{/* <AnimatedCursor
				color="54, 81, 70" // RGB color value for red
				innerAlpha={0}
				innerSize={0}
				outerSize={21}
				outerAlpha={0.2}
				// innerScale={0.7}
				outerScale={2}
			/> */}
			<Grid
				container
				direction="column"
				alignItems="center"
				justifyContent="center"
				sx={{
					height: '100%'
				}}
			>
				<Grid
					container
					item
					ref={initialRef}
					className={
						collapsed
							? 'initial-content-collapsed'
							: 'initial-content'
					}
					sx={{
						justifyContent: 'center'
					}}
				>
					<Header />
					<Grid
						container
						item
						justifyContent="center"
						alignItems="center"
						direction="column"
						height={window.outerHeight}
					>
						<UniqueSellingPointText />
					</Grid>
				</Grid>
				<Grid
					container
					item
					xs
					className={
						collapsed
							? 'next-page-content-expanded'
							: 'next-page-content'
					}
					// width="60%"
					padding="24px"
					// minWidth="608px"
					height="100vh"
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					{/* <img src={airballoon} /> */}
					<img src={numThoughtsGif} width="420px" />
					<BreathingCircle />
					<img
						src={sageYourThought}
						style={{
							position: 'absolute',
							right: 0,
							bottom: '180px',
							transition: 'transform 4s',
							transform: state === 2 ? `scale(1.5)` : 'scale(1)'
						}}
					/>
					{/* <canvas class="webgl-rocks rocks-section__canvas" data-engine="three.js r148" width="2764" height="1812" style="width: 1382px; height: 906px;"></canvas> */}
					<img
						src={landingLeft}
						style={{
							position: 'absolute',
							left: 0,
							bottom: '15%',
							zIndex: 0
						}}
					/>
					{/* <Grid container item xs >
						<SmallCard
							bgColor="#F3F0FC"
							textColor="#897BB3"
							title="Engage in Simple, Powerful Habits for Inner Balance"
							buttonText="Golden Hour Habit Tracker"
							img={goldenHour}
							marginRight
						/>
						<SmallCard
							bgColor="#DBF1E8"
							textColor="#5E9C88"
							title="Leverage AI to Understand and Process Emotional Triggers"
							buttonText="AI Thought Sentiment, Emotions and Summary"
							img={thoughtSentiment}
						/>
						<AnalyticsCard />
					</Grid> */}
				</Grid>
			</Grid>
		</Grid>
		// {/* </Grid> */ }
	);
}

const SmallCard = (props: {
	bgColor: string;
	textColor: string;
	buttonText: string;
	title: string;
	img: string;
	children?: React.ReactNode;
	marginRight?: boolean;
}) => {
	return (
		<Grid
			container
			item
			xs
			padding="42px 24px"
			marginRight={props.marginRight ? '24px' : undefined}
			borderRadius="24px"
			sx={{
				backgroundColor: props.bgColor
			}}
			direction="column"
			alignItems="center"
			// justifyContent="center"
		>
			<img src={props.img} width="240px" />
			<FeatureDescription
				textColor={props.textColor}
				title={props.title}
				buttonText={props.buttonText}
			>
				{props.children}
			</FeatureDescription>
		</Grid>
	);
};
const FeatureDescription = (props: {
	textColor: string;
	buttonText: string;
	title: string;
	children?: React.ReactNode;
}) => {
	return (
		<Grid marginTop="54px" marginLeft="24px">
			{/* <GreenOutlineButton
				onClick={() => {}}
				text={props.buttonText}
				// width="210px"
				// height="28px"
			/> */}
			<Typography
				sx={{
					marginTop: '12px',
					fontWeight: 600,
					color: props.textColor,
					fontSize: '32px',
					lineHeight: '40px',
					fontFamily: 'Arial'
				}}
			>
				{props.title}
			</Typography>
			<Typography
				sx={{
					marginTop: '16px',
					fontWeight: 400,
					color: '#7F8493',
					fontSize: '16px',
					lineHeight: '28px'
				}}
			>
				{props.children}
			</Typography>
		</Grid>
	);
};
const CheckinCard = () => {
	return (
		<Grid
			container
			item
			xs
			sx={{ backgroundColor: '#FFF6E4' }}
			borderRadius="24px"
			// width="950px"
			height="304px"
		>
			{/* <Grid container item xs direction="column" padding="64px 24px"> */}
			<FeatureDescription
				textColor="#DBA12C"
				title="Begin your day with Gratitude"
				buttonText="Gratitude Check-in"
			>
				Kickstart your morning by basking yourself with love and
				gratitude
				{/* Kickstart your mornings with gratitude and love <br /> */}
			</FeatureDescription>
			{/* </Grid> */}
			<Grid container item xs>
				<img src={checkin} style={{ marginTop: '-56px', zIndex: 2 }} />
			</Grid>
		</Grid>
	);
};

const AnalyticsCard = () => {
	return (
		<Grid
			container
			item
			xs
			sx={{ backgroundColor: '#FAE6E6' }}
			borderRadius="24px"
			// width="950px"
			height="304px"
		>
			<Grid container item xs direction="column">
				<FeatureDescription
					textColor="#B86D6D"
					title="See your Path to Awareness"
					buttonText="Insights & Analytics"
				>
					We provide clear, in-depth insights to track your growth
				</FeatureDescription>
			</Grid>
			<Grid container item xs justifyContent="flex-end">
				<img
					src={analytics}
					// height="100%"
					// style={{ marginTop: '-32px' }}
				/>
			</Grid>
		</Grid>
	);
};

const JoinWaitlistTextButtons = () => {
	const [email, setEmail] = useState('');
	const dispatch = useDispatch<AppDispatch>();
	const [submissionState, setSubmissionState] = useState('unsubmitted');
	const navigate = useNavigate();
	const submitUser = async () => {
		// navigate('/waitlist');
		if (!isValidEmail) {
			setSubmissionState('failed');
			return;
		}
		setSubmissionState('loading');
		await setTimeout(() => {
			// submitUser();
			dispatch(addToWaitlist(email));
			setSubmissionState('submitted');
		}, 1000);
	};

	const handleKeyDown = async (
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Prevent form submission or any other default behavior
			submitUser();
		}
	};
	// const [isJoinWaitlistHovered, setIsJoinWaitlistHovered] = useState(false);

	const [isValidEmail, setIsValidEmail] = useState(false);

	const [message, setMessage] = useState('');

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// Check if Enter key was pressed
		if (event.keyCode === 13 || event.which === 13) {
			event.preventDefault(); // Prevent form submission or any other default behavior
			return;
		}
		const inputEmail = event.target.value;
		setEmail(inputEmail);

		// Regular expression to check if the inputEmail is a valid email address
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		setIsValidEmail(regex.test(inputEmail));
	};
	const textareaRef = useRef();
	return submissionState === 'loading' ? (
		<SageCircularProgress state={submissionState} />
	) : submissionState === 'submitted' ? (
		<SubmittedState state={submissionState} />
	) : (
		<>
			{submissionState === 'failed' && (
				<Grid container item width="400px">
					<Typography sx={{ fontSize: '12px', color: '#FDA7A6' }}>
						Please enter a valid email
					</Typography>
				</Grid>
			)}
			<Grid
				container
				item
				// xs
				justifyContent="space-between"
				width="420px"
				height="56px"
				alignItems="center"
				sx={{
					border: '1px solid white',
					borderRadius: '32px',
					backgroundColor: 'rgba(42, 42, 42, 0.1)',
					paddingLeft: '16px'
					// padding: '8px 16px'
				}}
			>
				<Grid container item xs>
					<TextareaAutosize
						ref={textareaRef}
						placeholder="Email address"
						value={email}
						maxRows={1}
						style={{
							borderRadius: '32px',
							fontFamily: 'arial',
							color: '#2F2F2F',
							fontWeight: 450,
							// paddingLeft: '24px',
							backgroundColor: 'transparent',
							fontSize: '14px',
							lineHeight: '20px',
							letterSpacing: '-0.05px',
							width: '100%',
							border: 'none',
							outline: 'none',
							resize: 'none',
							minHeight: '32px',
							paddingTop: '12px'
						}}
						onChange={handleEmailChange}
						onKeyPress={handleKeyDown}
						autoFocus
					/>
				</Grid>

				<Grid
					container
					item
					// xs={3}
					padding="4px"
					// marginRight="-8px"
					// marginTop="-4px"
					// marginBottom="-4px"
					width="108px"
					height="100%"
					onClick={submitUser}
				>
					<Grid
						container
						item
						// xs
						justifyContent="center"
						alignItems="center"
						height="100%"
						sx={{
							// backgroundColor: 'rgba(115, 168, 153, 0.5)',
							backgroundColor: getCtaColor(),
							borderRadius: '32px'
						}}
					>
						<Typography
							sx={{
								fontSize: '12px',
								// fontWeight: 450,
								color: 'white'
							}}
						>
							Join waitlist
						</Typography>
					</Grid>
					{/* <ChevronRight /> */}
				</Grid>
			</Grid>
		</>
	);
};

const SubmittedState = (props: { state: string }) => (
	<Box className="fade-in-transition fade-in">
		<Grid
			container
			item
			justifyContent="center"
			alignItems="center"
			width="100%"
			height="100%"
		>
			<Typography
				sx={{
					fontSize: '14px',
					// fontWeight: 450,
					color: '#2F2F2F'
				}}
			>
				Thank you for joining the waitlist!
			</Typography>
		</Grid>
	</Box>
);

const SageCircularProgress = (props: { state: string }) => (
	<Box className="fade-in">
		{/* <Fade in={props.state === 'loading'} timeout={500}> */}
		<React.Fragment>
			<svg width={0} height={0}>
				<defs>
					<linearGradient
						id="my_gradient"
						x1="0%"
						y1="0%"
						x2="0%"
						y2="100%"
					>
						<stop
							offset="0%"
							stopColor={getSentimentColor('positive').color}
						/>
						<stop
							offset="50%"
							stopColor={getSentimentColor('saged').color}
						/>
						<stop
							offset="100%"
							stopColor={getSentimentColor('negative').color}
						/>
					</linearGradient>
				</defs>
			</svg>
			<CircularProgress
				sx={{
					'svg circle': {
						stroke: 'url(#my_gradient)'
					}
				}}
			/>
		</React.Fragment>
		{/* </Fade> */}
	</Box>
);
const SageYourThoughtButton = (props: { text: string; onClick: any }) => {
	return (
		<Button
			onClick={props.onClick}
			variant="contained"
			// endIcon={<ChevronRight />}
			sx={{
				borderRadius: '12px',
				width: '145px',
				height: '54px',
				backgroundColor: '#74A899',
				fontSize: '16px',
				lineHeight: '22px',
				fontWeight: 450,
				fontFamily: 'arial',
				color: '#fff',
				// marginTop: '48px',
				textTransform: 'none',
				'&:hover': {
					backgroundColor: '#74A899'
				}
			}}
		>
			{props.text}
		</Button>
	);
};

const UniqueSellingPointText = () => {
	const [isCloudVisible, setIsCloudVisible] = useState(true);
	const [key, setKey] = useState(0);

	useEffect(() => {
		const timer = setTimeout(() => setKey((prevKey) => prevKey + 1), 5000); // assuming 3 seconds for the GIF duration
		return () => clearTimeout(timer);
	}, [key]);
	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		setIsCloudVisible(true);
	// 	}, 500); // 3000 milliseconds = 3 seconds

	// 	return () => clearTimeout(timer);
	// }, []);
	return (
		<Grid
			container
			item
			// xs
			direction="column"
			justifyContent="center"
			alignItems="center"
			// width="100vw"
			// marginTop={headerHeight}
		>
			<Grid
				container
				item
				xs
				direction="column"
				// padding="24px 40px"
				alignItems="center"
				justifyContent="center"
				// position="relative"
			>
				{/* <img
					src={sageCircle}
					style={{ width: '120px', height: '120px' }}
				/> */}
				{/* <img src={sageCircle} style={{ width: '120px', height: '120px' }} /> */}
				{/* <img src={showGif ? sageCircle : sageCircleImg} style={!showGif ? { width: '120px', height: '120px' } : { width: '120px', height: '120px', transition: "opacity 0s ease-in-out" }} width="420px" /> */}
				<img src={sageCircle} width="120px" />

				<img src={sagethat} style={{ width: '420px' }} />

				{/* <video width="800" height="450" controls>
					<source src={sageCircleVideo} type="video/mp4" />
					Your browser does not support the video tag.
				</video> */}
				{/* <BreathingCircle />
				<img
					src={sageYourThought}
					style={{ position: 'absolute', right: 0, bottom: '180px' }}
				/>
				<img
					src={landingLeft}
					style={{
						position: 'absolute',
						left: 0,
						bottom: '15%',
						zIndex: 0
					}}
				/> */}
				<Grid container item xs>
					<Grid
						container
						item
						xs
						sx={{
							justifyContent: 'flex-end'
						}}
					>
						<Typography
							sx={{
								align: 'right',
								fontSize: '24px',
								lineHeight: '32px',
								fontWeight: 400,
								fontFamily: 'arial',
								color: '#7F7D83',
								marginTop: '24px',
								marginBottom: '24px'
							}}
						>
							A mindful space to
						</Typography>
					</Grid>
					<Grid
						container
						item
						xs
						alignItems="flex-start"
						paddingLeft="12px"
						justifyContent="flex-start"
					>
						<RotatingHeader
							options={[
								'slow down',
								'process your emotions',
								'decode your triggers',
								'master your mind',
								// 'reframe your thoughts',
								// 'gain awareness',
								'manifest your reality'
							]}
							interval={2400}
						/>
					</Grid>
				</Grid>
				<JoinWaitlistTextButtons />
			</Grid>
			{/* <Grid
				container
				item
				justifyContent="center"
				alignItems="center"
				marginTop="42px"
			> */}
			<img
				src={scrollDownGif}
				style={{ marginTop: '108px', width: '88px', height: '88px' }}
			/>
			{/* <ArrowDownward
				style={{
					marginTop: '108px',
					width: '44px',
					height: '44px',
					color: '#7F7D83'
				}}
			/> */}
			{/* </Grid> */}
		</Grid>
	);
};

const Header = () => {
	const { loginWithRedirect } = useAuth0();
	const handleLogin = async () => {
		try {
			// console.log('Redirect Login');
			await loginWithRedirect({
				authorizationParams: {
					scope: 'openid profile email offline_access'
				}
			});
		} catch (error) {
			// Handle login error
			console.error('Login error:', error);
		}
	};
	const [email, setEmail] = useState('');
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const submitUser = () => {
		dispatch(addToWaitlist(email));
		// navigate('/waitlist');
	};
	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' && isValidEmail) {
			submitUser();
		}
	};
	const [isJoinWaitlistHovered, setIsJoinWaitlistHovered] = useState(false);
	const [isValidEmail, setIsValidEmail] = useState(false);

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputEmail = event.target.value;
		setEmail(inputEmail);

		// Regular expression to check if the inputEmail is a valid email address
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		setIsValidEmail(regex.test(inputEmail));
	};
	return (
		<Grid
			container
			item
			xs
			justifyContent="space-between"
			alignItems="center"
			sx={{
				paddingRight: '24px',
				paddingLeft: '24px',
				paddingTop: '24px'
			}}
		>
			<Grid
				container
				item
				xs
				alignItems="center"
				justifyContent="flex-end"
			>
				<Button
					onClick={handleLogin}
					variant="contained"
					// endIcon={<ChevronRight />}
					sx={{
						position: 'absolute',
						marginTop: '32px',
						borderRadius: '12px',
						width: '145px',
						height: '54px',
						backgroundColor: 'white',
						fontSize: '16px',
						lineHeight: '22px',
						fontWeight: 450,
						fontFamily: 'arial',
						color: '#74A899',
						// marginTop: '48px',
						textTransform: 'none',
						'&:hover': {
							backgroundColor: 'white'
						}
					}}
				>
					Log in
				</Button>
			</Grid>
		</Grid>
		// 	</Toolbar>
		// </AppBar>

		// <Grid
		// 	container
		// 	item
		// 	xs
		// 	justifyContent="space-between"
		// 	alignItems="center"
		// 	sx={{
		// 		// position: 'fixed',
		// 		height: headerHeight,
		// 		top: 0,
		// 		position: 'sticky',
		// 		paddingRight: '24px',
		// 		paddingLeft: '24px',
		// 		paddingTop: '12px'
		// 	}}
		// >
		// 	<Grid container item xs>
		// 		<img
		// 			src={sageCircle}
		// 			style={{
		// 				width: '27px',
		// 				height: '28px',
		// 				marginRight: '4px'
		// 			}}
		// 		/>
		// 		<img src={sageLogo} style={{ width: '68px', height: '28px' }} />
		// 	</Grid>
		// 	<Grid container item xs alignItems="center" justifyContent="flex-end">
		// 		<Typography
		// 			sx={{
		// 				color: '#2D2B32',
		// 				fontSize: '16px',
		// 				fontWeight: 450,
		// 				lineHeight: '22px',
		// 				spacing: '-0.18px',
		// 				marginRight: '28px'
		// 			}}
		// 		>
		// 			Log in
		// 		</Typography>
		// 		<GreenOutlineButton
		// 			text="Get started for free"
		// 			width="176px"
		// 			height="46px"
		// 		/>
		// 	</Grid>
		// </Grid>
	);
};
export const GreenOutlineButton = (props: {
	onClick: any;
	text: string;
	width?: string;
	height?: string;
}) => {
	return (
		<Button
			onClick={props.onClick}
			sx={{
				width: props.width || undefined,
				height: props.height || undefined,
				textTransform: 'none',
				backgroundColor: 'white',
				border: '1px solid #74A899',
				boxShadow: '#74A899',
				radius: '12px'
			}}
		>
			<Typography
				sx={{
					color: '#74A899',
					fontSize: '14px',
					fontWeight: 450,
					lineHeight: '20px',
					spacing: '-0.18px'
				}}
			>
				{props.text}
			</Typography>
		</Button>
	);
};
