import {
	Add,
	ArrowDownward,
	Check,
	CheckCircle,
	ChevronRight,
	Circle,
	CircleOutlined,
	Close,
	CloseRounded,
	LightbulbCircleRounded,
	Remove,
	StarRounded,
	TipsAndUpdates,
	TipsAndUpdatesRounded
} from '@mui/icons-material';
import {
	AppDispatch,
	Bias,
	RootState,
	SageThought,
	Thought
} from '../../Util/Redux/Schema';
import {
	Button,
	Chip,
	Divider,
	Grid,
	IconButton,
	Modal,
	Skeleton,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import {
	COGNITIVE_BIAS_DESCRIPTOR,
	EMOTION_DESCRIPTOR,
	EMOTION_GROUP_COLOR_MAP,
	getCtaColor,
	getCtaHoverColor,
	getEmotionGroup,
	getSentimentColor
} from '../../Util/Helper';
import {
	IcSharpTipsAndUpdates,
	MeteoconsLightningBoltFill
} from '../../assets/svgFiles';
import SageWorksheetSummary, { SageSummary } from './WorksheetSummary';
import { useDispatch, useSelector } from 'react-redux';

import Breathwork from '../../components/pages/Home/Wellbeing/SpiritBreathwork';
import CustomTextField from '../../components/molecules/CustomTextField';
import { StringSupportOption } from 'prettier';
import Summary from './Summary';
import breatheCloud from '../assets/images/svg-files/breatheCloud.svg';
import { postReframedThought } from '../../Util/Redux/AsyncMiddleware';
import sageCircle from '../../assets/images/sageCircle.png';
import sagethat from '../../assets/images/logo/sagethat.png';
import { useState } from 'react';
import zIndex from '@mui/material/styles/zIndex';

export const Header = (props: { pageIdx: number }) => {
	return (
		<Grid container item justifyContent="space-between" alignItems="center">
			<img
				src={sageCircle}
				style={{ height: '48px', width: '44px', marginRight: '8px' }}
			/>
			<img src={sagethat} style={{ height: '32px', width: '140px' }} />
			<Grid
				container
				item
				xs
				justifyContent="flex-end"
				paddingRight="24px"
				// sx={{ backgroundColor: 'red' }}
			>
				{/* <Grid container width="100px"> */}
				{/* <CloseRounded /> */}
				{/* </Grid> */}
				{/* <Grid container width="100px"> */}
				{/* {[0, 1, 2].map((idx) => (
					<Grid
						container
						sx={{
							marginRight: idx < 2 ? '8px' : 0,
							width: '16px',
							height: '16px',
							borderRadius: '50%',
							backgroundColor:
								props.pageIdx > idx ? getCtaColor() : 'white',
							border:
								props.pageIdx === idx
									? `4px solid ${getCtaColor()}`
									: `1px solid ${getCtaColor()}`
						}}
					/>
				))} */}
				{/* </Grid> */}
			</Grid>
		</Grid>
	);
};
export const SageWorksheet = (props: {
	open: boolean;
	setOpen: (value: boolean) => void;
	setCompleted: (value: boolean) => void;
	setNumberOfBreaths: (value: number) => void;
	thought: Thought;
}) => {
	const [breaths, setBreaths] = useState(3);
	const [pageIdx, setPageIdx] = useState(0);
	const [reframedThought, setReframedThought] = useState('');
	const onClick = () => {
		// props.setOpen(false);
		// props.setNumberOfBreaths(breaths);
		// props.setCompleted(true);
		setPageIdx(pageIdx + 1);
		if (pageIdx === 1) {
			updateUserReframedThought(reframedThought);
		}
	};
	const [showSummaryView, setShowSummaryView] = useState(false);
	const sageThought = useSelector(
		(state: RootState) => state.sageThoughts[props.thought.uuid]
	);
	console.log('SAGE THOUGHT', sageThought);
	const [openIdx, setOpenIdx] = useState(0);
	// const [prevIdx, setP]
	const [completedIdx, setCompletedIdx] = useState(null);
	const [completed, setCompleted] = useState<boolean[]>([
		false,
		false,
		false,
		false
	]);
	const setGenerateJoyCompleted = (isCompleted: boolean) => {
		setCompleted(
			completed.map((c: boolean, idx: number) =>
				idx === 2 ? isCompleted : c
			)
		);
	};
	const setOpenIdxAndCompleted = (idx: number) => {
		setOpenIdx(idx);
		if (idx !== 0 && !completed[0]) {
			setCompleted(completed.map((c, idx) => (idx === 0 ? true : c)));
		}
		if (openIdx === 1 && idx !== 1 && !completed[1]) {
			setCompleted(completed.map((c, idx) => (idx === 1 ? true : c)));
		}
	};
	// console.log(
	// 	'COMPLETED',
	// 	completed.reduce((acc, curr) => acc + curr, 0) < 3
	// );

	const dispatch = useDispatch<AppDispatch>();
	const updateUserReframedThought = (message: string) => {
		dispatch(postReframedThought(message, props.thought.uuid));
	};
	const userReframedThought = useSelector(
		(state: RootState) =>
			state.thoughts.dailyThoughts.filter(
				(thought) => thought.uuid === props.thought.uuid
			)[0]?.reframed
	);
	console.log('User Reframed Thought', userReframedThought);

	return (
		<Modal
			open={props.open}
			onClose={() => props.setOpen(false)}
			sx={{ zIndex: 2000, backdropFilter: 'blur(5px)' }}
		>
			<Grid
				container
				style={{
					padding: '32px',
					paddingBottom: '42px',
					width: '680px',
					height: '680px',
					borderRadius: '16px',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor: 'white',
					overflowY: 'auto'
				}}
				direction="column"
			>
				<Grid
					container
					item
					xs
					direction="column"
					justifyContent="center"
					// padding="16px"
				>
					{/* <img src={sagethat} style={{ width: "160px" }} /> */}
					<Header pageIdx={pageIdx} />

					<ViewThought thought={props.thought.thought} />
					<Grid
						container
						width="120px"
						justifyContent="space-around"
						// sx={{ backgroundColor: 'red' }}
						// margin="0 32px"
						// width="288px"
					></Grid>
					<Grid
						container
						item
						xs
						// sx={{ backgroundColor: '#F2F5F9' }}
						padding="16px"
						paddingLeft={0}
						borderRadius="12px"
						justifyContent="center"
					>
						{pageIdx === 0 ? (
							<Grid container item direction="column">
								<Assess
									biases={sageThought?.biases || ''}
									open={true}
									setOpen={() => setOpenIdxAndCompleted(1)}
									completed={completed[1]}
								/>
								<GenerateJoy
									userReframedThought={reframedThought}
									setUserReframedThought={setReframedThought}
									cosReframedThought={
										sageThought?.reframedThought || ''
									}
									open={openIdx === 2}
									setOpen={() => setOpenIdxAndCompleted(2)}
									completed={completed[2]}
									setCompleted={setGenerateJoyCompleted}
									thoughtUuid={props.thought.uuid}
								/>
							</Grid>
						) : pageIdx === 1 ? (
							<Embrace
								completed={completed[3]}
								setCompleted={() =>
									setCompleted(
										completed.map((c, idx) =>
											idx === 3 ? true : c
										)
									)
								}
							/>
						) : (
							<SageSummary
								sageThought={sageThought}
								thought={props.thought}
							/>
						)}

						<Grid
							container
							item
							// xs={0.5}
							// height="100%"
							justifyContent="center"
							alignItems="flex-end"
							// sx={{ backgroundColor: 'red' }}
						>
							{/* <SubmitButton onClick={onClick} cancel /> */}
							{pageIdx === 0 ? (
								<SubmitButton
									onClick={onClick}
									disabled={!reframedThought}
								/>
							) : !completed ? (
								<SubmitButton onClick={onClick} cancel />
							) : (
								<SubmitButton onClick={onClick} />
							)}
							{/* <SubmitButton onClick={onClick} /> */}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Modal>
	);
};

const ViewThought = (props: { thought: string }) => {
	return (
		<Grid container item alignItems="center" margin="8px 0px">
			{/* <MeteoconsLightningBoltFill /> */}
			<Tooltip
				PopperProps={{ style: { zIndex: 5000 } }}
				title={props.thought}
			>
				<Typography
					// textAlign="center"
					sx={{
						fontSize: '14px',
						lineHeight: '20px',
						fontFamily: 'arial',
						fontWeight: 600,
						color: '#7F7D83',
						cursor: 'default',
						textDecoration: 'underline'
					}}
				>
					{/* <MeteoconsLightningBoltFill /> */}
					View your harsh thought
				</Typography>
			</Tooltip>
		</Grid>
	);
};

const GainInsightView = () => {
	return (
		<>
			<BodyTypography>
				Why do I feel the need to say I like the movie when I don't?
			</BodyTypography>
			<BodyTypography>Personalization, Overgeneralization</BodyTypography>
		</>
	);
};

const BodyTypography = (props: { children: React.ReactElement | string }) => {
	return (
		<Typography
			sx={{
				fontSize: '14px',
				lineHeight: '20px',
				fontFamily: 'arial',
				fontWeight: 400,
				color: '#7F7D83',
				cursor: 'default'
				// marginBottom: "56px",
			}}
		>
			{/* <b style={{ color: getCtaColor(), fontSize: "20px" }}>S</b>ense how you
		are feeling */}
			{props.children}
		</Typography>
	);
};

const Sense = (props: {
	emotions: string[];
	open: boolean;
	setOpen: () => void;
	completed: boolean;
}) => {
	return (
		<>
			<Title
				title={
					props.open
						? 'Sense these emotions physically in your body'
						: 'Sense'
				}
				open={props.open}
				completed={props.completed}
				setOpen={props.setOpen}
			/>
			{props.open && (
				<Grid
					justifyContent="center"
					alignItems="center"
					// borderLeft="2px solid lightgray"
					// paddingLeft="8px"
					marginTop="12px"
					direction="column"
				>
					{/* <Typography
						sx={{
							fontSize: '12px',
							fontFamily: 'arial',
							color: '#858580',
							paddingBottom: '8px'
						}}
					>
						Sense these emotions physically in your body:
					</Typography> */}
					{/* <Grid container item direction="column"> */}
					{(props.emotions.length > 0
						? props.emotions
						: ['Annoyance', 'Apathy']
					).map((label, idx) => (
						<Tooltip
							title={EMOTION_DESCRIPTOR[label]}
							PopperProps={{
								modifiers: [
									{
										name: 'zIndex',
										enabled: true,
										phase: 'afterWrite',
										fn: ({ state }) => {
											state.styles.popper.zIndex = 20000; // Set a higher zIndex than the modal (default is 1300)
										}
									}
								]
							}}
						>
							<Chip
								label={label}
								style={{
									display: 'inline-flex',
									width: 'auto',
									marginBottom: '12px',
									cursor: 'default',
									marginLeft: idx > 0 ? '8px' : '',
									backgroundColor:
										EMOTION_GROUP_COLOR_MAP[
											getEmotionGroup(label)
										]
								}}
							/>
						</Tooltip>
					))}
					{/* </Grid> */}
					{/* <Grid
            container
            item
            alignItems="center"
            // sx={{ backgroundColor: "red" }}
          >
            <TipsAndUpdates sx={{ height: "16px", color: getCtaColor() }} />
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "arial",
                color: "#858580",
                textAlign: "center",
                // paddingBottom: "8px",
              }}
            >
              Take a moment to examine your body. Where do you sense these
              emotions in your body?
            </Typography>
          </Grid> */}

					{/* <Divider
						sx={{ marginBottom: '8px', paddingBottom: '8px' }}
					/> */}
				</Grid>
			)}
		</>
	);
};

const Assess = (props: {
	biases: Bias[];
	open: boolean;
	setOpen: () => void;
	completed: boolean;
}) => {
	const patterns = props.biases
		? props.biases.map((bias) => [bias.bias_name, bias.reason])
		: [
				[
					'Social Proof',
					"While not explicitly stated, there may be an underlying pressure to perform all aspects of the project well, which can create resistance when faced with tasks that don't align with your natural strengths. This can slow down progress or lead to avoidance."
				],
				[
					'Negativity Bias',
					'You express uncertainty about your creative abilities in marketing, labeling them as "not my strong suit." This kind of thinking can undermine confidence and contribute to resistance, making the tasks seem harder than they might actually be.'
				],
				[
					'Overgeneralization',
					'By identifying creativity in marketing as challenging, there may be a tendency to overgeneralize that struggle across other creative tasks, which can amplify feelings of resistance.'
				]
		  ];
	const body = (
		<Grid
			container
			item
			// xs
			marginTop="12px"
			direction="column"
			paddingBottom="8px"
			sx={{ backgroundColor: '#F2F5F9', height: '140px' }}
			borderRadius="8px"
			padding="24px"
		>
			<Grid
				container
				item
				// xs
				alignItems="center"
				sx={{
					backgroundColor: '#F2F5F9',
					// padding: "24px",
					borderRadius: '8px',
					marginBottom: '16px'
				}}
			>
				{' '}
				<Skeleton width="160px" />
				<Skeleton
					width="80px"
					height="24px"
					sx={{ borderRadius: '16px', marginLeft: '8px' }}
				/>
			</Grid>
			{/* <Skeleton /> */}
			{/* <Grid container item xs direction="column"> */}
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
		</Grid>
	);
	const body2 = (
		<Grid container marginTop="12px" direction="column" paddingBottom="8px">
			<Grid
				container
				alignItems="center"
				sx={{
					backgroundColor: '#F2F5F9',
					padding: '24px',
					borderRadius: '8px',
					marginBottom: '16px'
				}}
			>
				{/* <Typography
					sx={{
						fontSize: '16px',
						fontFamily: 'arial',
						color: '#858580'
						// paddingBottom: '8px'
					}}
				>
					Cognitive Bias:
				</Typography> */}
				{/* {patterns.map((type, idx) => (
					<Tooltip
						title={COGNITIVE_BIAS_DESCRIPTOR[type[0]]}
						PopperProps={{
							modifiers: [
								{
									name: 'zIndex',
									enabled: true,
									phase: 'afterWrite',
									fn: ({ state }) => {
										state.styles.popper.zIndex = 15000; // Set a higher zIndex than the modal (default is 1300)
									}
								}
							]
						}}
					>
						<Chip
							label={type[0]}
							sx={{ width: 'auto' }}
							style={{
								// display: "inline-flex",
								// width: '140px',
								height: '24px',
								// marginBottom: '12px',
								marginLeft: idx > 0 ? '8px' : '-4px',
								cursor: 'default'
								// marginLeft: idx > 0 ? '8px' : ''
							}}
						/>
					</Tooltip>
				))} */}
				<Grid container direction="column">
					{patterns.map((type, idx) => {
						return (
							<Grid
								container
								item
								direction="row"
								alignItems="center"
								marginTop={idx > 0 ? '24px' : undefined}
							>
								<Grid
									container
									item
									xs={4}
									// sx={{ backgroundColor: 'red' }}
									width="140px"
								>
									<Tooltip
										title={
											COGNITIVE_BIAS_DESCRIPTOR[type[0]]
										}
										PopperProps={{
											modifiers: [
												{
													name: 'zIndex',
													enabled: true,
													phase: 'afterWrite',
													fn: ({ state }) => {
														state.styles.popper.zIndex = 15000; // Set a higher zIndex than the modal (default is 1300)
													}
												}
											]
										}}
									>
										<Chip
											label={type[0]}
											// sx={{ width: 'auto' }}
											style={{
												// display: "inline-flex",
												width: '140px',
												height: '36px',
												color: 'white',
												backgroundColor: getCtaColor(),
												// marginBottom: '12px',
												// marginLeft: '-4px',
												cursor: 'default'
												// marginLeft: idx > 0 ? '8px' : ''
											}}
										/>
									</Tooltip>
								</Grid>
								<Grid
									container
									item
									xs
									// sx={{ backgroundColor: 'red' }}
								>
									<Typography
										sx={{
											fontSize: '16px',
											fontWeight: 450,
											fontColor: '#2c2c2c',
											fontFamily: 'arial',
											marginTop: '8px'
										}}
									>
										{type[1]}
									</Typography>
								</Grid>
							</Grid>
						);
					})}
				</Grid>
			</Grid>
		</Grid>
	);
	return (
		<Template
			title="Assess your thought patterns"
			body={props.biases ? body2 : body}
		/>
	);
};

const Template = (props: { startIcon?: any; title: string; body: any }) => {
	return (
		<>
			<Grid
				container
				item
				xs
				width="100%"
				// sx={{ backgroundColor: 'red' }}
				alignItems="center"
			>
				{props.startIcon}
				<Typography
					sx={{
						fontSize: '16px',
						lineHeight: '20px',
						fontFamily: 'arial',
						fontWeight: 400,
						color: '#7F7D83',
						cursor: 'default'
					}}
				>
					<b
						style={{
							color: getCtaColor(),
							fontSize: '20px',
							marginLeft: '4px'
						}}
					>
						{props.title[0]}
					</b>
					{props.title.slice(1, props.title.length)}
				</Typography>
			</Grid>
			{props.body}
		</>
	);
};

const GenerateJoy = (props: {
	userReframedThought: string;
	setUserReframedThought: (userReframedThought: string) => void;
	cosReframedThought: string;
	open: boolean;
	setOpen: () => void;
	completed: boolean;
	setCompleted: (completed: boolean) => void;
	thoughtUuid: string;
}) => {
	const body2 = (
		<Grid
			container
			padding="16px"
			direction="column"
			marginTop="8px"
			borderRadius="8px"
			sx={{
				backgroundColor: '#F2F5F9',
				height: '210px',
				border: '1px solid #EBEBEB'
			}}
		>
			{/* <Grid
				item
				container
				xs
				marginTop="4px"
				padding="8px"
				// sx={{ backgroundColor: 'red' }}
			> */}
			{/* <Grid item xs> */}
			<Grid
				container
				item
				xs
				width="100%"
				alignItems="center"
				// sx={{ backgroundColor: 'red' }}
			>
				<Grid container item xs>
					<Typography
						sx={{
							fontSize: '16px',
							lineHeight: '20px',
							fontFamily: 'arial',
							fontWeight: 400,
							color: '#7F7D83',
							cursor: 'default',
							marginBottom: '24px'
						}}
					>
						Example:{' '}
						{props.cosReframedThought ||
							"I have my own unique preferences, and it's okay if they differ from others."}
					</Typography>
					{/* <StarRounded
						sx={{
							color: getSentimentColor('reframed').color,

							width: '24px'
						}}
					/> */}
				</Grid>
			</Grid>
			{/* </Grid> */}
			<CustomTextField
				placeholder="A more positive way to think about this is..."
				value={props.userReframedThought}
				setValue={props.setUserReframedThought}
				setCompleted={props.setCompleted}
			/>
		</Grid>
		// </Grid>
	);
	const body = (
		<Grid
			container
			paddingLeft="4px"
			direction="column"
			marginTop="8px"
			borderRadius="8px"
			sx={{
				backgroundColor: '#F2F5F9',
				height: '140px',
				border: '1px solid #EBEBEB'
			}}
		>
			<Grid
				container
				item
				// xs
				marginTop="4px"
				alignItems="center"
				// sx={{ backgroundColor: 'red' }}
			>
				<Typography
					sx={{
						color: '#adacb0',
						fontSize: '14px',
						margin: '8px',
						fontFamily: 'arial',
						marginLeft: '16px'
						// marginTop: '8px'
					}}
				>
					Example:
				</Typography>
				<Skeleton animation="wave" style={{ width: '240px' }} />
				{/* <CustomTextField
					placeholder={`Example: ${
						props.cosReframedThought ||
						"I have my own unique preferences, and it's okay if they differ from others."
					}`}
					value={props.userReframedThought}
					setValue={props.setUserReframedThought}
					setCompleted={props.setCompleted}
				/> */}
			</Grid>
		</Grid>
	);
	const body3 = (
		<Grid
			container
			item
			// xs
			marginTop="12px"
			direction="column"
			paddingBottom="8px"
			sx={{ backgroundColor: '#F2F5F9', height: '140px' }}
			borderRadius="8px"
			padding="24px"
		>
			<Grid
				container
				item
				// xs
				alignItems="center"
				sx={{
					backgroundColor: '#F2F5F9',
					// padding: "24px",
					borderRadius: '8px',
					marginBottom: '16px'
				}}
			>
				{' '}
				<Skeleton width="160px" />
				<Skeleton
					width="80px"
					height="24px"
					sx={{ borderRadius: '16px', marginLeft: '8px' }}
				/>
			</Grid>
			{/* <Skeleton /> */}
			{/* <Grid container item xs direction="column"> */}
			<Skeleton animation="wave" />
			<Skeleton animation="wave" />
		</Grid>
	);
	return (
		<Grid container marginTop="24px">
			<Template
				startIcon={
					<StarRounded
						sx={{ color: getSentimentColor('reframed').color }}
					/>
				}
				title="Generate a reframed thought"
				body={props.cosReframedThought ? body2 : body}
			/>
		</Grid>
	);
	return (
		<>
			<Title
				title={props.open ? 'Generate a reframed thought' : 'Generate'}
				open={props.open}
				completed={props.completed}
				setOpen={props.setOpen}
			/>
			<Grid
				container
				// borderLeft={`2px solid lightgray`}
				// height="160px"
				paddingLeft="16px"
				// margin="12px"
				direction="column"
			>
				<Typography
					sx={{
						fontSize: '12px',
						fontFamily: 'arial',
						color: '#858580',
						paddingBottom: '8px'
					}}
				>
					Replace your harsh thought with a more positive one
				</Typography>
				<Grid
					container
					item
					xs
					marginTop="4px"
					// sx={{ backgroundColor: 'red' }}
				>
					<CustomTextField
						placeholder={`Example: ${
							props.cosReframedThought ||
							"I have my own unique preferences, and it's okay if they differ from others."
						}`}
						value={props.userReframedThought}
						setValue={props.setUserReframedThought}
						setCompleted={props.setCompleted}
					/>
				</Grid>
			</Grid>
		</>
	);
};

const Title = (props: {
	title: string;
	open: boolean;
	completed: boolean;
	setOpen: () => void;
	disabled?: boolean;
}) => {
	const [hover, setHover] = useState(false);
	return (
		<Grid
			container
			onClick={props.setOpen}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			marginTop="16px"
			borderRadius="8px"
			justifyContent="center"
			alignItems="center"
			paddingLeft="4px"
			sx={
				{
					// cursor: props.disabled ? 'default' : 'pointer',
					// backgroundColor:
					// 	hover && !props.disabled ? '#F1F1F1' : 'transparent'
				}
			}
		>
			{/* {props.completed ? (
				<Check
					sx={{
						marginRight: '8px',
						width: '18px',
						height: '18px',
						padding: '2px',
						backgroundImage:
							'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)',
						borderRadius: '50%',
						color: getCtaColor()
					}}
				/>
			) : (
				<Circle
					sx={{
						marginRight: '8px',
						width: '18px',
						height: '18px',
						padding: '2px',
						backgroundImage:
							'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)',
						borderRadius: '50%',
						color: 'white'
					}}
				/>
			)} */}
			<Typography
				sx={{
					fontSize: '12px',
					lineHeight: '20px',
					fontFamily: 'arial',
					fontWeight: 400,
					color: '#7F7D83',
					cursor: 'default'
				}}
			>
				<b style={{ color: getCtaColor(), fontSize: '20px' }}>
					{props.title}
				</b>
				{/* {props.title.slice(1, props.title.length)} */}
			</Typography>
			<Grid container item xs justifyContent="flex-end">
				{/* <IconButton
					disabled={props.open || props.disabled}
					sx={{
						'&:hover': {
							backgroundColor: 'transparent'
						}
					}}
				>
					<ChevronRight
						style={{ rotate: props.open ? '-90deg' : '90deg' }}
					/>
				</IconButton> */}
			</Grid>
		</Grid>
	);
};

const Embrace = (props: {
	completed: boolean;
	setCompleted: () => void;
	// onClick: () => void;
}) => {
	const [progressCount, setProgressCount] = useState(1);
	const updateProgressCount = (count: number) => {
		setProgressCount(count);
		console.log(count);
		if (count === 5) {
			props.setCompleted();
		}
	};
	// const
	const body = (
		<Grid
			container
			item
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			<Grid container item xs marginTop="16px">
				<Grid
					container
					item
					xs={1}
					alignItems="center"
					justifyContent="flex-end"
				>
					<TipsAndUpdatesRounded
						style={{
							marginRight: '4px',
							color: getCtaColor(),
							borderRadius: '50%'
							// padding: '4px'
						}}
					/>
				</Grid>
				<Grid
					container
					item
					xs
					alignItems="center"
					// justifyContent="center"
					// sx={{ backgroundColor: 'blue' }}
				>
					<Typography
						sx={{
							fontSize: '14px',
							fontFamily: 'arial',
							color: '#858580',
							// lineHeight: '24px',
							marginTop: '-16px',
							paddingBottom: '8px',
							marginLeft: '4px'
						}}
					>
						<br />
						Remember, an emotional reaction lasts no more than 120
						seconds (15 full breaths).
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs marginTop="16px">
				<Grid
					container
					item
					xs={1}
					alignItems="center"
					justifyContent="flex-end"
				>
					<TipsAndUpdatesRounded
						style={{
							marginRight: '4px',
							color: getCtaColor(),
							borderRadius: '50%'
							// padding: '4px'
						}}
					/>
				</Grid>
				<Grid
					container
					item
					xs
					alignItems="center"
					// justifyContent="center"
					// sx={{ backgroundColor: 'blue' }}
				>
					<Typography
						sx={{
							fontSize: '14px',
							fontFamily: 'arial',
							color: '#858580',
							// lineHeight: '24px',
							marginTop: '-16px',
							paddingBottom: '8px',
							marginLeft: '4px'
						}}
					>
						<br />
						Intentionally send deep breathes to any lingering
						feelings of discomfort in your body.
					</Typography>
				</Grid>
			</Grid>

			<Grid container item>
				<Breathwork
					progressCount={progressCount}
					setProgressCount={updateProgressCount}
					maxCount={4}
					completed={props.completed}
					size="large"
				/>
			</Grid>
		</Grid>
	);
	return <Template title="Embrace yourself" body={body} />;
};

const SubmitButton = (props: {
	onClick: () => void;
	disabled: boolean;
	cancel?: boolean;
}) => {
	return (
		<Button
			onClick={props.onClick}
			disabled={props.disabled}
			sx={{
				'&:hover': {
					backgroundColor: props.cancel ? 'white' : getCtaColor(),
					textDecoration: props.cancel ? 'underline' : undefined
				},
				width: '276px',
				height: '42px',
				marginTop: '8px',
				textTransform: 'none',
				textDecoration: props.cancel ? 'underline' : undefined,

				':disabled': {
					backgroundColor: props.cancel ? 'white' : '#E6E6E6'
				},
				backgroundColor: props.cancel ? 'white' : getCtaColor(),
				color: props.cancel ? '#2f2f2f' : 'white',
				cursor: 'pointer'
				//   marginBottom: "48px",
			}}
		>
			{props.cancel ? 'Skip' : 'Submit'}
		</Button>
	);
};

// const MainView = (props: {
//   setView: (view: string) => void;
//   onClick: () => void;
//   thought: Thought;
//   sageThought: SageThought;
// }) => {
//   return (
//     <>
//       <Sense emotions={props.thought.userSelectedEmotions} open={true} />
//       <Assess
//         challenge={props.sageThought.challenge}
//         challengeResponse={""}
//         open={false}
//       />
//       <GenerateJoy
//         userReframedThought=""
//         cosReframedThought={props.sageThought.reframedThought}
//       />
//       <Embrace onClick={props.onClick} />
//     </>
//   );
// };

const Guide = () => {
	const [expand, setExpand] = useState(false);
	const onExpand = () => setExpand(!expand);
	return (
		<>
			<Grid
				container
				item
				sx={{
					padding: '16px',
					height: '54px',
					borderRadius: '8px',
					borderBottomLeftRadius: expand ? 0 : '8px',
					borderBottomRightRadius: expand ? 0 : '8px',
					border: '1px solid #F1F1F1',
					width: '420px',
					textTransform: 'none',
					backgroundColor: 'white',
					cursor: 'pointer',
					marginBottom: expand ? '0px' : '40px'
				}}
				onClick={onExpand}
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography
					sx={{
						fontSize: '16px',
						lineHeight: '22px',
						fontWeight: 450,
						color: '#2D2B32'
					}}
				>
					What is box breathing?
				</Typography>
				{expand ? (
					<Remove
						sx={{
							width: '24px',
							height: '24px',
							color: '#AEB2BC'
						}}
					/>
				) : (
					<Add
						sx={{
							width: '24px',
							height: '24px',
							color: '#AEB2BC'
						}}
					/>
				)}
			</Grid>
			{expand && (
				<Grid
					container
					item
					direction="column"
					sx={{
						height: '196px',
						paddingLeft: '16px',
						paddingRight: '16px',
						borderRadius: '8px',
						borderTopLeftRadius: '0px',
						borderTopRightRadius: '0px',
						border: '1px solid #F1F1F1',
						borderTop: 'none',
						width: '420px',
						textTransform: 'none',
						backgroundColor: '#FAFBFC',
						marginBottom: '40px'
					}}
				>
					{[
						'1. Get into a comfortable position by sitting or lying down.',
						'2. Inhale slowly through your nose',
						'3. Hold your breath for a moment',
						'4. Exhale fully through your nose',
						'5. Hold your breath for a moment'
						// '6. Repeat'
					].map((step, idx) => (
						<Grid
							container
							item
							justifyContent="space-between"
							alignItems="center"
							marginTop="16px"
						>
							<Typography
								sx={{
									fontSize: '14px',
									fontWeight: 450,
									lineHeight: '20px',
									color: '#4F4D55'
								}}
							>
								{step}
							</Typography>
							{[1, 2, 3, 4].includes(idx) && (
								<Typography
									sx={{
										fontSize: '14px',
										fontWeight: 450,
										lineHeight: '20px',
										color: '#ADACB0'
									}}
								>
									4 secs
								</Typography>
							)}
						</Grid>
					))}
				</Grid>
			)}
		</>
	);
};

export const BreathCountSelector = () => {
	const [breaths, setBreaths] = useState(5);
	return (
		<Grid
			container
			item
			alignItems="center"
			width="276px"
			height="64px"
			marginBottom="4px"
			borderRadius="12px"
		>
			<Grid
				container
				item
				justifyContent="center"
				alignItems="center"
				onClick={() => setBreaths(breaths > 1 ? breaths - 1 : breaths)}
				sx={{
					width: '40px',
					height: '40px',
					border: '1px solid #F1F1F1',
					borderRadius: '12px',
					cursor: breaths > 1 ? 'cursor' : 'not-allowed'
				}}
			>
				<Remove
					sx={{
						width: '20px',
						height: '20px',
						color: breaths > 1 ? getCtaColor() : '#DCDCDE'
					}}
				/>
			</Grid>
			<Grid
				container
				item
				justifyContent="center"
				alignItems="center"
				sx={{
					marginLeft: '24px',
					marginRight: '24px',
					width: '148px',
					height: '48px',
					borderRadius: '12px',
					border: '1px solid #F1F1F1'
				}}
			>
				<input
					value={breaths}
					onChange={(e) => setBreaths(parseInt(e.target.value))}
					style={{
						width: '100px',
						fontSize: '24px',
						lineHeight: '28px',
						fontWeight: 500,
						textAlign: 'center',
						border: 'none'
					}}
				></input>
			</Grid>
			<Grid
				container
				item
				onClick={() => setBreaths(breaths + 1)}
				justifyContent="center"
				alignItems="center"
				sx={{
					width: '40px',
					height: '40px',
					border: '1px solid #F1F1F1',
					borderRadius: '12px',
					cursor: 'cursor'
				}}
			>
				<Add
					sx={{
						width: '20px',
						height: '20px',
						color: getCtaColor()
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default SageWorksheet;
