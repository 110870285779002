import React, { useRef, useState } from 'react';
import ThoughtEntry, { ReframedThoughtEntry } from './ThoughtEntry';

import { ArcherContainer } from 'react-archer';
import Filters from '../../pages/Vault/Filters';
import { Grid } from '@mui/material';
import { Thought } from '../../../Util/Redux/Schema';
import ThoughtsEmptyState from './ThoughtsEmptyState';

const Entries = ({
	thoughts,
	mutable,
	dayView,
	filters
}: {
	thoughts: Thought[];
	mutable: boolean;
	dayView?: boolean;
	filters?: any;
}) => {
	const ref = useRef(null);
	React.useEffect(() => {
		if (ref.current && mutable) {
			ref.current.scrollTop = ref.current.scrollHeight;
		}
	}, [thoughts]);
	const firstNegativeEntryIdx = thoughts.findIndex(
		(thought) => thought.sentiment?.toLowerCase() === 'negative'
	);
	return (
		<Grid
			ref={ref}
			container
			item
			xs
			direction="column"
			alignItems="flex-start"
			sx={{
				overflowY: 'auto',
				flexWrap: 'nowrap'
			}}
		>
			{filters}
			{thoughts.length === 0 ? (
				<ThoughtsEmptyState type={dayView ? 'day' : 'month'} />
			) : (
				[...thoughts].map((thought, idx) => (
					<Grid container item key={thought.uuid}>
						{/* <Grid
							container
							sx={{
								backgroundColor: '#BDF5FE',
								height: '240px',
								width: '32px',
								position: 'absolute',
								paddingLeft: '204px'
								// left: '108px'
							}}
						/> */}
						<ArcherContainer
							style={{ width: '100%' }}
							endMarker={false}
						>
							<ThoughtEntry
								showReframe={
									Boolean(thought.reframed) ||
									(idx === firstNegativeEntryIdx + 1 &&
										!mutable)
								}
								thought={thought}
								mutable={mutable}
							/>
						</ArcherContainer>
					</Grid>
				))
			)}
		</Grid>
	);
};

export default Entries;
