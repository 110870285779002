import {
	Alert,
	Avatar,
	CircularProgress,
	Fab,
	Grid,
	IconButton,
	Paper,
	TextField,
	Theme,
	Tooltip,
	Typography
} from '@mui/material';
import { AppDispatch, RootState } from './Util/Redux/Schema';
import {
	CheckCircle,
	Lock,
	LockOpen,
	SentimentVerySatisfied
} from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { SubmitButton, getCtaColor } from './Util/Helper';
import {
	authenticateUser,
	getHomepageContents,
	getMonthlyStats,
	getThoughtsDaily,
	getThoughtsMonthly,
	getWeeklyStats,
	loginWithPin,
	setBearerToken
} from './Util/Redux/AsyncMiddleware';
import {
	fetchThoughtNouns,
	fetchThoughts,
	setAuthorized
} from './Util/Redux/Middleware';
import { useDispatch, useSelector } from 'react-redux';

import HomePage from './components/pages/Home/HomePage';
import { makeStyles } from '@mui/styles';
import sageCircle from './assets/images/logo/sageCircle.svg';
import session from 'redux-persist/lib/storage/session';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		height: '100vh',
		alignItems: 'center'
	},
	circle: {
		width: '72px',
		height: '72px',
		// backgroundColor: 'white',
		border: '1px solid #ECECED',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		transition: 'transform 0.75s'
	},
	moveRight: {
		transform: 'translateX(50vw)'
	},
	moveLeft: {
		transform: 'translateX(-50vw)'
	},
	incorrect: {
		backgroundColor: 'red',
		transform: 'translateY(75vh)'
	},
	circle1: {
		backgroundColor: '#A1D0F9'
	},
	circle2: {
		backgroundColor: '#A1D0F9'
	},
	circle3: {
		backgroundColor: '#A1D0F9'
	},
	circle4: {
		backgroundColor: '#A1D0F9'
	}
}));

const LockScreen: React.FC = () => {
	const [passcode, setPasscode] = useState<string>('');
	const [isPasscodeCorrect, setIsPasscodeCorrect] = useState<boolean | null>(
		null
	);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const { user, logout } = useAuth0();
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const firstname = sessionStorage.getItem('username') || user?.given_name;
	const userPic = sessionStorage.getItem('userPic') || user?.picture;
	const [userTriedLogin, setUserTriedLogin] = useState(false);
	const goToDashboard = async () => {
		setLoading(true);
		setSuccess(true);
		// await setTimeout(() => {
		// 	navigate('/space');
		// }, 500);
		await dispatch(getHomepageContents());
		await dispatch(getThoughtsDaily());
		dispatch(getThoughtsMonthly());
		dispatch(getWeeklyStats());
		dispatch(getMonthlyStats());

		navigate('/space');
	};
	useEffect(() => {
		const handleKeyPress = (
			event: React.KeyboardEvent<HTMLInputElement>
		) => {
			// Update the text state with the typed character
			// console.log('Passcode', event.target.value);
			// const newPasscode = event.target.value;
			setPasscode((prevText) => prevText + event.key);
		};

		// Add the event listener to capture key presses
		window.addEventListener('keydown', handleKeyPress);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, []);
	useEffect(() => {
		const attemptLogin = async () => {
			await dispatch(loginWithPin(passcode));
			if (sessionStorage.getItem('isPinAuthorized') === 'True') {
				sessionStorage.setItem(
					'userTimezone',
					Intl.DateTimeFormat().resolvedOptions().timeZone
				);
				await goToDashboard();
			} else {
				setIsPasscodeCorrect(false);
				setPasscode('');
				setUserTriedLogin(true);
			}
		};
		if (passcode.length === 4) {
			attemptLogin();
		}
	}, [passcode]);
	// if (firstname === null) {
	// 	return <div> Loading...</div>;
	// }
	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			sx={{
				height: '100vh',
				width: '100vw',
				backgroundImage:
					'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)'
			}}
		>
			{/* <input
				type="text"
				style={{
					position: 'absolute',
					top: '-9999px',
					left: '-9999px'
				}}
				autoFocus // Focus the input automatically
				onChange={handleKeyDown} // Handle input change
			/> */}
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				direction="column"
				width="384px"
			>
				<Grid
					container
					item
					xs
					direction="column"
					marginBottom="32px"
					// sx={{ backgroundColor: 'blue' }}
					// justifyContent="center"
					// alignItems="center"
				>
					<a href={userPic} target="_blank" rel="noreferrer">
						<Avatar
							sx={{
								width: '80px',
								height: '80px',
								marginBottom: '8px'
							}}
							alt="Uu Profile Picture"
							src={userPic}
						/>
					</a>
					<Grid item xs>
						<Typography
							sx={{
								color: '#2D2B32',
								fontSize: '24px',
								lineHeight: '28px'
								// fontWeight: 600
							}}
						>
							{/* {`Welcome, ${firstname}`} */}
						</Typography>
					</Grid>

					{/* <img src={user?.picture} referrerpolicy="no-referrer" /> */}
					<Grid item xs marginTop="8px">
						<Typography
							sx={{
								color: '#7F7D83',
								fontSize: '16px',
								lineHeight: '22px',
								fontWeight: 400
							}}
						>
							Enter your PIN to proceed to your Sage space
						</Typography>
					</Grid>
				</Grid>
				{userTriedLogin && (
					<Alert
						sx={{
							width: '92%',
							marginBottom: '16px'
							// padding: '8px'
						}}
						severity="error"
					>
						Incorrect PIN. Please try again.
					</Alert>
				)}
				<Grid
					container
					item
					xs
					alignItems="center"
					justifyContent="space-between"
					sx={{ borderRadius: '50%' }}
					width="384px"
					height={72}
				>
					<Paper className={classes.circle}>
						{passcode.length >= 1 && (
							<Typography
								sx={{
									color: getCtaColor(),
									marginTop: '12px',
									fontWeight: 500,
									fontSize: '32px',
									lineHeight: '72px'
								}}
							>
								*
							</Typography>
						)}
					</Paper>
					<Paper className={classes.circle}>
						{passcode.length >= 2 && (
							<Typography
								sx={{
									color: getCtaColor(),
									marginTop: '12px',
									fontWeight: 500,
									fontSize: '32px',
									lineHeight: '72px'
								}}
							>
								*
							</Typography>
						)}
					</Paper>
					<Paper className={classes.circle}>
						{passcode.length >= 3 && (
							<Typography
								sx={{
									color: getCtaColor(),
									marginTop: '12px',
									fontWeight: 500,
									fontSize: '32px',
									lineHeight: '72px'
								}}
							>
								*
							</Typography>
						)}
					</Paper>
					<Paper className={classes.circle}>
						{passcode.length >= 4 && (
							<Typography
								sx={{
									color: getCtaColor(),
									marginTop: '12px',
									fontWeight: 500,
									fontSize: '32px',
									lineHeight: '72px'
								}}
							>
								*
							</Typography>
						)}
					</Paper>
				</Grid>
				{/* <Grid item xs marginTop="16px" width="100%" marginBottom="24px">
					{isPasscodeCorrect === false && (
						<Alert
							hidden={isPasscodeCorrect !== false}
							severity="error"
						>
							That's not the right PIN.
						</Alert>
					)}
				</Grid> */}
				<Fab style={{ backgroundColor: 'white', marginTop: '42px' }}>
					{success ? (
						// <CheckCircle style={{ color: getCtaColor() }} />
						// <SentimentVerySatisfied
						// 	style={{ color: getCtaColor() }}
						// />
						<img src={sageCircle} width="42px" />
					) : (
						<Lock style={{ color: getCtaColor() }} />
					)}

					{/* <img src={sageCircle} style={{ width: '76.22', height: '64px' }} /> */}
					{loading && (
						<CircularProgress
							size={68}
							sx={{
								color: getCtaColor(),
								position: 'absolute',
								top: -6,
								left: -6,
								zIndex: 1
							}}
						/>
					)}
				</Fab>
			</Grid>
		</Grid>
	);
};

export default LockScreen;
