import '../Animations/heartbeat.css';
import '../Animations/heartbeat.css';

import { AppDispatch, RootState, Thought } from './Redux/Schema';
import { Button, Divider, Grid, Typography } from '@mui/material';
import {
	LocalLibrary,
	SaveAlt,
	SelfImprovement,
	SentimentSatisfiedAlt,
	SentimentVeryDissatisfied,
	SportsGymnastics
} from '@mui/icons-material';
import {
	MaterialSymbolsAirRounded,
	MaterialSymbolsDirectionsRunRounded,
	SolarDumbbellBold
} from '../assets/svgFiles';

import { Dispatch } from 'redux';
import { ReactNode } from 'react';
import SectionHeaderTypography from '../components/atoms/SectionHeaderTypography';
import { addThought } from './Redux/Middleware';
import air from '../assets/gifs/air.gif';
import bodyImg from '../assets/images/svg-files/body.svg';
import dumbbellImg from '../assets/images/svg-files/dumbbell.svg';
import { format } from 'date-fns-tz';
import heartImg from '../assets/images/svg-files/heart.svg';
import heartbeatGif from '../assets/gifs/heartbeat.gif';
import { postThought } from './Redux/AsyncMiddleware';
import running from '../assets/gifs/running.gif';
import runningImg from '../assets/running.png';
import spiritImg from '../assets/images/svg-files/spirit.svg';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export const POSITIVE_EMOTIONS = [
	'Admiration',
	'Adoration',
	'Altruism',
	'Amusement',
	'Awe',
	'Calmness',
	'Connection',
	'Contentment',
	'Curiosity',
	'Determination',
	'Ecstasy',
	'Euphoria',
	'Excitement',
	'Freedom',
	'Gratitude',
	'Hope',
	'Intrigue',
	'Joy', // Primary
	'Love',
	'Passion',
	'Pride',
	'Quiescence',
	'Relief',
	'Serenity',
	'Surprise' // Primary
];

export const NEGATIVE_EMOTIONS = [
	'Anger', // Primary
	'Anguish',
	'Annoyance',
	'Apathy',
	'Bitterness',
	'Boredom',
	'Calamity',
	'Confusion',
	'Craving',
	'Crushedness',
	'Desperation',
	'Disappointment',
	'Disgust', // Primary
	'Dread',
	'Embarrassment',
	'Envy',
	'Exhaustion',
	'Fear', // Primary
	'Frustration',
	'Grief',
	'Guilt',
	'Hatred',
	'Heartache',
	'Hostility',
	'Humiliation',
	'Hurt',
	'Inadequacy',
	'Indifference',
	'Insecurity',
	'Irritation',
	'Jealousy',
	'Listlessness',
	'Loneliness',
	'Longing',
	'Lust',
	'Melancholy',
	'Nervousness',
	'Nostalgia',
	'Panic',
	'Pity',
	'Rage',
	'Regret',
	'Rejection',
	'Remorse',
	'Resentment',
	'Sadness', // Primary
	'Shame',
	'Shock',
	'Shyness',
	'Sorrow',
	'Stagnation',
	'Stress',
	'Terror',
	'Trepidation',
	'Uncertainty',
	'Unease',
	'Unhappiness',
	'Unrest',
	'Vengefulness',
	'Vulnerability',
	'Worry'
];

export const COGNITIVE_BIAS_DESCRIPTOR: { [key: string]: string } = {
	'Confirmation Bias':
		"The tendency to seek, interpret, and remember information that confirms one's pre-existing beliefs, while ignoring contradictory evidence.",

	'Availability Heuristic':
		'A mental shortcut that relies on immediate examples that come to mind when evaluating a situation or decision, often skewing perception of probability or importance.',

	'Anchoring Bias':
		"The human tendency to rely heavily on the first piece of information encountered (the 'anchor') when making decisions, even if it's irrelevant or arbitrary.",

	'Hindsight Bias':
		'The inclination to see past events as having been predictable after they have already occurred, often leading to an oversimplified view of outcomes.',

	'Availability Cascade':
		'A self-reinforcing cycle in which a belief gains increasing plausibility through its repetition in public discourse, regardless of its accuracy.',

	'Overconfidence Bias':
		"The tendency to be overly sure about the accuracy of one’s knowledge or abilities, often leading to risky decisions or overestimation of one's competence.",

	'Self-Serving Bias':
		'The tendency to attribute successes to personal factors while blaming failures on external factors, protecting self-esteem and self-image.',

	'Loss Aversion':
		'The cognitive bias where losses are perceived as more significant than gains of the same amount, making people more risk-averse to avoid loss rather than seeking equivalent gains.',

	'Sunk Cost Fallacy':
		'The tendency to continue investing in a decision, project, or situation based on prior investments of time, money, or effort, rather than current rational evaluation.',

	'Recency Bias':
		'The tendency to place greater importance on the most recent information or experiences when making decisions or judgments, often at the expense of earlier data or context.',

	'Negativity Bias':
		'The psychological tendency to give more attention and weight to negative experiences or information compared to positive or neutral ones, influencing perceptions and reactions.',

	'Social Proof':
		'The tendency to conform to the actions or beliefs of others, especially in situations of uncertainty, assuming that if many people are doing something, it must be correct.',

	Catastrophizing:
		'A cognitive distortion where a person assumes the worst possible outcome of a situation, often leading to heightened anxiety and avoidance behavior.',

	Personalization:
		"The tendency to attribute external events or others' actions as being directly related to oneself, often without sufficient evidence to support that interpretation.",

	Overgeneralization:
		'The cognitive distortion where a single negative event is seen as part of an endless pattern of defeat, leading to broad, overly negative conclusions.',

	'Mind Reading':
		'A cognitive distortion where one assumes they know what others are thinking, especially when assuming negative judgments or opinions without any real evidence.'
};
export const EMOTION_GROUP_COLOR_MAP = {
	Anger: '#F5A6A7',
	Anxiety: '#F9CAB1',
	Disgust: '#B3C7A9',
	Ennui: '#9EA3B8',
	Joy: '#F4E2B0',
	Sadness: '#AEC3E5',
	Surprise: '#E0CDEA'
};

export const EMOTION_DESCRIPTOR: { [key: string]: string } = {
	Admiration:
		'A feeling of respect and approval for someone or something, often driven by positive qualities or achievements.',

	Adoration:
		'Deep love and respect, typically mixed with awe or reverence for someone or something.',

	Altruism:
		'A selfless concern for the well-being of others, often manifesting in acts of kindness or generosity.',

	Amusement:
		'A light-hearted feeling of enjoyment or pleasure, usually triggered by something entertaining or funny.',

	Anger: 'A strong feeling of displeasure or hostility in response to perceived wrongs or injustices.',

	Anguish:
		'Severe emotional or mental pain, often stemming from intense suffering or distress.',

	Annoyance:
		'A mild form of irritation or frustration, typically caused by something persistent or bothersome.',

	Anticipation:
		'A feeling of excitement or anxiety about something that is going to happen, driven by expectations of future events.',

	Apathy: 'A lack of interest, enthusiasm, or concern, often resulting in emotional indifference or detachment.',

	Awe: 'A feeling of reverential respect mixed with fear or wonder, often inspired by something grand or powerful.',

	Bitterness:
		'A lingering feeling of resentment or anger, often caused by perceived injustice or betrayal.',

	Boredom:
		'A feeling of weariness and dissatisfaction arising from a lack of interest or excitement.',

	Calamity:
		'A feeling of extreme distress or concern, often in response to a catastrophic or disastrous event.',

	Calmness:
		'A peaceful, relaxed state of mind, free from agitation, excitement, or disturbance.',

	Confusion:
		'A state of being unsure or unclear about something, often accompanied by feelings of frustration or uncertainty.',

	Connection:
		'A sense of belonging or closeness to others, characterized by feelings of mutual understanding and support.',

	Contentment:
		"A state of happiness and satisfaction, often arising from acceptance of one's situation or life.",

	Craving:
		'A powerful desire for something, often related to physical needs or emotional longing.',

	Crushedness:
		'A deep feeling of emotional defeat or overwhelming sadness, often triggered by personal loss or failure.',

	Curiosity:
		'A desire to learn or know more about something, driven by an interest in exploration or discovery.',

	Distraction:
		'A feeling of being diverted or pulled away from focus, often leading to difficulty concentrating or staying on task.',

	Desire: 'A strong feeling of wanting to have or achieve something, often accompanied by motivation to pursue it.',

	Desperation:
		'A feeling of hopelessness or urgency, often leading to extreme actions or emotions in a difficult situation.',

	Determination:
		'A firm resolve or commitment to achieving a goal or overcoming obstacles, often fueled by inner strength.',

	Disappointment:
		'A feeling of sadness or displeasure when expectations or hopes are not met.',

	Disgust:
		'A strong feeling of aversion or repulsion, often in response to something offensive, distasteful, or morally wrong.',

	Dread: 'A feeling of fear or anxiety about something unpleasant that is likely to happen.',

	Ecstasy:
		'An overwhelming feeling of great happiness or joyful excitement, often intense and euphoric.',

	Embarrassment:
		'A feeling of awkwardness, shame, or discomfort, often triggered by a socially uncomfortable situation.',

	Envy: "A feeling of discontent or desire for someone else's possessions, qualities, or achievements.",

	Euphoria:
		'A feeling of intense happiness and excitement, often accompanied by a sense of invincibility or elation.',

	Excitement:
		'A state of heightened enthusiasm and eagerness, often in anticipation of something positive or thrilling.',

	Exhaustion:
		'A feeling of extreme physical or mental tiredness, often resulting from prolonged effort or stress.',

	Fear: 'A strong, unpleasant emotion caused by the belief that something dangerous or threatening is likely to happen.',

	Freedom:
		'A feeling of liberation or the absence of constraints, often associated with personal autonomy and independence.',

	Frustration:
		'A feeling of irritation and helplessness caused by obstacles preventing progress or success.',

	Gratitude:
		'A feeling of thankfulness and appreciation, often in response to kindness or positive outcomes.',

	Grief: 'A deep and intense sorrow, often resulting from the loss of a loved one or a significant life change.',

	Guilt: "A feeling of remorse or responsibility for something wrong or harmful, often linked to one's actions or decisions.",

	Hatred: 'An intense feeling of dislike or hostility, often directed toward someone or something perceived as harmful or offensive.',

	Heartache:
		'A deep emotional pain, often associated with loss, longing, or unrequited love.',

	Hope: 'An optimistic state of mind that expects positive outcomes or solutions to challenging situations.',

	Hostility:
		'A feeling of antagonism or opposition, often accompanied by aggressive or unfriendly behavior.',

	Humiliation:
		'A feeling of deep shame or embarrassment, often caused by being degraded or exposed to ridicule.',

	Hurt: 'A feeling of emotional pain or distress, often caused by rejection, betrayal, or disappointment.',

	Inadequacy:
		'A feeling of not being good enough or lacking the necessary qualities or abilities to succeed.',

	Indifference:
		'A lack of interest or concern, often leading to emotional detachment from situations or people.',

	Insecurity:
		"A feeling of uncertainty or anxiety about oneself, often resulting from doubts about one's abilities or worth.",

	Intrigue:
		'A feeling of curiosity and fascination, often sparked by something mysterious or novel.',

	Irritation:
		'A feeling of mild annoyance or discomfort, often caused by something persistent or trivial.',

	Jealousy:
		'A feeling of resentment or envy toward someone who has something one desires, often coupled with insecurity.',

	Joy: 'A feeling of great happiness and pleasure, often accompanied by a sense of well-being and contentment.',

	Listlessness:
		'A state of lacking energy or enthusiasm, often leading to an absence of motivation or interest.',

	Loneliness:
		'A feeling of sadness or emptiness resulting from being isolated or disconnected from others.',

	Longing:
		'A persistent desire or yearning for something, often accompanied by feelings of sadness or incompleteness.',

	Love: 'A deep and enduring emotional connection with someone or something, characterized by affection, care, and commitment.',

	Lust: 'A strong and intense desire, usually of a sexual nature, often driven by physical attraction or passion.',

	Melancholy:
		'A deep feeling of sadness or gloom, often accompanied by reflection on the past or a sense of longing.',

	Nervousness:
		'A feeling of unease or anxiety, often accompanied by physical symptoms like restlessness or a racing heart.',

	Nostalgia:
		'A sentimental longing for the past, often accompanied by bittersweet feelings of both joy and sorrow.',

	Panic: 'A sudden, overwhelming feeling of fear or anxiety, often leading to a loss of control or frantic behavior.',

	Passion:
		'A powerful emotion, often associated with intense enthusiasm or desire for something or someone.',

	Pity: "A feeling of sorrow and compassion for someone else's suffering or misfortune.",

	Pride: "A feeling of deep satisfaction and self-respect, often stemming from one's own achievements or those of close others.",

	Quiescence:
		'A state of quietness or inactivity, often accompanied by a sense of peace or stillness.',

	Rage: 'An intense and uncontrolled feeling of anger, often accompanied by aggressive or violent behavior.',

	Regret: 'A feeling of sorrow or disappointment over something that has happened or a missed opportunity.',

	Rejection:
		'A feeling of being dismissed or not accepted, often leading to emotional pain or insecurity.',

	Relief: 'A feeling of reassurance and relaxation following the removal of stress or uncertainty.',

	Remorse:
		'A deep feeling of regret or guilt for a wrong committed, often accompanied by a desire to make amends.',

	Resentment:
		'A feeling of bitterness or anger toward someone or something, often resulting from perceived unfair treatment.',

	Sadness:
		'A feeling of sorrow or unhappiness, often accompanied by a sense of loss or disappointment.',

	Sentimentality:
		'A tender emotional feeling, often nostalgic or overly sweet, sometimes to an exaggerated or unrealistic degree.',

	Serenity:
		'A state of calm and peacefulness, often associated with a sense of inner tranquility and balance.',

	Shame: 'A painful feeling of humiliation or distress caused by the awareness of wrong or foolish behavior.',

	Shock: 'A sudden and intense feeling of surprise or disbelief, often in response to an unexpected or disturbing event.',

	Shyness:
		'A feeling of discomfort or nervousness in social situations, often leading to a desire to avoid attention.',

	Sorrow: 'A deep and profound feeling of sadness or grief, often caused by loss or misfortune.',

	Stagnation:
		'A feeling of lack of progress or growth, often accompanied by frustration or dissatisfaction.',

	Stress: 'A feeling of emotional or mental strain, often caused by demanding situations or external pressures.',

	Surprise:
		'A sudden feeling of astonishment or wonder, often in response to something unexpected.',

	Terror: 'An extreme and overwhelming fear, often resulting in panic or a sense of helplessness.',

	Thrill: 'A feeling of excitement or exhilaration, often accompanied by a sense of heightened energy or anticipation.',

	Trepidation:
		'A feeling of fear or apprehension about something that may happen, often leading to hesitation.',

	Uncertainty:
		'A feeling of doubt or confusion, often arising from a lack of clear information or direction.',

	Unease: 'A feeling of discomfort or worry, often resulting from uncertainty or unfamiliarity with a situation.',

	Unhappiness:
		'A state of dissatisfaction or discontent, often characterized by feelings of sadness or discomfort.',

	Unrest: 'A feeling of disturbance or instability, often arising from unresolved tension or conflict.',

	Vengefulness:
		'A strong desire for revenge or retaliation, often fueled by feelings of anger or injustice.',

	Vulnerability:
		'A feeling of being exposed or open to emotional or physical harm, often leading to a sense of insecurity or fragility.',

	Worry: 'A feeling of anxiety or concern about potential problems or future events, often accompanied by restlessness.'
};

export const getEmotionGroup = (emotion: string) => {
	if (EMOTION_GROUP['Anger'].includes(emotion)) {
		return 'Anger';
	} else if (EMOTION_GROUP['Anxiety'].includes(emotion)) {
		return 'Anxiety';
	} else if (EMOTION_GROUP['Disgust'].includes(emotion)) {
		return 'Disgust';
	} else if (EMOTION_GROUP['Ennui'].includes(emotion)) {
		return 'Ennui';
	} else if (EMOTION_GROUP['Joy'].includes(emotion)) {
		return 'Joy';
	} else if (EMOTION_GROUP['Sadness'].includes(emotion)) {
		return 'Sadness';
	} else if (EMOTION_GROUP['Surprise'].includes(emotion)) {
		return 'Surprise';
	} else {
		throw new Error('No Emotion Map');
	}
};
const EMOTION_GROUP: { [key: string]: string[] } = {
	Anger: [
		'Anger',
		'Annoyance',
		'Bitterness',
		'Frustration',
		'Hatred',
		'Hostility',
		'Irritation',
		'Jealousy',
		'Rage',
		'Resentment',
		'Vengefulness'
	],
	Anxiety: [
		'Confusion',
		'Desperation',
		'Dread',
		'Embarrassment',
		'Inadequacy',
		'Insecurity',
		'Nervousness',
		'Panic',
		'Shame',
		'Shock',
		'Stress',
		'Trepidation',
		'Uncertainty',
		'Unease',
		'Worry'
	],
	Joy: [
		'Admiration',
		'Adoration',
		'Altruism',
		'Amusement',
		'Anticipation',
		'Awe',
		'Calmness',
		'Connection',
		'Contentment',
		'Curiosity',
		'Desire',
		'Determination',
		'Ecstasy',
		'Euphoria',
		'Excitement',
		'Freedom',
		'Gratitude',
		'Hope',
		'Joy',
		'Love',
		'Passion',
		'Pride',
		'Relief',
		'Serenity',
		'Surprise',
		'Thrill'
	],
	Disgust: [
		'Disgust',
		'Embarrassment',
		'Envy',
		'Guilt',
		'Humiliation',
		'Pity',
		'Regret',
		'Remorse'
	],
	Sadness: [
		'Anguish',
		'Boredom',
		'Calamity',
		'Crushedness',
		'Disappointment',
		'Grief',
		'Heartache',
		'Hurt',
		'Loneliness',
		'Melancholy',
		'Rejection',
		'Sadness',
		'Sorrow',
		'Unhappiness'
	],
	Fear: ['Fear', 'Dread', 'Panic', 'Shock', 'Terror', 'Vulnerability'],
	Ennui: [
		'Apathy',
		'Boredom',
		'Exhaustion',
		'Indifference',
		'Listlessness',
		'Quiescence',
		'Stagnation',
		'Unrest'
	]
};
export const COLORS = {
	wellbeing: {
		backgroundColor: '#EFECFA',
		color: '#D0C1F0',
		mid: '#DED2F3',
		text: '#897BB3',
		colorText: 'purple'
	}
};
export function getTimeDifference(timestamp: string) {
	const now = new Date();
	const time = new Date(timestamp);
	const diffInSeconds = Math.floor((now - time) / 1000);

	if (diffInSeconds < 60) {
		return `< 1 minute ago`;
	} else if (diffInSeconds < 3600) {
		const minutes = Math.floor(diffInSeconds / 60);
		return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
	} else {
		const hours = Math.floor(diffInSeconds / 3600);
		return `${hours} hour${hours === 1 ? '' : 's'} ago`;
	}
}

export function formatTime(timeString: string): string {
	const time = new Date(timeString);
	const isToday = (date: Date) => {
		const today = new Date();
		return (
			date.getDate() === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear()
		);
	};

	if (isToday(time)) {
		return time.toLocaleTimeString('en-US', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true
		});
	}

	function formatDate(date: Date) {
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		const hours = date.getHours();
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const ampm = hours >= 12 ? 'PM' : 'AM';
		const formattedHours = (hours % 12 || 12).toString();

		return `${month}/${day} ${formattedHours}:${minutes}${ampm}`;
	}
	return formatDate(time);
}
export function getMondayOfCurrentWeek() {
	const date = new Date();
	const day = date.getDay(); // Sunday - Saturday : 0 - 6
	//  Day of month - day of week (-6 if Sunday), otherwise +1
	const diff = date.getDate() - day + (day === 0 ? -6 : 1);
	return diff;
	// date.setDate(diff), date;
}
export const isSameDay = (date1String: string, date2String: string) => {
	const date1 = new Date(date1String);
	const date2 = new Date(date2String);
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
};
export function getTotalDaysInThisMonth(): number {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed

	const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();

	return lastDayOfMonth;
}

export function InsightsCard(props: {
	title: string;
	children: ReactNode;
	insightType: string;
	insights?: string[];
	marginTop?: string;
}) {
	return (
		<Grid
			container
			item
			xs
			marginTop={props.marginTop}
			// width={{ xl: '600px' }}
			// height="100%"
			direction="column"
			sx={{
				boxShadow: `0px 0px 2px 2px white`, // Apply shadow effect
				borderRadius: '16px',
				border: '1px solid #F3F5FA'
				// backgroundColor: '#F3F5FA'
			}}
		>
			{/* <Grid item xs={1} height="104px" padding="24px" direction="column">
				<SectionHeaderTypography title={props.title} />
				<Typography
					sx={{
						fontSize: '14px',
						lineHeight: '20px',
						fontWeight: 450,
						color: '#7F7D83'
					}}
				>
					{props.insightType}
				</Typography>
			</Grid>
			<Divider color="#F1F1F1" /> */}
			{/* <Grid container item xs>
				
			</Grid> */}
			{/* <SectionHeaderTypography title={props.title} /> */}
			{/* <Typography
				sx={{
					cursor: 'default',
					fontFamily: 'arial',
					fontSize: '16px',
					lineHeight: '24px',
					fontWeight: 500,
					color: '#0A090B',
					padding: '24px'
				}}
			>
				{props.title}
			</Typography> */}
			{props.children}
			{props.insights && (
				<Grid container item xs={1} padding="24px" paddingTop="0px">
					<KeyInsightsBox insights={props.insights} />
				</Grid>
			)}
		</Grid>
	);
}
export function getMondayToSunday() {
	const now = new Date();
	const currentDay = now.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

	// Calculate the difference between the current day and Monday
	const mondayDiff = currentDay - 1;
	const monday = new Date(now);
	monday.setDate(now.getDate() - mondayDiff);

	// Calculate the difference between Sunday and the current day
	const sundayDiff = 7 - currentDay;
	const sunday = new Date(now);
	sunday.setDate(now.getDate() + sundayDiff);

	// Check if Monday and Sunday are in the same month
	const isSameMonth = monday.getMonth() === sunday.getMonth();
	const isSameYear = monday.getFullYear() === sunday.getFullYear();

	// Format the result accordingly
	const formatter = new Intl.DateTimeFormat('en-US', {
		month: 'long'
	});
	let result;
	result = isSameMonth
		? ` ${formatter.format(
				sunday
		  )} ${monday.getDate()} - ${sunday.getDate()}`
		: `${formatter.format(monday)} ${monday.getDate()} ${
				!isSameYear ? monday.getFullYear() : ''
		  } - ${formatter.format(sunday)} ${sunday.getDate()} ${
				!isSameYear ? monday.getFullYear() : ''
		  }`;

	return result;
}

export function distributePercentage(items: number[]): number[] {
	const totalItems: number = items.reduce((acc, curr) => acc + curr, 0);
	const individualPercentages: number[] = items.map((item) =>
		Math.floor(100 * (item / totalItems))
	);
	const remainder: number =
		100 - individualPercentages.reduce((acc, curr) => acc + curr, 0);

	// Distribute the remainder
	for (let i = 0; i < remainder; i++) {
		const maxIndex: number = individualPercentages.indexOf(
			Math.max(...individualPercentages)
		);
		individualPercentages[maxIndex]++;
	}

	return individualPercentages;
}

export function getSentimentColor(type: string) {
	if (type === null || type === undefined) {
		return {};
	}
	if (type === 'unknown' || type === 'undefined') {
		return {
			backgroundColor: '#F8F8F8',
			color: '#F8F8F8',
			mid: '#F8F8F8',
			textColor: '#F8F8F8',
			text: '#F8F8F8'
		}; // '#F8F8F8';
	}
	// if (
	// 	['Positive', 'positive', 'joyful', 'Joyful', 'neutral'].includes(type)
	// ) {
	// 	return {
	// 		backgroundColor: '#FFF9E0',
	// 		color: '#fdea9a',
	// 		mid: '#FFF8D5',
	// 		textColor: '#FFC76A'
	// 	};
	// }
	if (type === 'reframed') {
		return {
			backgroundColor: '#e2f1ea',
			color: '#A9D4C0',
			mid: '#FED7D8',
			textColor: '#F75656',
			text: '#8BB6A2'
		};
	}
	if (
		['sad', 'Sad', 'Harsh', 'negative', 'harsh'].includes(type)
		//  ||
		// type?.toLowerCase() === 'negative'

		// (type && type.toLowerCase() === 'negative')
		// ||
	) {
		return {
			backgroundColor: '#ffe2e1',
			color: '#FDA7A6',
			mid: '#FED7D8',
			textColor: '#F75656',
			text: '#FDA7A6'
		};
	}
	return {
		backgroundColor: '#ffefd2',
		color: '#FDDFA5',
		mid: '#CBE5D9',
		textColor: '#016626',
		text: '#FFC536'
	};
}

export function getMonth() {
	const now = new Date();
	// if (useString) {
	// 	return now.toLocaleString('default', { month: 'short' });
	// }
	const shortMonth = now.toLocaleString('default', { month: 'short' });
	return shortMonth;
}

export function getYear() {
	const now = new Date();
	return now.getFullYear();
}

export function getSentimentIcon(type: string) {
	return {
		positive: SentimentSatisfiedAlt,
		negative: SentimentVeryDissatisfied
	}[type];
}

export function getCtaColor(alpha?: number = 1) {
	return `rgb(166, 152, 192, ${alpha})`;
	// return '#2F4150';
	// return '#FF9500';
	// return '#F3AD68';
}

export function getCtaHoverColor() {
	return '#ACCBC2';
}

export function SubmitButton(props: {
	text: string;
	marginLeft?: string;
	marginBottom?: string;
	fullWidth?: boolean;
	onClick?: () => void;
}) {
	return (
		<Button
			fullWidth={props.fullWidth}
			onClick={() => props.onClick && props.onClick()}
			sx={{
				marginLeft: props.marginLeft,
				marginBottom: props.marginBottom,
				width: props.fullWidth ? undefined : '120px',
				height: props.fullWidth ? undefined : '40px',
				'&:hover': {
					backgroundColor: getCtaColor()
				},
				backgroundColor: getCtaColor(),
				textTransform: 'none'
			}}
		>
			<Typography
				sx={{
					fontSize: '14px',
					lineHeight: '20px',
					fontWeight: 450,
					color: 'white',
					letterSpacing: '-0.05px'
				}}
			>
				{props.text}
			</Typography>
		</Button>
	);
}

export const getTimestamp = () => {
	const now = new Date();
	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, '0');
	const day = String(now.getDate()).padStart(2, '0');
	const hours = String(now.getHours()).padStart(2, '0');
	const minutes = String(now.getMinutes()).padStart(2, '0');
	const seconds = String(now.getSeconds()).padStart(2, '0');
	const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
	const timezoneOffset = now.getTimezoneOffset();
	const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60))
		.toString()
		.padStart(2, '0');
	const timezoneOffsetMinutes = (Math.abs(timezoneOffset) % 60)
		.toString()
		.padStart(2, '0');
	const timezoneSign = timezoneOffset >= 0 ? '-' : '+';

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`;
};

export const onAddThought = async (message: string, dispatch: AppDispatch) => {
	dispatch(
		addThought({
			createdAt: getTimestamp(),
			sentiment: 'unknown',
			thought: message,
			computedTopEmotions: [],
			uuid: null,
			userSelectedEmotions: [],
			reframedThought: '',
			challenge: "'",
			challengeResponse: ''
		})
	);
};

export const onPostThought = async (message: string, dispatch: AppDispatch) => {
	dispatch(postThought(message));
};

export const wellbeingColor = {
	Spirit: {
		backgroundColor: '#EFECFA',
		color: '#D0C1F0',
		mid: '#DED2F3',
		text: '#897BB3',
		colorText: 'purple'
	},
	Mind: {
		backgroundColor: '#EFECFA',
		// color: '#A9D4C0',
		color: '#FDDFA5',
		mid: '#FDDFA5',
		text: '#5E9C88',
		colorText: 'red'
	},
	Body: {
		backgroundColor: '#EFECFA',
		color: '#FDA7A6',
		mid: '#FFB6D3',
		text: '#B86D6D',
		colorText: 'yellow'
	}
};

export const goldenHourColor = {
	Spirit: {
		backgroundColor: '#ECE7FF',
		color: '#C7BBF8',
		mid: '#C7BBF8',
		text: '#7C5CF3',
		colorText: 'purple'
	},
	Mind: {
		backgroundColor: '#D4E0FB',
		color: '#B8C9F3',
		mid: '#B8C9F3',
		text: '#446ACC',
		colorText: 'blue'
	},
	Body: {
		backgroundColor: '#FDEBF2',
		color: '#FFB6D3',
		mid: '#FFB6D3',
		text: '#F05F99',
		colorText: 'red'
	}
};

export function SaveButton() {
	return (
		<Button
			style={{
				backgroundColor: getCtaColor(),
				margin: '8px 0px',
				textTransform: 'none',
				borderRadius: '16px'
			}}
			onClick={() => {}}
		>
			<Typography
				sx={{ color: 'white', fontWeight: 600, fontSize: '12px' }}
			>
				Save
			</Typography>
		</Button>
	);
}
export function getGoldenHourColor(type: 'Spirit' | 'Mind' | 'Body') {
	return wellbeingColor[type];
}

export function getGoldenHourIcon(type: 'Mind' | 'Body' | 'Spirit') {
	return {
		Mind: LocalLibrary,
		Body: SportsGymnastics,
		Spirit: SelfImprovement
	}[type];
}

export function getWellbeingImage(
	type: 'Mind' | 'Body' | 'Spirit',
	isHovered: boolean
) {
	return {
		Mind: (
			<svg
				// align="center"
				// style={{ backgroundColor: "red" }}
				className={isHovered ? 'heartbeat' : ''}
				xmlns="http://www.w3.org/2000/svg"
				width="24px"
				height="24px"
				viewBox="0 0 18 18"
			>
				<path
					fill="#D0C2F1"
					fill-rule="evenodd"
					d="M1.633 2.796C2.395 1.959 3.483 1.5 4.76 1.5c1.164 0 2.407.55 3.24 1.625C8.827 2.05 10.065 1.5 11.24 1.5c1.274 0 2.36.457 3.124 1.292c.756.828 1.136 1.962 1.136 3.221c0 2.165-1.113 3.908-2.522 5.263c-1.405 1.352-3.17 2.383-4.633 3.14a.75.75 0 0 1-.693-.002c-1.463-.765-3.228-1.788-4.633-3.133C1.61 9.93.5 8.193.5 6.013c0-1.255.378-2.389 1.133-3.217m1.109 1.01C2.287 4.306 2 5.053 2 6.013c0 1.624.816 2.996 2.057 4.184c1.146 1.099 2.6 1.985 3.945 2.705c1.335-.71 2.79-1.604 3.937-2.707C13.182 8.999 14 7.62 14 6.013c0-.963-.288-1.71-.744-2.21C12.808 3.314 12.14 3 11.24 3c-.976 0-2.093.627-2.527 1.948a.75.75 0 0 1-1.426 0C6.854 3.628 5.725 3 4.76 3c-.903 0-1.57.314-2.018.806"
					clip-rule="evenodd"
				/>
			</svg>
			// <svg
			// 	className={isHovered ? "heartbeat" : ""}
			// 	xmlns="http://www.w3.org/2000/svg"
			// 	width="28"
			// 	height="28"
			// 	viewBox="0 0 24 24"
			// >
			// 	<path
			// 		fill="#D0C2F1"
			// 		d="M2 9.137C2 14 6.02 16.591 8.962 18.911C10 19.729 11 20.5 12 20.5s2-.77 3.038-1.59C17.981 16.592 22 14 22 9.138c0-4.863-5.5-8.312-10-3.636C7.5.825 2 4.274 2 9.137"
			// 	/>
			// </svg>
		),
		Body: isHovered ? (
			<img src={running} width="24px" height="24px" />
		) : (
			<img src={runningImg} width="24px" height="24px" />
		),
		// <MaterialSymbolsDirectionsRunRounded />,
		Spirit: isHovered ? (
			<img src={air} width="24px" height="24px" />
		) : (
			<MaterialSymbolsAirRounded />
		)
	}[type];
}

export function getGoldenHourIndex(type: 'Spirit' | 'Mind' | 'Body') {
	return type === 'Spirit' ? 0 : type === 'Mind' ? 1 : 2;
}

export function KeyInsightsBox(props: { insights: string[] }) {
	return (
		<Grid
			container
			item
			xs
			direction="column"
			// justifyContent="flex-end"
			alignItems="flex-start"
			border="1px solid #ECECED"
			marginTop="24px"
			padding="24px"
			borderRadius="16px"
			// sx={{ backgroundColor: '#F6E5FE' }}
		>
			<Typography
				sx={{
					fontSize: '16px',
					lineHeight: '32px',
					fontWeight: 600,
					fontFamily: 'arial'
				}}
			>
				Key Insights
			</Typography>
			{props.insights.map((insight) => (
				<Typography
					sx={{
						fontSize: '14px',
						lineHeight: '32px',
						fontWeight: 500,
						fontFamily: 'arial'
					}}
				>
					<li>{insight}</li>
				</Typography>
			))}
		</Grid>
	);
}

export function filterTodaysThoughts(thoughts: Thought[]) {
	const today = new Date().toString();
	return thoughts.filter((obj) => {
		return isSameDay(obj.createdAt, today);
	});
}

export function getTodayDay() {
	const today = new Date();
	return today.getDate();
}

export function getMonthYear() {
	const today = new Date();
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	const month = months[today.getMonth()];
	const year = today.getFullYear();
	return `${month}, ${year}`;
}
export function getDayOfWeek(date: Date): string {
	const daysOfWeek = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	];
	return daysOfWeek[date.getDay()];
}

export function getToday() {
	const date = new Date();
	return formatDate(date);
}

export function formatDate(date: Date): string {
	const daysOfWeek = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	];
	const dayOfWeek = daysOfWeek[date.getDay()];
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	const day = date.getDate();
	const month = date.getMonth() + 1;
	// months[date.getMonth()];
	const year = date.getFullYear() % 100;
	function getOrdinalSuffix(day: number) {
		if (day >= 11 && day <= 13) {
			return `${day}th`;
		} else {
			const lastDigit = day % 10;
			switch (lastDigit) {
				case 1:
					return `${day}st`;
				case 2:
					return `${day}nd`;
				case 3:
					return `${day}rd`;
				default:
					return `${day}th`;
			}
		}
	}
	const formattedDate = `${month}/${day}/${year} ${getOrdinalSuffix(day)}`;
	// const formattedDate = `${month}/${day}/${year}`;
	// return formattedDate;

	return date.toLocaleDateString('en-US', {
		month: 'long',
		day: 'numeric',
		year: 'numeric'
	});
}

export function getCurrentTime() {
	const currentTime = new Date();
	return currentTime.toLocaleTimeString([], {
		hour: 'numeric',
		minute: '2-digit',
		hour12: true
	});
}

export const getUtcTimestamp = () => {
	const utcTimestamp = new Date().toISOString();
	return format(new Date(utcTimestamp), 'yyyy-MM-dd HH:mm:ss XXX', {
		timeZone: 'UTC'
	});
};
