import React, { useState, useEffect } from 'react';
import './LandingPage.css';
import { Grid, Typography } from '@mui/material';

const RotatingHeader = ({
	options,
	interval
}: {
	options: string[];
	interval: number;
}) => {
	const [index, setIndex] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setIndex((prevIndex) => (prevIndex + 1) % options.length);
		}, interval);

		return () => clearInterval(timer);
	}, [options, interval]);

	return (
		<Grid container item xs sx={{ width: '100%', position: 'relative' }}>
			{options.map((option, i) => (
				<Grid
					container
					item
					xs
					key={i}
					height="20px"
					// justifyContent="center"
					// alignItems="center"
					className={`cube-face ${index === i ? 'visible' : ''}`}
				>
					<Typography
						sx={{
							fontSize: '24px',

							lineHeight: '32px',
							fontWeight: 500,
							fontFamily: 'arial',
							color: '#2f2f2f',
							marginTop: '24px'
						}}
					>
						{option}
					</Typography>
				</Grid>
			))}
		</Grid>
	);
};

export default RotatingHeader;
