import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Close, Error } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import MainLayout from "../templates/MainTemplate";
import StyledModal from "../molecules/StyledModal";
import { RootState } from "../../Util/Redux/Schema";
import { getCtaColor, SubmitButton } from "../../Util/Helper";
import checkIcon from "../..//assets/images/svg-files/check.svg";
import { setAuthenticated, setAuthorized } from "../../Util/Redux/Middleware";

const AccountSettingsPage = (props: { tab: string }) => {
  return (
    <MainLayout page="Account Settings">
      <AccountSettings tab={props.tab} />
    </MainLayout>
  );
};

const AccountSettings = (props: { tab: string }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={10}
      xl={8}
      // paddingTop="10%"
      // width="100%"
      // padding={5}
      direction="column"
      // justifyContent="center"
      // alignItems="center"
    >
      <Typography
        sx={{
          fontSize: "24px",
          lineHeight: "28px",
          fontWeight: 600,
          color: "#0A090B",
          letterSpacing: "-3%",
          marginBottom: "24px",
        }}
      >
        Settings
      </Typography>
      <BasicTabs {...{ selectedTab, setSelectedTab }} />
      {selectedTab === 0 && <Profile />}
      {selectedTab === 1 && <Account />}
      {selectedTab === 2 && <Subscriptions />}
    </Grid>
  );
};

function Profile() {
  const name = useSelector((state: RootState) => state.user.firstName);
  return (
    <TitleGrid divider title="Your Profile">
      <>
        <Grid container item xs padding="20px">
          <Avatar
            sx={{
              width: "64px",
              height: "64px",
              bgcolor: "#E4F2EC",
              color: getCtaColor(),
            }}
          >
            {name[0]}
          </Avatar>
          <Grid container item xs direction="column" marginLeft="12px">
            <FieldText text="Profile Picture" marginBottom="4px" />
            <HelperText
              text="PNGs and JPEGs under 10MB"
              marginTop="4px"
              marginBottom="8px"
            />
            <UpdateButton text="Upload image" />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs
          direction="column"
          paddingLeft="20px"
          paddingBottom="32px"
        >
          <FieldText text="Display Name" marginBottom="4px" />
          <TextField
            InputProps={{
              style: {
                height: "40px", // Set the desired height here
              },
            }}
            sx={{ width: "280px", height: "40px" }}
          />
        </Grid>
        <SubmitButton
          text="Save changes"
          marginLeft="20px"
          marginBottom="20px"
        />
      </>
    </TitleGrid>
  );
}

function HelperText(props: {
  text: string;
  marginTop?: string;
  marginBottom?: string;
  darker?: boolean;
  darkest?: boolean;
}) {
  return (
    <Typography
      sx={{
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 450,
        color: props.darkest ? "#0A090B" : props.darker ? "#4F4D55" : "#7F7D83",
        letterSpacing: "-.05px",
      }}
    >
      {" "}
      {props.text}
    </Typography>
  );
}

function FieldText(props: { text: string; marginBottom?: string }) {
  return (
    <Typography
      marginBottom={props.marginBottom}
      sx={{
        fontSize: "16px",
        lineHeight: "22px",
        fontWeight: 450,
        color: "#2D2B32",
        letterSpacing: "-.18px",
      }}
    >
      {props.text}
    </Typography>
  );
}

function TitleGrid(props: {
  title: string;
  children: React.ReactNode;
  marginTop?: boolean;
  divider?: boolean;
}) {
  return (
    <Grid container direction="column">
      <Grid
        container
        item
        xs
        direction="column"
        sx={{
          marginTop: props.marginTop ? "16px" : undefined,
          border: "1px solid #F1F1F1",
          borderRadius: "16px",
          backgroundColor: "white",
        }}
      >
        <TitleText text={props.title} />
        {props.divider && <Divider />}
        {props.children}
      </Grid>
    </Grid>
  );
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: getCtaColor(),
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: getCtaColor(),
    opacity: 1,
    // transition: theme.transitions.create(['background-color'], {
    //     duration: 500,
    // }),
  },
}));

function MonthlyYearlySwitch() {
  return (
    <Grid container item xs alignItems="center" justifyContent="center">
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "16px",
          letterSpacing: "-0.13px",
          color: "#0A090B",
        }}
      >
        Pay monthly
      </Typography>
      <IOSSwitch sx={{ m: 1 }} defaultChecked />
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "16px",
          letterSpacing: "-0.13px",
          color: "#0A090B",
        }}
      >
        Pay yearly
      </Typography>
      <Typography
        sx={{
          marginLeft: "4px",
          fontWeight: 450,
          fontSize: "12px",
          lineHeight: "18px",
          color: getCtaColor(),
        }}
      >
        (Save 10%)
      </Typography>
    </Grid>
  );
}

function Subscriptions() {
  return (
    <Grid container direction="column">
      <TitleGrid title="Current Subscriptions" divider>
        <Grid
          container
          item
          xs
          padding="20px"
          paddingTop="0px"
          direction="column"
        >
          <Grid
            container
            item
            xs
            paddingBottom="24px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid container item xs direction="column">
              <TitleText
                text="Explorer"
                paddingLeft="0px"
                paddingBottom="4px"
              />
              <HelperText
                darker
                text="For those just getting started."
                marginBottom="8px"
              />
            </Grid>
            <Grid
              container
              item
              xs
              justifyContent="flex-end"
              paddingRight="20px"
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "28px",
                  fontWeight: 600,
                  color: "#019939",
                  letterSpacing: "-2%",
                }}
              >
                $0
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Typography
            marginBottom="8px"
            marginTop="24px"
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 450,
              color: "#4F4D55",
              letterSpacing: "-.05px",
            }}
          >
            Your free trial ends in{" "}
            {<span style={{ fontWeight: "bold" }}>3 days</span>}.
          </Typography>
          <HelperText
            darker
            text="Get the premium plan to access more features."
            marginBottom="32px"
          />
          <SubmitButton text="Upgrade now" />
        </Grid>
      </TitleGrid>
      <TitleGrid title="Subscription Plans" marginTop divider>
        <Grid
          container
          item
          xs
          padding="20px"
          paddingTop="0px"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            item
            xs
            alignItems="center"
            justifyContent="center"
            margin="24px"
          >
            <MonthlyYearlySwitch />
          </Grid>
          <Grid container item xs padding="0px 84px">
            <Grid
              container
              item
              xs
              sx={{
                border: "1px solid #F1F1F1",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              borderRadius="16px"
              marginRight="12px"
              direction="column"
              padding="20px"
            >
              <SubscriptionPackage
                type="Explorer"
                cost={0}
                features={[
                  "Thought & journal entries",
                  "Basic thought sentiment analysis",
                  "Golden hour wellbeing tracker",
                  "Daily Check-in",
                ]}
              />
            </Grid>
            <Grid
              container
              item
              xs
              height="380px"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                borderRadius: "16px",
                padding: "3px",
                backgroundImage:
                  "linear-gradient(145deg, #FDDEA5, #A6D5C1, #FFA6A5)",
                border: "2.4px solid transparent",
                backgroundClip: "padding-box",
              }}
            >
              <Grid
                container
                item
                xs
                sx={{
                  backgroundColor: "white",
                  borderRadius: "11.6px",
                }}
                direction="column"
              >
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  width="100%"
                  height="13px"
                >
                  <Grid
                    container
                    item
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "26px",
                      width: "112px",
                      borderRadius: "4px",
                      marginTop: "-13px",
                      marginRight: "16px",
                      backgroundImage:
                        "linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)",
                    }}
                  >
                    <Typography
                      sx={{
                        color: getCtaColor,
                        fontSize: "12px",
                        fontWeight: 600,
                        lineHeight: "18px",
                      }}
                    >
                      RECOMMENDED
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  width="100%"
                  direction="column"
                  padding="20px"
                  paddingTop="0px"
                >
                  <SubscriptionPackage
                    type="Seeker"
                    cost={8.88}
                    highlightFirstFeature
                    features={[
                      "Everything in Explorer, plus:",
                      "Thought & emotions analysis",
                      "Personalized AI reminders",
                      "Advanced Sage journey analytics",
                      "Reflection prompts",
                      "Monthly insights report",
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TitleGrid>
    </Grid>
  );
}

function SubscriptionPackage(props: {
  type: string;
  cost: number;
  features: string[];
  highlightFirstFeature?: boolean;
}) {
  return (
    <>
      <FieldText text={props.type} />
      <Grid container item alignItems="flex-end">
        <Typography
          sx={{
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "40px",
            letterSpacing: "-3%",
            color: "#0A090B",
          }}
        >
          ${props.cost}{" "}
        </Typography>

        <Typography
          sx={{
            marginLeft: "4px",
            fontSize: "14px",
            fontWeight: 450,
            lineHeight: "20px",
            letterSpacing: "-0.05px",
            color: "#7F7D83",
          }}
        >
          /month{" "}
        </Typography>
      </Grid>
      <Typography
        marginTop="42px"
        sx={{
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#0A090B",
        }}
      >
        Includes:
      </Typography>
      {props.features.map((feature, idx) => (
        <CheckText
          text={feature}
          bold={props.highlightFirstFeature && idx === 0 ? true : false}
        />
      ))}
    </>
  );
}

function CheckText(props: { text: string; bold: boolean }) {
  return (
    <Grid container item marginTop="10px">
      <img src={checkIcon} style={{ marginRight: "8px" }} />
      <HelperText text={props.text} darkest={props.bold} />
    </Grid>
  );
}

function Account() {
  const name = useSelector((state: RootState) => state.user.firstName);
  return (
    <Grid container direction="column">
      <Grid
        container
        item
        xs
        direction="column"
        sx={{
          border: "1px solid #F1F1F1",
          borderRadius: "16px",
          backgroundColor: "white",
        }}
      >
        <TitleText text="Login Details" />
        <Divider />
        <Grid container item xs padding="20px" direction="column">
          <FieldText text="Email" marginBottom="4px" />
          <Grid container item xs justifyContent="space-between">
            <TextField
              InputProps={{
                style: {
                  height: "40px", // Set the desired height here
                  fontWeight: 450,
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "-0.05px",
                  color: "#7F7D83",
                },
              }}
              InputLabelProps={{
                shrink: true,
                // style: {
                // 	display: 'flex',
                // 	justifyContent: 'center',
                // 	alignItems: 'center',
                // 	paddingBottom: '24px',
                // 	fontSize: '14px',
                // 	lineHeight: '20px',
                // 	color: '#7F7D83',
                // 	fontWeight: 400,
                // 	letterSpacing: '-0.05px'
                // }
              }}
              sx={{ width: "280px", height: "40px" }}
              label={`${name}@gmail.com`}
            />
            <UpdateButton text="Update email" />
          </Grid>

          <Grid
            container
            item
            xs
            justifyContent="space-around"
            paddingTop="24px"
          >
            <Grid container item xs direction="column">
              <FieldText text="Passcode" marginBottom="4px" />
              <HelperText text="A 4-digit or 6-digit passcode used to log into your account." />
            </Grid>
            <UpdateButton text="Update passcode" width="145px" />
          </Grid>
        </Grid>
      </Grid>

      <DeleteAccount deactivate />
      <DeleteAccount />
    </Grid>
  );
}

function TitleText(props: {
  text: string;
  paddingBottom?: string;
  paddingLeft?: string;
}) {
  return (
    <Typography
      sx={{
        padding: "20px",
        paddingBottom: props.paddingBottom,
        paddingLeft: props.paddingLeft,
        fontSize: "20px",
        lineHeight: "28px",
        fontWeight: 500,
        color: "#0A090B",
        letterSpacing: "-2%",
      }}
    >
      {props.text}
    </Typography>
  );
}

function DeleteAccount(props: { deactivate?: boolean }) {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(setAuthorized(false));
    dispatch(setAuthenticated(false));
    navigate("/space/login");
  };
  return (
    <TitleGrid
      marginTop
      title={`${props.deactivate ? "Deactivate" : "Delete"} Account`}
    >
      <Grid container item xs direction="column" padding="20px">
        <FieldText
          text={
            props.deactivate
              ? "This will suspend your account until you sign back in."
              : "Permanently deleting your account will remove all of your activities, and you will no longer be able to sign in with this account."
          }
          marginBottom="32px"
        />
        <Grid container item xs justifyContent="space-between">
          <DeleteButton
            deactivate={props.deactivate}
            onClick={() => setOpenModal(!openModal)}
          />
        </Grid>
        {openModal && (
          <StyledModal
            onClose={() => setOpenModal(false)}
            width="400px"
            height="162px"
          >
            <Grid container item xs direction="column">
              <Grid
                container
                item
                justifyContent="flex-end"
                height="8px"
                onClick={() => setOpenModal(!openModal)}
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <Close
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "#ADACB0",
                  }}
                />
              </Grid>
              <Grid container item alignItems="center">
                <Error sx={{ color: "#F53535" }} />
                <Typography
                  sx={{
                    marginLeft: "4px",
                    color: "#F53535",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "22px",
                    letterSpacing: "-0.18px",
                  }}
                >
                  Deactivate account
                </Typography>
              </Grid>
              <HelperText
                darker
                text="Are you sure you want to deactivate your account?"
                marginTop="8px"
              />
              <Grid
                container
                item
                marginTop="24px"
                marginBottom="24px"
                justifyContent="center"
              >
                <UpdateButton
                  text="Cancel"
                  marginRight="12px"
                  onClick={() => setOpenModal(!openModal)}
                />
                <DeleteButton deactivate onClick={onLogout} />
                {/* <Button>Cancel</Button><Button>Deactivate account</Button> */}
              </Grid>
            </Grid>
          </StyledModal>
        )}
      </Grid>
    </TitleGrid>
  );
}

function DeleteButton(props: { deactivate?: boolean; onClick: () => void }) {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        textTransform: "none",
        backgroundColor: "#F75656",
        height: "40px",
        width: props.deactivate ? "160px" : "140px",
        "&:hover": {
          backgroundColor: "#F75656",
        },
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: 450,
          color: "white",
          letterSpacing: "-.05px",
        }}
      >
        {props.deactivate ? "Deactivate" : "Delete"} account{" "}
      </Typography>
    </Button>
  );
}

function UpdateButton(props: {
  text: string;
  width?: string;
  marginRight?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      // onClick={}
      onClick={() => (props.onClick ? props.onClick() : undefined)}
      sx={{
        borderRadius: "8px",
        backgroundColor: "#F8F8F8",
        width: props.width || "120px",
        height: "40px",
        textTransform: "none",
        marginRight: props.marginRight,
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: 450,
          color: "#4F4D55",
          letterSpacing: "-.05px",
        }}
      >
        {" "}
        {props.text}
      </Typography>
    </Button>
  );
}

export function BasicTabs(props: {
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { selectedTab, setSelectedTab } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const TabButton = (props: { title: string; tabIndex: number }) => (
    <Button
      onClick={() => setSelectedTab(props.tabIndex)}
      sx={{
        textTransform: "none",
        fontFamily: "arial",
        fontWeight: selectedTab === props.tabIndex ? 600 : 450,
        fontSize: "16px",
        lineHeight: "22px",
        color: selectedTab === props.tabIndex ? getCtaColor() : "#7F7D83",
        "&:hover": {
          backgroundColor: "#FAFBFC",
        },
        borderRadius: 0,
        marginLeft: props.tabIndex > 0 && "8px",
        borderBottom:
          selectedTab === props.tabIndex
            ? `3px solid ${getCtaColor()}`
            : "none",
      }}
      disableRipple
    >
      {props.title}
    </Button>
  );
  return (
    <Grid
      container
      item
      xs
      alignItems="center"
      sx={{
        // marginTop: '64px',
        marginBottom: "24px",
        borderBottom: "1px solid #E6E6E6",
      }}
      justifyContent="flex-start"
    >
      <TabButton title="Profile" tabIndex={0} />
      <TabButton title="Account" tabIndex={1} />
      <TabButton title="Subscriptions" tabIndex={2} />
    </Grid>
  );
}
export default AccountSettingsPage;
