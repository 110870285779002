import { Grid, Modal } from '@mui/material';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

export default function StyledModal(props: { children: ReactNode, onClose?: (() => void), width?: string, height?: string, backgroundColor?: string }) {
	const navigate = useNavigate();
	return (
		<Modal
			open={true}
			onClose={() => props.onClose ? props.onClose() : navigate(-1)}
			sx={{ backdropFilter: 'blur(5px)', zIndex: 3000 }}
		>
			<Grid
				container
				item
				xs
				justifyContent="center"
				alignItems="center"
				marginRight={3}
				id="bodyGrid"
				sx={{
					padding: '24px',
					width: props.width || '80%',
					height: props.height || '80%',
					borderRadius: '16px',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor: props.backgroundColor || 'white'
				}}
			// sx={{ backgroundColor: '#A1D0F9' }}
			>
				{props.children}
			</Grid>
		</Modal>
	);
}
