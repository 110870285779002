import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import MainLayout from '../components/templates/MainTemplate';
import Breathe from '../Tools/Breathe/Main';
import PlaygroundModal from '../components/molecules/StyledModal';

const BreathePage: React.FC = () => {
	return (
		<MainLayout page="Breathe">
			<PlaygroundModal backgroundColor="#f6fafd">
				<BreatheBody />
			</PlaygroundModal>
		</MainLayout>
	);
};
export const BreatheBody = () => {
	const [timerSize, setTimerSize] = useState(0);
	const handleGridSize = () => {
		const timerGrid = document.getElementById('bodyGrid'); // Replace 'myGrid' with the actual ID or ref of your Grid component
		if (timerGrid) {
			const height = timerGrid.getBoundingClientRect().height;
			// setTimerSize(height / 0.1);
			setTimerSize(500);
		}
	};
	useEffect(() => {
		handleGridSize();
		// You might want to add an event listener for window resize if the grid size can change dynamically
		window.addEventListener('resize', handleGridSize);

		// Cleanup the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleGridSize);
		};
	}, []); // Empty dependency array ensures that this effect runs once after initial render
	return (
		<Grid
			container
			item
			xs
			justifyContent="center"
			alignItems="center"
			marginRight={3}
		>
			<Breathe size={timerSize} />
		</Grid>
	);
};

export default BreathePage;
