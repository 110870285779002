import { ArcherContainer, ArcherElement } from 'react-archer';
import { Grid, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { AppDispatch } from '../../Util/Redux/Schema';
import { Home } from './Home/HomePage';
import { Insights } from './InsightsPage/InsightsPage';
import MainLayout from '../templates/MainTemplate';
import Vault from './Vault/Vault';
import { headerHeight } from '../../Util/Config';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	useEffect(() => {
		if (sessionStorage.getItem('isPinAuthorized') !== 'True') {
			navigate('/space/gringotts');
		}
	}, [navigate]);
	const [currentSection, setCurrentSection] = useState('Home');
	const containerRef = useRef<React.Ref<Element>>(null);
	const debounce = (func: (...args: any[]) => void, wait: number) => {
		let timeout: NodeJS.Timeout;
		return function (...args: any[]) {
			clearTimeout(timeout);
			timeout = setTimeout(() => func.apply(this, args), wait);
		};
	};
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current) {
				const container = containerRef.current;
				const scrollTop = container.scrollTop;
				const containerHeight = container.clientHeight;
				const scrollHeight = container.scrollHeight;

				// Calculate the percentage scrolled
				const scrollPercentage =
					(scrollTop + containerHeight) / scrollHeight;

				// Add a small buffer to prevent flickering
				const buffer = 0.05; // 5% buffer

				// Adjust the logic for detecting scroll position with buffer thresholds
				if (scrollPercentage < 1 / 3 + buffer) {
					setCurrentSection('Home');
				} else if (scrollPercentage < 2 / 3 + buffer) {
					if (currentSection !== 'Vault') {
						setCurrentSection('Vault');
					}
				} else {
					if (currentSection !== 'Insights') {
						setCurrentSection('Insights');
					}
				}
			}
		};

		// Debounce to avoid rapid firing of the scroll event
		const debouncedHandleScroll = debounce(handleScroll, 1);

		if (containerRef.current) {
			containerRef.current.addEventListener(
				'scroll',
				debouncedHandleScroll
			);
		}

		return () => {
			if (containerRef.current) {
				containerRef.current.removeEventListener(
					'scroll',
					debouncedHandleScroll
				);
			}
		};
	}, [containerRef, setCurrentSection]);

	return (
		<MainLayout page={currentSection}>
			<Grid
				container
				item
				xs
				width="100%"
				ref={containerRef}
				sx={{
					height: `calc(100vh - ${headerHeight})`,
					overflowY: 'scroll',
					scrollSnapType: 'y mandatory'
				}}
			>
				{/* <Grid container item xs justifyContent="center"> */}
				{['Home', 'Vault', 'Insights'].map((section, idx) => {
					return (
						<Grid
							container
							item
							key={idx}
							alignItems="center"
							justifyContent="center"
							sx={{
								height: `calc(100vh - ${headerHeight})`,
								scrollSnapAlign: 'start',
								padding: '24px 0'
							}}
						>
							{currentSection === section &&
								section === 'Home' && <Home />}
							{currentSection === section &&
								section === 'Vault' && <Vault />}
							{currentSection === section &&
								section === 'Insights' && <Insights />}
						</Grid>
					);
				})}
				{/* </Grid> */}
			</Grid>
		</MainLayout>
	);
};

export default Dashboard;
