import { IconButton, Tooltip } from '@mui/material';
import { ReactNode } from 'react';

const TooltipIconButton = (props: {
	title: string;
	icon: ReactNode;
	onClick: () => void;
}) => {
	return (
		<Tooltip title={props.title}>
			<IconButton onClick={props.onClick}>{props.icon}</IconButton>
		</Tooltip>
	);
};

export default TooltipIconButton;
