import * as React from 'react';

import {
	Add,
	CheckCircle,
	Circle,
	CircleOutlined,
	InfoRounded
} from '@mui/icons-material';
import {
	Autocomplete,
	Box,
	Checkbox,
	Chip,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Select,
	TextField,
	TextFieldProps,
	Tooltip,
	Typography
} from '@mui/material';
import {
	EMOTION_DESCRIPTOR,
	getCtaColor,
	getCtaHoverColor
} from '../../../Util/Helper';
import {
	F7Sparkles,
	FluentBotSparkle24Filled,
	FluentEmojiSparkle20Filled,
	PhSparkleFill
} from '../../../assets/svgFiles';
import {
	selectThoughtEmotions,
	setEmotions
} from '../../../Util/Redux/Middleware';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import ChipsTextField from './ChipsTextField';
import { RootState } from '../../../Util/Redux/Schema';
import { StyledMenu } from '../StyledDropdown';
import dropdownSvg from '../../../assets/images/svg-files/dropdown.svg';

// export default function EmotionSelect(props: {
// 	emotions: string[];
// 	options: string[];
// 	setEmotions: (x: string[]) => void;
// }) {
// 	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
// 	const open = Boolean(anchorEl);
// 	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
// 		setAnchorEl(event.currentTarget);
// 	};
// 	const handleClose = (emotions: string[]) => {
// 		setAnchorEl(null);
// 		props.setEmotions(emotions)
// 	};

// 	return (
// 		<div>
// 			<Button
// 				variant="contained"
// 				disableElevation
// 				onClick={handleClick}
// 				endIcon={<img src={dropdownSvg} />}
// 				sx={{
// 					textTransform: 'none',
// 					backgroundColor: 'white',
// 					'&:hover': { backgroundColor: 'white' }
// 				}}
// 			>
// 				<Typography
// 					sx={{
// 						cursor: 'default',
// 						fontFamily: 'arial',
// 						color: props.options.length === 0 ? 'gray' : '#74A899',
// 						fontSize: '12px',
// 						lineHeight: '18px',
// 						fontWeight: 600,
// 						wrap: 'wrap'
// 					}}
// 				>
// 					View suggested emotions
// 				</Typography>
// 			</Button>
// 			<EmotionMenu
// 				anchorEl={anchorEl}
// 				open={open}
// 				onClose={handleClose}
// 				emotions={props.emotions}
// 				options={props.options}
// 				setEmotions={props.setEmotions}
// 			/>
// 		</div>
// 	);
// }

const EMOTIONLIST = [
	'Abandoned',
	'Accepted',
	'Aggressive',
	'Amazed',
	'Anger',
	'Annoyed',
	'Anxiety',
	'Apathetic',
	'Appalled',
	'Aroused',
	'Ashamed',
	'Astonished',
	'Awe',
	'Awful',
	'Bad',
	'Betrayed',
	'Bitter',
	'Bored',
	'Busy',
	'Chaotic',
	'Cheeky',
	'Confident',
	'Confused',
	'Content',
	'Courageous',
	'Creative',
	'Critical',
	'Curious',
	'Depressed',
	'Despair',
	'Detestable',
	'Disappointed',
	'Disapproving',
	'Disgust',
	'Disillusioned',
	'Dismayed',
	'Dismissive',
	'Disrespected',
	'Distant',
	'Eager',
	'Embarrassed',
	'Empty',
	'Energetic',
	'Excited',
	'Excluded',
	'Exposed',
	'Fear',
	'Fragile',
	'Free',
	'Frightened',
	'Frustrated',
	'Furious',
	'Grief',
	'Guilty',
	'Happiness',
	'Helpless',
	'Hesitant',
	'Hopeful',
	'Horrified',
	'Hostile',
	'Humiliated',
	'Hurt',
	'Inadequate',
	'Indifferent',
	'Indignant',
	'Inferior',
	'Infuriated',
	'Inquisitive',
	'Insecure',
	'Insignificant',
	'Inspired',
	'Interested',
	'Intimate',
	'Isolated',
	'Jealous',
	'Joy',
	'Judgemental',
	'Lonely',
	'Loving',
	'Mad',
	'Nauseated',
	'Nervous',
	'Numb',
	'Optimistic',
	'Overwhelm',
	'Overwhelmed',
	'Peaceful',
	'Perplexed',
	'Persecuted',
	'Playful',
	'Powerful',
	'Powerless',
	'Pressured',
	'Proud',
	'Provoked',
	'Rejected',
	'Remorseful',
	'Repelled',
	'Resentful',
	'Respected',
	'Revolted',
	'Ridiculed',
	'Rushed',
	'Sadness',
	'Scared',
	'Sensitive',
	'Shocked',
	'Skeptical',
	'Sleepy',
	'Startled',
	'Stressed',
	'Successful',
	'Surprised',
	'Thankful',
	'Threatened',
	'Tired',
	'Trusting',
	'Unfocused',
	'Valued',
	'Victimized',
	'Violated',
	'Vulnerable',
	'Weak',
	'Withdrawn',
	'Worried',
	'Worthless'
];
export const SageThoughtDropdown = (props: {
	anchorEl: null | HTMLElement;
	open: boolean;
	onClose: (emotions: string[]) => void;
	emotions: string[];
	setEmotions: (emotions: string[]) => void;
	sentiment: string;
	options: string[];
	thoughtUuid: number;
}) => {
	const [userSelectedEmotions, setUserSelectedEmotions] = React.useState(
		props.emotions
	);
	const handleClose = () => {
		props.setEmotions(userSelectedEmotions);
		props.onClose(userSelectedEmotions);
	};
	const onCheck = (checked: boolean, emotion: string) => {
		if (checked) {
			setUserSelectedEmotions([...userSelectedEmotions, emotion]);
		} else {
			setUserSelectedEmotions(
				userSelectedEmotions.filter((e) => e !== emotion)
			);
		}
	};
	if (!props.anchorEl) {
		return <></>;
	}

	return (
		<Grid
			container
			item
			sx={{
				backgroundImage:
					'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)'
			}}
		>
			<Grid container item xs direction="column" paddingBottom="0px">
				<Grid
					container
					item
					xs
					justifyContent="center"
					direction="column"
				>
					<Grid container item xs direction="row" width="100%"></Grid>
					<Grid
						container
						item
						direction="column"
						sx={{
							backgroundColor: 'white',
							borderRadius: '8px'
						}}
					>
						<Grid
							container
							// spacing={1}
							direction="row"
							alignItems="center"
							sx={{
								backgroundColor: '#F8F8F8',
								padding: '8px 16px',
								display: 'flex'
							}}
						>
							<Grid item xs>
								<FluentEmojiSparkle20Filled />
							</Grid>
							<Grid item xs={10}>
								<Typography
									sx={{
										fontSize: '12px',
										fontWeight: 500,
										fontFamily: 'arial'
									}}
								>
									I am sensing...
								</Typography>
							</Grid>
						</Grid>
						<Grid container padding="8px">
							{props.options.map((o) => (
								<ComputedEmotionOption
									option={o}
									onCheck={onCheck}
								/>
							))}
						</Grid>
						<ConfirmButton
							disabled={userSelectedEmotions.length === 0}
							onClick={handleClose}
						/>
					</Grid>

					{/* <Comple */}
				</Grid>
			</Grid>
		</Grid>
	);
};

export const ConfirmButton = (props: {
	disabled: boolean;
	onClick: () => void;
}) => {
	return (
		<Grid container padding="8px">
			<Button
				onClick={props.onClick}
				variant="contained"
				disableElevation
				disabled={props.disabled}
				fullWidth
				disableRipple
				sx={{
					padding: '4px',
					borderRadius: '16px',
					textTransform: 'none',
					backgroundColor: getCtaColor(),
					'&:hover': { backgroundColor: getCtaColor() }
				}}
			>
				<Typography
					sx={{
						// cursor: "default",
						fontFamily: 'arial',
						// color: props.options.length === 0 ? "gray" : "#74A899",
						fontSize: '12px',
						lineHeight: '18px',
						fontWeight: 600,
						wrap: 'wrap'
					}}
				>
					Confirm
				</Typography>
			</Button>
		</Grid>
	);
};

export const ComputedEmotionOption = (props: {
	option: string;
	onCheck: (isChecked: boolean, emotion: string) => void;
}) => {
	const [checked, setChecked] = React.useState(false);
	const onCheck = () => {
		setChecked(!checked);
		props.onCheck(!checked, props.option);
	};
	return (
		<Grid
			container
			item
			alignItems="center"
			justifyContent="center"
			sx={{
				'&:hover': { backgroundColor: '#E5E5E5' },
				// height: '32px',
				borderRadius: '16px',
				cursor: 'pointer'
				// backgroundColor: 'red'
			}}
			onClick={onCheck}
		>
			<Checkbox
				checked={checked}
				sx={{ marginRight: '8px' }}
				disableRipple
				icon={<CircleOutlined sx={{ width: '16px', height: '16px' }} />}
				checkedIcon={
					<CheckCircle
						sx={{
							backgroundImage: '',
							color: getCtaColor(),
							width: '16px',
							height: '16px'
						}}
					/>
				}
			/>
			<Typography sx={{ fontSize: '12px' }}>{props.option}</Typography>

			<Grid
				container
				item
				xs
				justifyContent="flex-end"
				alignItems="center"
				marginRight="8px"
				// sx={{ backgroundColor: 'red' }}
			>
				<Tooltip title={EMOTION_DESCRIPTOR[props.option]}>
					<InfoRounded
						fontSize="small"
						sx={{ color: 'gray', width: '16px' }}
					/>
				</Tooltip>
			</Grid>
		</Grid>
	);
};

export const EmotionMenu = (props: {
	anchorEl: null | HTMLElement;
	open: boolean;
	onClose: (emotions: string[]) => void;
	emotions: string[];
	setEmotions: (emotions: string[]) => void;
	sentiment: string;
	options: string[];
	thoughtUuid: number;
}) => {
	return (
		<StyledMenu
			anchorEl={props.anchorEl}
			open={props.open}
			onClose={() => props.onClose(props.emotions)}
			sx={{
				maxHeight: '200px'
				// width: "400px",
			}}
		>
			<Grid container item xs direction="column" height="100px">
				{props.options.map((emotion, idx) => (
					<Grid
						container
						item
						xs
						// padding="8px"
						key={idx}
						onClick={() =>
							props.setEmotions(
								props.emotions.includes(emotion)
									? props.emotions.filter(
											(x) => x !== emotion
									  )
									: [...props.emotions, emotion]
							)
						}
						alignItems="center"
						sx={{
							'&:hover': {
								backgroundColor: '#F5F5F5'
							},
							cursor: 'pointer'
							// height: "20px",
							// marginBottom: "8px",
						}}
					>
						<Checkbox
							disableRipple
							sx={{
								width: '16px',
								height: '16px',
								marginRight: '12px'
							}}
							icon={
								<CircleOutlined
									style={{
										color: '#7F7D83',
										width: '16px',
										height: '16px'
									}}
								/>
							}
							checkedIcon={
								<CheckCircle
									style={{
										// color: 'white',
										width: '16px',
										height: '16px',
										color: getCtaColor()
									}}
								/>
							}
							checked={props.emotions.includes(emotion)}
						/>

						<Typography
							sx={{
								cursor: 'pointer',
								color: '#7F7D83',
								fontWeight: 450,
								fontSize: '14px',
								lineHeight: '20px',
								letterSpacing: '-0.5px'
							}}
						>
							{emotion}
						</Typography>
					</Grid>
				))}
			</Grid>
		</StyledMenu>
	);
};
