import { Grid } from '@mui/material';
import { SubHeader, TextTypography } from '../../../Util/Components/Typography';
import breathe from '../../../assets/images/breathe.png';

const BenefitsText = (props: { description: string }) => {
	return (
		<Grid container item xs={6} justifyContent="center" alignItems="center">
			<Grid container item xs direction="column" marginBottom={3}>
				<Grid item xs={1}>
					<SubHeader>Benefits</SubHeader>
				</Grid>
				<Grid item xs>
					<TextTypography>{props.description}</TextTypography>
				</Grid>
			</Grid>
		</Grid>
	);
};

const Benefits = (props: { description: string }) => {
	return (
		<>
			<Grid
				container
				item
				xs
				direction="column"
				justifyContent="center"
				alignItems="center"
				paddingLeft="5%"
				paddingRight="5%"
				paddingTop={0}
				paddingBottom={0}
			>
				<BenefitsText description={props.description} />
			</Grid>
			<Grid container item xs justifyContent="center" alignItems="center">
				<img src={breathe} width="30%" alt="relaxing" />
			</Grid>
		</>
	);
};

export default Benefits;
