import { Typography } from '@mui/material';

export default function SectionHeaderTypography(props: { title: string }) {
	return (
		<Typography
			sx={{
				cursor: 'default',
				fontFamily: 'arial',
				fontSize: '16px',
				lineHeight: '24px',
				fontWeight: 550,
				color: '#345247'
				// paddingBottom: '8px'
			}}
		>
			{props.title}
		</Typography>
	);
}
