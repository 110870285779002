import * as React from 'react';

import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { MenuProps } from '@mui/material/Menu';
import dropdownSvg from '../../assets/images/svg-files/dropdown.svg';

export const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={1}
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'right'
		}}
		transformOrigin={{
			vertical: 'bottom',
			horizontal: 'right'
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		// marginTop: theme.spacing(1),
		// minWidth: 180,

		color:
			theme.palette.mode === 'light'
				? 'rgb(55, 65, 81)'
				: theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '0px'
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5)
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				)
			}
		}
	}
}));

export default function StyledDropdown(props: {
	selectedIcon: React.ReactElement;
	options: string[][];
	selected: string;
	setSelected: (x: string) => void;
	menuMargin?: boolean;
	buttonStyle: any;
	buttonTextStyle: any;
}) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				aria-controls={open ? 'demo-customized-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				variant="contained"
				disableElevation
				onClick={handleClick}
				startIcon={props.selectedIcon}
				endIcon={<img src={dropdownSvg} />}
				sx={props.buttonStyle}
			>
				<Typography sx={props.buttonTextStyle}>
					{props.selected}
				</Typography>
			</Button>
			<StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				{props.options.map((emojiText) => (
					<MenuItem onClick={handleClose} disableRipple>
						<Typography
							sx={{
								marginRight: props.menuMargin
									? '16px'
									: undefined
							}}
						>
							{emojiText[0]}
						</Typography>
						<Typography
							sx={{
								fontWeight: 450,
								fontSize: '14px',
								lineHeight: '20px',
								letterSpacing: '-0.05px',
								fontFamily: 'arial',
								color: '#4F4D55'
							}}
						>
							{emojiText[1]}
						</Typography>
					</MenuItem>
				))}
			</StyledMenu>
		</div>
	);
}
