import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import Filters, { FilterRow } from './Filters';
import {
	PhSortAscendingLight,
	PhSortDescendingLight
} from '../../../assets/svgFiles';
import React, { useEffect, useState } from 'react';
import { RootState, Thought } from '../../../Util/Redux/Schema';
import { getMonth, getSentimentColor } from '../../../Util/Helper';
import {
	getThoughtEntries,
	postSearch
} from '../../../Util/Redux/AsyncMiddleware';

import Entries from '../../organisms/Thoughts/ThoughtEntries';
import Search from './Search';
import { useSelector } from 'react-redux';

export const Vault: React.FC = () => {
	const [descending, setDescending] = useState(false);
	const [entriesToshow, setEntriesToShow] = useState<Thought[]>([]);
	const [monthView, setMonthView] = useState(getMonth());
	const [sentimentFilterIndex, setSentimentFilterIndex] = useState(0);
	const [allEntriesByMonth, setAllEntriesByMonth] = useState(
		useSelector((state: RootState) => state.thoughts.monthlyThoughts)
	);
	const onReverseSortOrder = () => {
		setEntriesToShow([...entriesToshow].reverse());
		setDescending(!descending);
	};
	const onChangeSentimentView = () => {
		updateFilterBySentiment(
			(sentimentFilterIndex + 1) % 4,
			allEntriesByMonth
		);
	};
	const updateFilterBySentiment = (
		sentimentIndex: number,
		entries: Thought[]
	) => {
		if (sentimentIndex !== sentimentFilterIndex) {
			setSentimentFilterIndex(sentimentIndex);
		}
		var entriesToShow = [];
		if (sentimentIndex === 0) {
			entriesToShow = entries;
		} else if (sentimentIndex === 1) {
			entriesToShow = entries.filter((obj) => {
				return (
					obj.sentiment.toLowerCase() === 'positive' &&
					obj.userSelectedEmotions.length > 0
				);
			});
		} else if (sentimentIndex === 2) {
			entriesToShow = entries.filter((obj) => {
				return (
					obj.sentiment.toLowerCase() === 'negative' &&
					obj.userSelectedEmotions.length > 0
				);
			});
		} else {
			entriesToShow = entries.filter((obj) => {
				return obj.userSelectedEmotions.length === 0;
			});
		}
		setEntries(entriesToShow);
	};
	const setEntries = (thoughts: Thought[]) => {
		descending
			? setEntriesToShow(thoughts.reverse())
			: setEntriesToShow(thoughts);
	};
	const fetchData = async (month: string) => {
		const entries = await getThoughtEntries(month);
		console.log('Entries', entries);
		if (entries === undefined) {
			setAllEntriesByMonth([]);
		} else {
			setAllEntriesByMonth(entries);
			updateFilterBySentiment(sentimentFilterIndex, entries);
		}
	};
	useEffect(() => {
		if (allEntriesByMonth.length === 0) {
			fetchData(monthView);
		} else {
			setEntries(allEntriesByMonth);
		}
	}, []);
	const onChangeMonthView = (month: string) => {
		setMonthView(month);
		fetchData(month);
	};

	const onEnterSearch = async (query: string) => {
		const searchedThoughts = await postSearch(query);
		setEntriesToShow(searchedThoughts);
	};
	const SearchBar = () => (
		<FilterRow>
			<Grid container item xs sx={{ alignItems: 'center' }}>
				<Search onEnterSearch={onEnterSearch} />
			</Grid>
		</FilterRow>
	);
	const Filter = (
		<Filters
			onEnterSearch={onEnterSearch}
			onReverseSortOrder={onReverseSortOrder}
			onChangeSentimentView={onChangeSentimentView}
			onChangeMonthView={onChangeMonthView}
			sentimentFilterIndex={sentimentFilterIndex}
			descending={descending}
			monthView={monthView}
		/>
	);

	return (
		<Grid
			container
			item
			width="1024px"
			height="768px"
			maxHeight="100%"
			direction="column"
			justifyContent="center"
		>
			<Grid
				container
				item
				xs
				marginTop="16px"
				sx={{
					width: '100%',
					padding: '2px',
					borderRadius: '16px',
					backgroundImage:
						'linear-gradient(145deg, #FDDFA5, #FFA6A5)',
					border: '2.4px solid transparent',
					backgroundClip: 'padding-box'
				}}
				direction="column"
			>
				<Grid
					container
					item
					xs
					sx={{ backgroundColor: 'white', borderRadius: '12px' }}
					direction="column"
				>
					<Grid container item>
						<SearchBar />
					</Grid>

					<Divider />
					<Entries
						filters={Filter}
						thoughts={entriesToshow}
						mutable={false}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Vault;
