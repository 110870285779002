import { Grid, IconButton } from "@mui/material";

import { CheckinEditView } from "./CheckinEditView";
import NotebookLayout from "../../../../templates/NotebookTemplate";
import insights from "../../../../../assets/images/svg-files/insights.svg";

export default function Checkin() {
  const DefaultHeaderIcons = (
    <Grid container item xs justifyContent="flex-end" alignItems="center">
      <IconButton>
        <img src={insights} />
      </IconButton>
    </Grid>
  );
  return (
    // <NotebookLayout title="Be Grateful">
    <CheckinEditView />
    // </NotebookLayout>
  );
}
