import { Grid, Tooltip, Typography, Button } from '@mui/material';
import MainLayout from '../../templates/MainTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../Util/Redux/Schema';
import { useNavigate } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Info } from '@mui/icons-material';
import React, { useState } from 'react';
import { getCtaColor, getMondayToSunday } from '../../../Util/Helper';
import { StyledSwitch } from '../../molecules/StyledSwitch';
import calendarIcon from '../../../assets/images/svg-files/calendar.svg';
import cloudSparkleIcon from '../../../assets/images/svg-files/cloudSparkle.svg';
import starSparkleIcon from '../../../assets/images/svg-files/starSparkle.svg';
import breatheSparkleIcon from '../../../assets/images/svg-files/yogaSparkle.svg';
import WellbeingTab from './WellbeingTab/WellbeingTab';
import ThoughtsTab from './ThoughtsTab/ThoughtsTab';
import SageJourneyInsights from './JourneyTab';
import {
	getAllTimeStats,
	getMonthlyStats,
	getWeeklyStats
} from '../../../Util/Redux/AsyncMiddleware';
import SagedThoughtsTab from './SagedThoughtsTab/SagedThoughtsTab';
import WeeklySummary from './WeeklySummary/WeeklySummary';

const InsightsPage = () => {
	const dispatch = useDispatch<AppDispatch>();
	dispatch(getWeeklyStats());
	dispatch(getMonthlyStats());
	dispatch(getAllTimeStats());
	return (
		<MainLayout page="Insights">
			<Insights />
		</MainLayout>
	);
};

function InsightsCardSmall(props: {
	score: string;
	scoreTitle: string;
	percentageOverLastMonth: number;
}) {
	const Header = () => (
		<>
			<Typography
				sx={{
					fontFamily: 'arial',

					fontSize: '20px',
					lineHeight: '28px',
					fontWeight: 500,
					color: '#2D2B32'
				}}
			>
				{props.scoreTitle}
			</Typography>
			<Tooltip title="Info" style={{ marginLeft: '8px' }}>
				<Info
					sx={{
						color: '#aeb2bc',
						width: '20px',
						height: '20px'
					}}
				/>
			</Tooltip>
		</>
	);

	const Score = () => (
		<>
			<Typography
				textAlign="center"
				justifyContent="center"
				noWrap
				sx={{
					fontWeight: 600,
					fontSize: '14px',
					lineHeight: '20px',
					fontFamily: 'arial',
					spacing: '5px',
					color: '#74A899'
				}}
			>
				{props.score}
			</Typography>
		</>
	);

	const ScoreChange = () => (
		<>
			{props.percentageOverLastMonth > 0 ? (
				<ArrowDownwardIcon
					width="12px"
					height="12px"
					sx={{ color: '#ed6a5e' }}
				/>
			) : (
				<ArrowUpwardIcon
					width="12px"
					height="12px"
					sx={{ color: '#61C554' }}
				/>
			)}
			<Typography
				sx={{
					paddingLeft: '2px',
					fontWeight: 400,
					fontSize: '14px',
					lineHeight: '20px',
					fontFamily: 'arial',
					color:
						props.percentageOverLastMonth > 0
							? '#ed6a5e'
							: '#61C554',
					paddingRight: '8px'
				}}
			>
				{Math.abs(props.percentageOverLastMonth)}%
			</Typography>
			<Typography
				sx={{
					paddingLeft: '2px',
					fontWeight: 400,
					fontSize: '14px',
					lineHeight: '20px',
					fontFamily: 'arial',
					color: '#ADACB0'
				}}
			>
				vs last month
			</Typography>
		</>
	);
	return (
		<Grid container item width="400px" height="196px">
			<Grid
				container
				item
				xs
				padding="24px"
				paddingRight="0px"
				paddingBottom="0px"
				sx={{
					borderRadius: '8px',
					backgroundColor: 'white',
					border: '1px solid #F1F1F1',
					boxShadow: '0px 0px 2px 2px #0A090B05' // Apply shadow effect
				}}
			>
				<Grid container item xs direction="column" paddingBottom="24px">
					<Grid
						container
						item
						xs
						alignItems="center"
						sx={{ width: '240px' }}
						marginBottom="24px"
					>
						<Header />
					</Grid>
					<Grid
						container
						item
						// xs
						width="180px"
						alignItems="center"
					// paddingRight="-24px"
					>
						<Score />
					</Grid>
					<Grid
						container
						item
						xs
						alignItems="center"
						marginTop="16px"
					>
						<ScoreChange />
					</Grid>
				</Grid>
				<Grid
					container
					item
					xs
					// alignItems="flex-end"
					justifyContent="flex-end"
					sx={{
						marginBottom: '-24px',
						// marginLeft: '-8px',
						marginRight: '8px'
					}}
				>
					<img
						src={
							props.scoreTitle === 'Gold Star Days'
								? starSparkleIcon
								: props.scoreTitle === 'Saged Thoughts'
									? breatheSparkleIcon
									: cloudSparkleIcon
						}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}

function MonthlyOverview() {
	return (
		<Grid container item xs={3} marginTop="40px" direction="column">
			{/* <Grid container item xs marginBottom='24px'>
				<Typography sx={{fontSize: '24px', fontWeight: 600, lineHeight: '28px', color: '#0A090B'}}>
					Monthly Overview
				</Typography>
			</Grid> */}

			<Grid
				container
				item
				// height="176px"
				// spacing={4}
				justifyContent="space-around"
			>
				<InsightsCardSmall
					score="38 saged / 50 negative thoughts"
					scoreTitle="Saged Thoughts"
					percentageOverLastMonth={40}
				/>
				<InsightsCardSmall
					score="38"
					scoreTitle="Gold Stars Collected"
					percentageOverLastMonth={-20}
				/>
				<InsightsCardSmall
					score="30 joyful thoughts / 50 total thoughts"
					scoreTitle="Positivity Ratio"
					percentageOverLastMonth={15}
				/>
			</Grid>
			{/* </Grid> */}
		</Grid>
	);
}
export const Insights = () => {
	const monday = getMondayToSunday();
	return (
		<Grid
			container
			item
			// marginTop="-32px"
			// sx={{ backgroundColor: 'blue' }}
			width="1024px"
			height="768px"
			maxHeight="100%"
			// sm={12}
			// md={8}
			// lg={8}
			// xl={8}
			// height="90%"
			direction="column"
			justifyContent="center"
			alignItems="center"
		// paddingBottom="24px"
		>
			{/* <Header /> */}
			{/* <ScoreHeader /> */}
			<Grid container item width="100%" alignItems="center">
				<Grid
					container
					item
					xs
					justifyContent="flex-end"
					alignItems="center"
				>
					<Typography
						sx={{
							textAlign: 'center',
							paddingRight: '8px',
							// paddingTop: '8px',
							color: '#7F7D83',
							fontSize: '14px',
							lineHeight: '20px',
							fontWeight: 450,
							cursor: 'default',
							fontFamily: 'arial'
						}}
					>
						{monday}
					</Typography>
					<img
						src={calendarIcon}
						style={{ marginRight: '8px', marginLeft: '4px' }}
					/>
				</Grid>
			</Grid>
			<Grid container item xs>
				<WeeklySummary />
			</Grid>
			{/* <Grid
				container
				item
				xs
				direction="column"
				height="100%"
				alignItems="center"
				marginTop="16px"
				// justifyContent="center"
				// sx={{ backgroundColor: 'red' }}
			>
				
				{selectedTab === 0 && <ThoughtsTab view={view} />}
				{selectedTab === 1 && <SagedThoughtsTab view={'All Time'} />}
				{selectedTab === 1 && <WellbeingTab view={view} />}
			</Grid> */}
		</Grid>
	);
};

function BasicTabs(props: {
	selectedTab: number;
	setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}) {
	const { selectedTab, setSelectedTab } = props;
	const TabButton = (props: { title: string; tabIndex: number }) => (
		<Button
			onClick={() => setSelectedTab(props.tabIndex)}
			sx={{
				textTransform: 'none',
				fontFamily: 'arial',
				fontWeight: selectedTab === props.tabIndex ? 600 : 450,
				fontSize: '16px',
				lineHeight: '22px',
				color:
					selectedTab === props.tabIndex ? getCtaColor() : '#7F7D83',
				'&:hover': {
					backgroundColor: 'transparent'
				},
				borderRadius: '0px',
				borderBottom:
					selectedTab === props.tabIndex
						? `3px solid ${getCtaColor()}`
						: 'none'
			}}
			disableRipple
		>
			{props.title}
		</Button>
	);
	return (
		<Grid
			container
			item
			// xs
			alignItems="center"
			sx={{
				// backgroundColor: 'red',
				marginTop: '24px',
				// marginBottom: '24px',
				borderBottom: '1px solid #E6E6E6'
			}}
			justifyContent="flex-start"
		>
			<TabButton title="Thoughts" tabIndex={0} />
			{/* <TabButton title="Saged Thoughts" tabIndex={1} /> */}
			{/* <TabButton title="Wellbeing" tabIndex={1} /> */}
		</Grid>
	);
}
export default InsightsPage;
