import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Cloud, CloudRounded, TimelineRounded } from '@mui/icons-material';
import {
	GradientCloudIcon,
	PhSortAscendingLight,
	PhSortDescendingLight
} from '../../../assets/svgFiles';
import { getCtaColor, getSentimentColor } from '../../../Util/Helper';

import MonthDropdown from '../../organisms/MonthDropdown';
import Search from './Search';

const Filters = ({
	onEnterSearch,
	sentimentFilterIndex,
	onChangeSentimentView,
	descending,
	onReverseSortOrder,
	monthView,
	onChangeMonthView
}: {
	onEnterSearch: (query: string) => void;
	sentimentFilterIndex: number;
	onChangeSentimentView: () => void;
	descending: boolean;
	onReverseSortOrder: () => void;
	monthView: string;
	onChangeMonthView: (month: string) => void;
}) => {
	return (
		<>
			{/* <FilterRow>
				<Grid container item xs sx={{ alignItems: 'center' }}>
					<Search onEnterSearch={onEnterSearch} />
				</Grid>
			</FilterRow> */}

			<FilterRow>
				<Grid container item xs marginLeft="24px" alignItems="center">
					{/* <Tooltip
						title={
							sentimentFilterIndex === 0
								? 'all thoughts'
								: sentimentFilterIndex === 1
								  ? 'joyful thoughts'
								  : sentimentFilterIndex === 2
								    ? 'harsh thoughts'
								    : 'unclassified thoughts'
						}
					>
						<Grid
							container
							item
							width="36px"
							height="36px"
							alignItems="center"
							onClick={onChangeSentimentView}
							style={{
								borderRadius: '50%',
								marginLeft: '2px',
								marginRight: '10px',
								marginTop: '-8px',
								cursor: 'pointer',

								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<IconButton
								style={{
									width: '42px',
									height: '42px'
								}}
							>
								{sentimentFilterIndex === 0 ? (
									// <TimelineRounded />
									<GradientCloudIcon />
								) : (
									<Cloud
										style={{
											color:
												sentimentFilterIndex === 0
													? '#F3F2F3'
													: sentimentFilterIndex === 1
													  ? getSentimentColor(
																'positive'
													    ).color
													  : sentimentFilterIndex ===
													      2
													    ? getSentimentColor(
																	'negative'
													      ).color
													    : '#E0E0E0'
										}}
									/>
								)}
							</IconButton>
						</Grid>
					</Tooltip> */}

					<Grid
						container
						item
						alignItems="center"
						// width="36px"
						// height="36px"
						// alignItems="center"
						onClick={onChangeSentimentView}
						style={{
							borderRadius: '50%',
							marginLeft: '2px',
							marginRight: '10px',
							marginTop: '-8px',
							cursor: 'pointer',

							// justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<IconButton
							style={{
								width: '42px',
								height: '42px'
							}}
						>
							{sentimentFilterIndex === 0 ? (
								// <TimelineRounded />
								<GradientCloudIcon />
							) : (
								<CloudRounded
									style={{
										color:
											sentimentFilterIndex === 0
												? '#F3F2F3'
												: sentimentFilterIndex === 1
												  ? getSentimentColor(
															'positive'
												    ).color
												  : sentimentFilterIndex === 2
												    ? getSentimentColor(
																'negative'
												      ).color
												    : '#E0E0E0'
									}}
								/>
							)}
						</IconButton>
						{/* <Typography
							sx={{
								color: 'gray',
								fontSize: '12px',
								fontFamily: 'arial',
								marginLeft: '8px'
							}}
						>
							{sentimentFilterIndex === 0
								? 'All View'
								: 'Newest to Oldest'}
						</Typography> */}
						<MonthDropdown
							selected={monthView}
							onUpdateMonth={onChangeMonthView}
							onEnterSearch={onEnterSearch}
							onReverseSortOrder={onReverseSortOrder}
							onChangeSentimentView={onChangeSentimentView}
							onChangeMonthView={onChangeMonthView}
							sentimentFilterIndex={sentimentFilterIndex}
							descending={descending}
							monthView={monthView}
						/>
						<Grid
							container
							item
							xs
							// justifyContent="flex-end"
							alignItems="center"
							sx={{ height: '36px' }}
						>
							<Tooltip
								title={
									descending
										? 'Newest to Oldest'
										: 'Oldest to Newest'
								}
							>
								<Button
									sx={{
										// width: '24px',
										// height: '24px',
										borderRadius: '36px',
										textTransform: 'none',

										// backgroundColor: 'lightgray',
										// getCtaColor(),
										color: 'white',
										'&:hover': {
											backgroundColor: getCtaColor(0.1)
										}
										// padding: '4px'
									}}
									onClick={onReverseSortOrder}
								>
									<TimelineRounded
										sx={{
											width: '16px',
											height: '16px',
											color: getCtaColor()
										}}
									/>
									{/* {descending ? (
									<PhSortAscendingLight
										style={{ color: 'white' }}
									/>
								) : (
									<PhSortDescendingLight
										style={{ color: 'white' }}
									/>
								)} */}
									<Typography
										sx={{
											color: 'gray',
											fontSize: '12px',
											fontFamily: 'arial',
											marginLeft: '8px'
										}}
									>
										{/* Sort by:{' '} */}
										{descending
											? 'Oldest to Newest'
											: 'Newest to Oldest'}
									</Typography>
								</Button>
							</Tooltip>
						</Grid>
					</Grid>

					{/* <Grid
						container
						item
						xs
						// justifyContent="flex-end"
						alignItems="center"
					>
						<Tooltip
							title={
								descending
									? 'Newest to Oldest'
									: 'Oldest to Newest'
							}
						>
							<IconButton
								sx={{ width: '36px', height: '36px' }}
								onClick={onReverseSortOrder}
							>
								{descending ? (
									<PhSortAscendingLight />
								) : (
									<PhSortDescendingLight />
								)}
							</IconButton>
						</Tooltip>
					</Grid> */}
				</Grid>
			</FilterRow>
		</>
	);
};

export function FilterRow(props: { children: React.ReactElement }) {
	const { children } = props;
	return (
		<Grid container item xs padding="16px">
			{children}
		</Grid>
	);
}

export default Filters;
