import { Cloud, LightMode } from '@mui/icons-material';
import { getCtaColor, getGoldenHourColor } from '../Util/Helper';

import React from 'react';
import type { SVGProps } from 'react';
import { SvgIcon } from '@mui/material';

const HAPPYCOLOR = '#FDDFA5';

export function SolarDumbbellBold(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={32}
			height={32}
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="#D0C1F0"
				d="M22 14v-4c0-.932 0-1.398-.152-1.765a2 2 0 0 0-1.083-1.083C20.398 7 19.932 7 19 7c-.932 0-1.398 0-1.765.152a2 2 0 0 0-1.083 1.083C16 8.602 16 9.568 16 10.5H8c0-.932 0-1.898-.152-2.265a2 2 0 0 0-1.083-1.083C6.398 7 5.932 7 5 7c-.932 0-1.398 0-1.765.152a2 2 0 0 0-1.083 1.083C2 8.602 2 9.068 2 10v4c0 .932 0 1.398.152 1.765a2 2 0 0 0 1.083 1.083C3.602 17 4.068 17 5 17c.932 0 1.398 0 1.765-.152a2 2 0 0 0 1.083-1.083C8 15.398 8 14.432 8 13.5h8c0 .932 0 1.898.152 2.265a2 2 0 0 0 1.083 1.083C17.602 17 18.068 17 19 17c.932 0 1.398 0 1.765-.152a2 2 0 0 0 1.083-1.083C22 15.398 22 14.932 22 14"
			></path>
		</svg>
	);
}

export function MaterialSymbolsDirectionsRunRounded(
	props: SVGProps<SVGSVGElement>
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={32}
			height={32}
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="#D0C1F0"
				d="M13 22v-5l-2.1-2l-.775 3.45q-.1.4-.437.613t-.738.137L4 18.2q-.425-.075-.65-.425T3.2 17t.425-.662t.775-.138l3.8.8l1.6-8.1l-1.8.7V12q0 .425-.288.713T7 13t-.712-.288T6 12V8.95q0-.3.163-.537T6.6 8.05L9.95 6.6q.875-.375 1.288-.487T12 6q.525 0 .975.275T13.7 7l1 1.6q.525.85 1.363 1.475T18 10.9q.425.075.713.375T19 12t-.288.7t-.687.225q-1.35-.2-2.525-.838t-2-1.587l-.6 3l1.8 1.7q.15.15.225.338t.075.387V22q0 .425-.288.713T14 23t-.712-.288T13 22m.5-16.5q-.825 0-1.412-.587T11.5 3.5t.588-1.412T13.5 1.5t1.413.588T15.5 3.5t-.587 1.413T13.5 5.5"
			></path>
		</svg>
	);
}

export function PhStarFill(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 256 256"
			{...props}
		>
			<path
				fill={getGoldenHourColor('Mind').color}
				d="m234.29 114.85l-45 38.83L203 211.75a16.4 16.4 0 0 1-24.5 17.82L128 198.49l-50.53 31.08A16.4 16.4 0 0 1 53 211.75l13.76-58.07l-45-38.83A16.46 16.46 0 0 1 31.08 86l59-4.76l22.76-55.08a16.36 16.36 0 0 1 30.27 0l22.75 55.08l59 4.76a16.46 16.46 0 0 1 9.37 28.86Z"
			></path>
		</svg>
	);
}

export function MaterialSymbolsAirRounded(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="#D0C1F0"
				d="M11.5 20q-.8 0-1.487-.4T8.9 18.5q-.275-.5-.012-1T9.7 17q.325 0 .575.2t.45.45q.125.175.338.263T11.5 18q.425 0 .713-.288T12.5 17t-.288-.712T11.5 16H3q-.425 0-.712-.288T2 15t.288-.712T3 14h8.5q1.25 0 2.125.875T14.5 17t-.875 2.125T11.5 20M3 10q-.425 0-.712-.288T2 9t.288-.712T3 8h12.5q.625 0 1.063-.437T17 6.5t-.437-1.062T15.5 5q-.4 0-.75.188t-.55.537q-.175.3-.425.538t-.6.237q-.5 0-.812-.375t-.163-.8q.35-1.05 1.263-1.687T15.5 3q1.45 0 2.475 1.025T19 6.5t-1.025 2.475T15.5 10zm16.95 7.7q-.5.225-.975-.062T18.5 16.8q0-.35.238-.587t.537-.413q.35-.2.538-.55T20 14.5q0-.625-.437-1.062T18.5 13H3q-.425 0-.712-.288T2 12t.288-.712T3 11h15.5q1.45 0 2.475 1.025T22 14.5q0 1.05-.55 1.913t-1.5 1.287"
			></path>
		</svg>
	);
}

export function LineMdSunnyFilledAnimated(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<g stroke={HAPPYCOLOR} strokeLinecap="round" strokeWidth={2}>
				<path
					fill={HAPPYCOLOR}
					fillOpacity={0}
					strokeDasharray={34}
					strokeDashoffset={34}
					d="M12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7"
				>
					<animate
						fill="freeze"
						attributeName="stroke-dashoffset"
						dur="0.4s"
						values="34;0"
					></animate>
					<animate
						fill="freeze"
						attributeName="fill-opacity"
						begin="0.9s"
						dur="0.5s"
						values="0;1"
					></animate>
				</path>
				<g fill="none" strokeDasharray={2} strokeDashoffset={2}>
					<path d="M0 0">
						<animate
							fill="freeze"
							attributeName="d"
							begin="0.5s"
							dur="0.2s"
							values="M12 19v1M19 12h1M12 5v-1M5 12h-1;M12 21v1M21 12h1M12 3v-1M3 12h-1"
						></animate>
						<animate
							fill="freeze"
							attributeName="stroke-dashoffset"
							begin="0.5s"
							dur="0.2s"
							values="2;0"
						></animate>
					</path>
					<path d="M0 0">
						<animate
							fill="freeze"
							attributeName="d"
							begin="0.7s"
							dur="0.2s"
							values="M17 17l0.5 0.5M17 7l0.5 -0.5M7 7l-0.5 -0.5M7 17l-0.5 0.5;M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5"
						></animate>
						<animate
							fill="freeze"
							attributeName="stroke-dashoffset"
							begin="0.7s"
							dur="0.2s"
							values="2;0"
						></animate>
					</path>
				</g>
			</g>
		</svg>
	);
}

export function LineMdSunnyFilled(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<g stroke={HAPPYCOLOR} strokeLinecap="round" strokeWidth={2}>
				<path
					fill={HAPPYCOLOR}
					d="M12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7"
				></path>
				<g fill="none" strokeDasharray={2} strokeDashoffset={2}>
					<path
						d="M12 21v1M21 12h1M12 3v-1M3 12h-1"
						strokeDashoffset={0}
					></path>
					<path
						d="M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5"
						strokeDashoffset={0}
					></path>
				</g>
			</g>
		</svg>
	);
}

export function LineMdSunnyFilledGreen(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<g stroke={'#a6d5c1'} strokeLinecap="round" strokeWidth={2}>
				<path
					fill={'#a6d5c1'}
					d="M12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7"
				></path>
				<g fill="none" strokeDasharray={2} strokeDashoffset={2}>
					<path
						d="M12 21v1M21 12h1M12 3v-1M3 12h-1"
						strokeDashoffset={0}
					></path>
					<path
						d="M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5"
						strokeDashoffset={0}
					></path>
				</g>
			</g>
		</svg>
	);
}
export function SunSmallFilled(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="0 0 24 24"
			{...props}
		>
			<g stroke={HAPPYCOLOR} strokeLinecap="round" strokeWidth={2}>
				<path
					fill={HAPPYCOLOR}
					d="M12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7"
				></path>
				<g fill="none" strokeDasharray={2} strokeDashoffset={2}>
					<path
						d="M12 21v1M21 12h1M12 3v-1M3 12h-1"
						strokeDashoffset={0}
					></path>
					<path
						d="M18.5 18.5l0.5 0.5M18.5 5.5l0.5 -0.5M5.5 5.5l-0.5 -0.5M5.5 18.5l-0.5 0.5"
						strokeDashoffset={0}
					></path>
				</g>
			</g>
		</svg>
	);
}

// #FDDFA5
// 87CEEB

export function MeteoconsLightningBoltFillAnimated(
	props: SVGProps<SVGSVGElement>
) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={38}
			height={38}
			viewBox="0 0 512 512"
			style={{ marginLeft: '-8px' }}
			{...props}
		>
			<defs>
				<symbol id="meteoconsLightningBolt0" viewBox="0 0 96 176">
					<path
						fill="#FDA7A6"
						d="M32 0L0 96h32l-16 80L96 64H48L80 0H32z"
					>
						<animate
							id="meteoconsLightningBolt1"
							attributeName="opacity"
							begin="0s; x1.end+.67s"
							dur="1.33s"
							keyTimes="0; .38; .5; .63; .75; .86; .94; 1"
							values="1; 1; 0; 1; 0; 1; 0; 1"
						></animate>
					</path>
				</symbol>
			</defs>
			<use
				width={96}
				height={176}
				href="#meteoconsLightningBolt0"
				transform="translate(190.13 132.72)scale(1.36)"
			></use>
		</svg>
	);
}

export function MeteoconsLightningBoltFill(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={38}
			height={38}
			viewBox="0 0 512 512"
			style={{ marginLeft: '-8px' }}
			{...props}
		>
			<defs>
				<linearGradient
					id="meteoconsLightningBoltFill0"
					x1={8.7}
					x2={80.9}
					y1={17.1}
					y2={142.1}
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0} stopColor="#FDA7A6"></stop>
					<stop offset={0.5} stopColor="#FDA7A6"></stop>
					<stop offset={1} stopColor="#FDA7A6"></stop>
				</linearGradient>
				<symbol
					id="meteoconsLightningBoltFill1"
					viewBox="0 0 102.7 186.8"
				>
					<path
						fill="url(#meteoconsLightningBoltFill0)"
						stroke="#FDA7A6"
						strokeMiterlimit={10}
						strokeWidth={4}
						d="m34.8 2l-32 96h32l-16 80l80-112h-48l32-64h-48z"
						opacity={1}
					></path>
				</symbol>
			</defs>
			<use
				width={102.7}
				height={186.7}
				href="#meteoconsLightningBoltFill1"
				transform="translate(186.37 130)scale(1.36)"
			></use>
		</svg>
	);
}

export function LightningSmallFilled(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 380 380"
			style={{ marginLeft: '-8px' }}
			{...props}
		>
			<defs>
				<linearGradient
					id="meteoconsLightningBoltFill0"
					x1={8.7}
					x2={80.9}
					y1={17.1}
					y2={142.1}
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0} stopColor="#FDA7A6"></stop>
					<stop offset={0.5} stopColor="#FDA7A6"></stop>
					<stop offset={1} stopColor="#FDA7A6"></stop>
				</linearGradient>
				<symbol
					id="meteoconsLightningBoltFill1"
					viewBox="0 0 102.7 186.8"
				>
					<path
						fill="url(#meteoconsLightningBoltFill0)"
						stroke="#FDA7A6"
						strokeMiterlimit={10}
						strokeWidth={4}
						d="m34.8 2l-32 96h32l-16 80l80-112h-48l32-64h-48z"
						opacity={1}
					></path>
				</symbol>
			</defs>
			<use
				width={102.7}
				height={186.7}
				href="#meteoconsLightningBoltFill1"
				transform="translate(186.37 130)scale(1.36)"
			></use>
		</svg>
	);
}
export function LineMdPaintDropFilledAnimated(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="-2 -2 24 24"
			{...props}
		>
			<path
				fill={SADCOLOR}
				fillOpacity={0}
				d="M12 4C12 4 18 9 18 15C18 19 15 20 12 20C9 20 6 19 6 15C6 9 12 4 12 4Z"
			>
				<animate
					fill="freeze"
					attributeName="fill-opacity"
					begin="0.4s"
					dur="0.5s"
					values="0;1"
				></animate>
			</path>
			<path
				fill="none"
				stroke={SADCOLOR}
				strokeDasharray={24}
				strokeDashoffset={24}
				strokeLinecap="round"
				strokeWidth={2}
				d="M12 3C12 3 19 9 19 15C19 17 18 21 12 21M12 3C12 3 5 9 5 15C5 17 6 21 12 21"
			>
				<animate
					fill="freeze"
					attributeName="stroke-dashoffset"
					dur="0.4s"
					values="28;0"
				></animate>
			</path>
		</svg>
	);
}

export function LineMdPaintDropFilled(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="-2 -2 24 24"
			{...props}
		>
			<path
				fill={SADCOLOR}
				d="M12 4C12 4 18 9 18 15C18 19 15 20 12 20C9 20 6 19 6 15C6 9 12 4 12 4Z"
			></path>
			<path
				fill="none"
				stroke={SADCOLOR}
				strokeLinecap="round"
				strokeWidth={2}
				d="M12 3C12 3 19 9 19 15C19 17 18 21 12 21M12 3C12 3 5 9 5 15C5 17 6 21 12 21"
			></path>
		</svg>
	);
}

export function MeteoconsRainFill(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={32}
			height={32}
			viewBox="0 0 512 512"
			{...props}
		>
			<defs>
				<linearGradient
					id="meteoconsRainFill0"
					x1={99.5}
					x2={232.6}
					y1={30.7}
					y2={261.4}
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0} stopColor="#F8F8F8"></stop>
					<stop offset={0.5} stopColor="#F8F8F8"></stop>
					<stop offset={1} stopColor="#F8F8F8"></stop>
				</linearGradient>
				<linearGradient
					id="meteoconsRainFill1"
					x1={1381.3}
					x2={1399.5}
					y1={-1144.7}
					y2={-1097.4}
					gradientTransform="rotate(-9 8002.567 8233.063)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset={0} stopColor="#0b65ed"></stop>
					<stop offset={0.5} stopColor="#0a5ad4"></stop>
					<stop offset={1} stopColor="#0950bc"></stop>
				</linearGradient>
				<linearGradient
					id="meteoconsRainFill2"
					x1={1436.7}
					x2={1454.9}
					y1={-1137}
					y2={-1089.7}
					gradientTransform="rotate(-9 8009.537 8233.037)"
					href="#meteoconsRainFill1"
				></linearGradient>
				<linearGradient
					id="meteoconsRainFill3"
					x1={1492.1}
					x2={1510.3}
					y1={-1129.3}
					y2={-1082.1}
					gradientTransform="rotate(-9 8016.566 8233.078)"
					href="#meteoconsRainFill1"
				></linearGradient>
				<symbol id="meteoconsRainFill4" viewBox="0 0 350 222">
					<path
						fill="url(#meteoconsRainFill0)"
						stroke="#F8F8F8"
						strokeMiterlimit={10}
						strokeWidth={12}
						d="m291 107l-2.5.1A83.9 83.9 0 0 0 135.6 43A56 56 0 0 0 51 91a56.6 56.6 0 0 0 .8 9A60 60 0 0 0 63 219l4-.2v.2h224a56 56 0 0 0 0-112Z"
					></path>
				</symbol>
				<symbol id="meteoconsRainFill5" viewBox="0 0 129 57">
					<path
						fill="url(#meteoconsRainFill1)"
						stroke="#0a5ad4"
						strokeMiterlimit={10}
						d="M8.5 56.5a8 8 0 0 1-8-8v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
						opacity={0}
						transform="translate(0 60)"
					></path>
					<path
						fill="url(#meteoconsRainFill2)"
						stroke="#0a5ad4"
						strokeMiterlimit={10}
						d="M64.5 56.5a8 8 0 0 1-8-8v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
						opacity={0}
						transform="translate(0 60)"
					></path>
					<path
						fill="url(#meteoconsRainFill3)"
						stroke="#0a5ad4"
						strokeMiterlimit={10}
						d="M120.5 56.5a8 8 0 0 1-8-8v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
						opacity={0}
						transform="translate(0 60)"
					></path>
				</symbol>
			</defs>
			<use
				width={350}
				height={222}
				href="#meteoconsRainFill4"
				transform="translate(81 145)"
			></use>
			<use
				width={129}
				height={57}
				href="#meteoconsRainFill5"
				transform="translate(191.5 343.5)"
			></use>
		</svg>
	);
}

export function MeteoconsRainFillAnimated(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={32}
			height={32}
			viewBox="0 0 512 512"
			{...props}
		>
			<defs>
				<symbol id="meteoconsRain0" viewBox="0 0 359 231">
					<path
						fill="none"
						stroke="#e2e8f0"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={15}
						d="M295.5 223.5a56 56 0 0 0 0-112l-2.5.1a83.9 83.9 0 0 0-153-64.2a56 56 0 0 0-84.6 48.1a56.6 56.6 0 0 0 .8 9a60 60 0 0 0 11.2 119"
					></path>
				</symbol>
				<symbol id="meteoconsRain1" viewBox="0 0 128 56">
					<path
						fill="#0a5ad4"
						d="M8 56a8 8 0 0 1-8-8V8a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
						opacity={0}
					>
						<animateTransform
							id="meteoconsRain2"
							additive="sum"
							attributeName="transform"
							begin="0s; x1.end+.33s"
							dur=".67s"
							type="translate"
							values="0 -60; 0 60"
						></animateTransform>
						<animate
							id="meteoconsRain3"
							attributeName="opacity"
							begin="0s; y1.end+.33s"
							dur=".67s"
							keyTimes="0; .25; 1"
							values="0; 1; 0"
						></animate>
					</path>
					<path
						fill="#0a5ad4"
						d="M64 56a8 8 0 0 1-8-8V8a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
						opacity={0}
					>
						<animateTransform
							id="meteoconsRain4"
							additive="sum"
							attributeName="transform"
							begin=".33s; x2.end+.33s"
							dur=".67s"
							type="translate"
							values="0 -60; 0 60"
						></animateTransform>
						<animate
							id="meteoconsRain5"
							attributeName="opacity"
							begin=".33s; y2.end+.33s"
							dur=".67s"
							keyTimes="0; .25; 1"
							values="0; 1; 0"
						></animate>
					</path>
					<path
						fill="#0a5ad4"
						d="M120 56a8 8 0 0 1-8-8V8a8 8 0 0 1 16 0v40a8 8 0 0 1-8 8Z"
						opacity={0}
					>
						<animateTransform
							id="meteoconsRain6"
							additive="sum"
							attributeName="transform"
							begin="-.33s; x3.end+.33s"
							dur=".67s"
							type="translate"
							values="0 -60; 0 60"
						></animateTransform>
						<animate
							id="meteoconsRain7"
							attributeName="opacity"
							begin="-.33s; y3.end+.33s"
							dur=".67s"
							keyTimes="0; .25; 1"
							values="0; 1; 0"
						></animate>
					</path>
				</symbol>
			</defs>
			<use
				width={359}
				height={231}
				href="#meteoconsRain0"
				transform="translate(76.5 140.5)"
			></use>
			<use
				width={128}
				height={56}
				href="#meteoconsRain1"
				transform="translate(192 344)"
			></use>
		</svg>
	);
}

export function HappyCloud(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="#FDDFA5"
				d="M9 7L12 5L15 7L21 15L18 19H6L3 15L9 7Z"
			></path>
			<g
				fill="none"
				stroke="#FDDFA5"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2.4}
			>
				<path d="M12 19C12 19 9.5 19 7 19C4.5 19 3 17 3 15C3 13 4.5 11 7 11C8 11 8.5 11.5 8.5 11.5M12 19H17C19.5 19 21 17 21 15C21 13 19.5 11 17 11C16 11 15.5 11.5 15.5 11.5"></path>
				<path d="M17 11C17 11 17 10.5 17 10C17 7.5 15 5 12 5M7 11V10C7 7.5 9 5 12 5"></path>
			</g>
		</svg>
	);
}

export function HappyCloudAnimated(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="#FDDFA5"
				fillOpacity={0}
				d="M9 7L12 5L15 7L21 15L18 19H6L3 15L9 7Z"
			>
				<animate
					fill="freeze"
					attributeName="fill-opacity"
					begin="0.8s"
					dur="0.5s"
					values="0;1"
				></animate>
			</path>
			<g
				fill="none"
				stroke="#FDDFA5"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2.4}
			>
				<path
					strokeDasharray={20}
					strokeDashoffset={20}
					d="M12 19C12 19 9.5 19 7 19C4.5 19 3 17 3 15C3 13 4.5 11 7 11C8 11 8.5 11.5 8.5 11.5M12 19H17C19.5 19 21 17 21 15C21 13 19.5 11 17 11C16 11 15.5 11.5 15.5 11.5"
				>
					<animate
						fill="freeze"
						attributeName="stroke-dashoffset"
						dur="0.4s"
						values="20;0"
					></animate>
				</path>
				<path
					strokeDasharray={9}
					strokeDashoffset={9}
					d="M17 11C17 11 17 10.5 17 10C17 7.5 15 5 12 5M7 11V10C7 7.5 9 5 12 5"
				>
					<animate
						fill="freeze"
						attributeName="stroke-dashoffset"
						begin="0.5s"
						dur="0.3s"
						values="9;0"
					></animate>
				</path>
			</g>
		</svg>
	);
}

export function SadCloud(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="#FDA7A6"
				d="M9 7L12 5L15 7L21 15L18 19H6L3 15L9 7Z"
			></path>
			<g
				fill="none"
				stroke="#FDA7A6"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2.4}
			>
				<path d="M12 19C12 19 9.5 19 7 19C4.5 19 3 17 3 15C3 13 4.5 11 7 11C8 11 8.5 11.5 8.5 11.5M12 19H17C19.5 19 21 17 21 15C21 13 19.5 11 17 11C16 11 15.5 11.5 15.5 11.5"></path>
				<path d="M17 11C17 11 17 10.5 17 10C17 7.5 15 5 12 5M7 11V10C7 7.5 9 5 12 5"></path>
			</g>
		</svg>
	);
}

export function SadCloudAnimated(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="#FDA7A6"
				fillOpacity={0}
				d="M9 7L12 5L15 7L21 15L18 19H6L3 15L9 7Z"
			>
				<animate
					fill="freeze"
					attributeName="fill-opacity"
					begin="0.8s"
					dur="0.5s"
					values="0;1"
				></animate>
			</path>
			<g
				fill="none"
				stroke="#FDA7A6"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2.4}
			>
				<path
					strokeDasharray={20}
					strokeDashoffset={20}
					d="M12 19C12 19 9.5 19 7 19C4.5 19 3 17 3 15C3 13 4.5 11 7 11C8 11 8.5 11.5 8.5 11.5M12 19H17C19.5 19 21 17 21 15C21 13 19.5 11 17 11C16 11 15.5 11.5 15.5 11.5"
				>
					<animate
						fill="freeze"
						attributeName="stroke-dashoffset"
						dur="0.4s"
						values="20;0"
					></animate>
				</path>
				<path
					strokeDasharray={9}
					strokeDashoffset={9}
					d="M17 11C17 11 17 10.5 17 10C17 7.5 15 5 12 5M7 11V10C7 7.5 9 5 12 5"
				>
					<animate
						fill="freeze"
						attributeName="stroke-dashoffset"
						begin="0.5s"
						dur="0.3s"
						values="9;0"
					></animate>
				</path>
			</g>
		</svg>
	);
}

export function EmptyCloud(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<g
				fill="none"
				stroke="#F8F8F8"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2.4}
			>
				<path
					strokeDasharray={20}
					strokeDashoffset={20}
					d="M12 19C12 19 9.5 19 7 19C4.5 19 3 17 3 15C3 13 4.5 11 7 11C8 11 8.5 11.5 8.5 11.5M12 19H17C19.5 19 21 17 21 15C21 13 19.5 11 17 11C16 11 15.5 11.5 15.5 11.5"
				>
					<animate
						fill="freeze"
						attributeName="stroke-dashoffset"
						dur="0.4s"
						values="20;0"
					></animate>
				</path>
				<path
					strokeDasharray={9}
					strokeDashoffset={9}
					d="M17 11C17 11 17 10.5 17 10C17 7.5 15 5 12 5M7 11V10C7 7.5 9 5 12 5"
				>
					<animate
						fill="freeze"
						attributeName="stroke-dashoffset"
						begin="0.5s"
						dur="0.3s"
						values="9;0"
					></animate>
				</path>
			</g>
		</svg>
	);
}

export function MingcuteBubbleFill(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={34}
			height={34}
			viewBox="0 0 24 24"
			{...props}
		>
			<g fill="none">
				<path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"></path>
				<path
					fill="#74A899"
					d="M16 15a3 3 0 1 1 0 6a3 3 0 0 1 0-6m-9-4a4 4 0 1 1 0 8a4 4 0 0 1 0-8m9-8a5 5 0 1 1 0 10a5 5 0 0 1 0-10"
				></path>
			</g>
		</svg>
	);
}

export function PhPlantFill(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			{...props}
		>
			<g fill="none">
				<path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"></path>
				<path
					fill={getCtaColor()}
					d="M12 4c-.672 0-1.235.44-1.599.805c-.414.415-.813.978-1.16 1.63c-.448.845-.836 1.89-1.054 3.058a9.4 9.4 0 0 0-1.898-.74c-.493-.129-.973-.2-1.399-.19c-.37.009-.934.082-1.34.487c-.421.422-.513 1.047-.483 1.618c.028.528.163 1.137.385 1.769c.242.69.603 1.445 1.088 2.197a7.3 7.3 0 0 0-1.23.6C2.69 15.62 2 16.204 2 17c0 1.087 1.193 1.766 2.046 2.158C5.074 19.631 6.466 20 8 20c1.55 0 2.96-.376 4-.863c1.04.487 2.45.863 4 .863c1.534 0 2.926-.369 3.954-.842C20.807 18.766 22 18.087 22 17c0-.797-.69-1.38-1.31-1.765a7.3 7.3 0 0 0-1.23-.601c.485-.752.846-1.508 1.088-2.197c.222-.631.357-1.241.385-1.77c.03-.57-.062-1.195-.483-1.617c-.406-.405-.97-.478-1.34-.487c-.426-.01-.906.061-1.399.19a9.4 9.4 0 0 0-1.898.74a10.8 10.8 0 0 0-1.055-3.058c-.346-.652-.745-1.215-1.16-1.63C13.235 4.44 12.673 4 12 4"
				></path>
			</g>
		</svg>
	);
}

export function PhSortAscendingLight(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 256 256"
			{...props}
		>
			<path
				fill="#979797"
				d="M126 128a6 6 0 0 1-6 6H48a6 6 0 0 1 0-12h72a6 6 0 0 1 6 6M48 70h136a6 6 0 0 0 0-12H48a6 6 0 0 0 0 12m56 116H48a6 6 0 0 0 0 12h56a6 6 0 0 0 0-12m124.24-22.24a6 6 0 0 0-8.48 0L190 193.51V112a6 6 0 0 0-12 0v81.51l-29.76-29.75a6 6 0 0 0-8.48 8.48l40 40a6 6 0 0 0 8.48 0l40-40a6 6 0 0 0 0-8.48"
			></path>
		</svg>
	);
}

export function PhSortDescendingLight(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 256 256"
			{...props}
		>
			<path
				fill="#979797"
				d="M42 128a6 6 0 0 1 6-6h72a6 6 0 0 1 0 12H48a6 6 0 0 1-6-6m6-58h56a6 6 0 0 0 0-12H48a6 6 0 0 0 0 12m136 116H48a6 6 0 0 0 0 12h136a6 6 0 0 0 0-12m44.24-102.24l-40-40a6 6 0 0 0-8.48 0l-40 40a6 6 0 0 0 8.48 8.48L178 62.49V144a6 6 0 0 0 12 0V62.49l29.76 29.75a6 6 0 0 0 8.48-8.48"
			></path>
		</svg>
	);
}

export function BxsDownArrow(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={8}
			height={8}
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				fill="#AEB2BC"
				d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569z"
			></path>
		</svg>
	);
}

export function IconamoonCloseBold(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={16}
			height={16}
			viewBox="0 0 24 24"
			style={{ marginLeft: '4px' }}
			{...props}
		>
			<path
				fill="none"
				stroke="#989898"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2.5}
				d="m7 7l10 10M7 17L17 7"
			></path>
		</svg>
	);
}

export function F7Sparkles(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke={getCtaColor()}
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			class="lucide lucide-sparkles"
		>
			<path d="M9.937 15.5A2 2 0 0 0 8.5 14.063l-6.135-1.582a.5.5 0 0 1 0-.962L8.5 9.936A2 2 0 0 0 9.937 8.5l1.582-6.135a.5.5 0 0 1 .963 0L14.063 8.5A2 2 0 0 0 15.5 9.937l6.135 1.581a.5.5 0 0 1 0 .964L15.5 14.063a2 2 0 0 0-1.437 1.437l-1.582 6.135a.5.5 0 0 1-.963 0z" />
			<path d="M20 3v4" />
			<path d="M22 5h-4" />
			<path d="M4 17v2" />
			<path d="M5 18H3" />
		</svg>
	);
}

export const GradientSunIcon = () => {
	return (
		<SvgIcon>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={20}
				height={20}
				viewBox="0 0 20 20"
			>
				<defs>
					<linearGradient
						id="gradient1"
						x1="0%"
						y1="0%"
						x2="0%"
						y2="100%"
					>
						<stop
							offset="0%"
							style={{ stopColor: '#F3F2F3', stopOpacity: 1 }}
						/>
						<stop
							offset="50%"
							style={{ stopColor: '#FDDFA5', stopOpacity: 1 }}
						/>
						<stop
							offset="100%"
							style={{ stopColor: '#FDA7A6', stopOpacity: 1 }}
						/>
					</linearGradient>
				</defs>
				<LightMode style={{ fill: 'url(#gradient1)' }} />
			</svg>
		</SvgIcon>
	);
};

export const GradientCloudIcon = () => {
	return (
		<SvgIcon>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={20}
				height={20}
				viewBox="0 0 20 20"
			>
				<defs>
					<linearGradient
						id="gradient1"
						x1="0%"
						y1="0%"
						x2="0%"
						y2="100%"
					>
						<stop
							offset="0%"
							style={{ stopColor: '#F3F2F3', stopOpacity: 1 }}
						/>
						<stop
							offset="50%"
							style={{ stopColor: '#FDDFA5', stopOpacity: 1 }}
						/>
						<stop
							offset="100%"
							style={{ stopColor: '#FDA7A6', stopOpacity: 1 }}
						/>
					</linearGradient>
				</defs>
				<Cloud style={{ fill: 'url(#gradient1)' }} />
			</svg>
		</SvgIcon>
	);
};

export function FluentBotSparkle24Filled(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 25 25"
			{...props}
		>
			<defs>
				<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
					<stop
						offset="0%"
						style={{ stopColor: '#fddfa5', stopOpacity: 1 }}
					/>
					<stop
						offset="50%"
						style={{ stopColor: '#fda7a6', stopOpacity: 1 }}
					/>
					<stop
						offset="100%"
						style={{ stopColor: '#d0c1f0', stopOpacity: 1 }}
					/>
				</linearGradient>
			</defs>
			<rect
				x="0"
				y="0"
				width="100%"
				height="100%"
				rx="4" // Rounded corners
				ry="4" // Rounded corners
				fill="url(#grad1)" // Use the gradient fill
			/>
			<path
				fill="white"
				d="M18.365 10.283q.069.05.132.105q.003-.066.003-.133V5.75a2.25 2.25 0 0 0-2.25-2.25h-3.5v-.75l-.006-.101A.75.75 0 0 0 12 2l-.102.007a.75.75 0 0 0-.648.743l-.001.75h-3.5A2.25 2.25 0 0 0 5.5 5.75v4.505a2.25 2.25 0 0 0 2.25 2.25h7.784l.02-.053l.008-.026l.45-1.384l.005-.012a1.544 1.544 0 0 1 2.348-.747M9.75 6.5a1.25 1.25 0 1 1 0 2.499a1.25 1.25 0 0 1 0-2.499m4.492 0a1.25 1.25 0 1 1 0 2.499a1.25 1.25 0 0 1 0-2.499m-1.2 7.537l.113-.037H6.254a2.25 2.25 0 0 0-2.25 2.25v.907a3.75 3.75 0 0 0 1.305 2.844c1.563 1.343 3.802 2 6.691 2c2.076 0 3.817-.339 5.213-1.028a1.55 1.55 0 0 1-1.169-1.003l-.004-.012l-.45-1.385v-.001a1.84 1.84 0 0 0-.444-.72l-.359-.262l-.359-.183l-1.385-.45l-.012-.005a1.545 1.545 0 0 1 0-2.911zm2.812 3.109a2.8 2.8 0 0 1 .685 1.114l.448 1.377a.544.544 0 0 0 1.027 0l.447-1.377a2.83 2.83 0 0 1 1.798-1.796l1.378-.448a.545.545 0 0 0 0-1.025l-.027-.007l-1.378-.448a2.84 2.84 0 0 1-1.798-1.796l-.448-1.377a.544.544 0 0 0-1.026 0l-.448 1.377l-.012.034a2.84 2.84 0 0 1-1.759 1.762l-1.377.448a.545.545 0 0 0 0 1.025l1.377.448c.42.14.801.376 1.113.689m7.164 3.819l.765.248l.016.004a.302.302 0 0 1 0 .57l-.766.248a1.58 1.58 0 0 0-.999.999l-.248.764a.302.302 0 0 1-.57 0l-.25-.764a1.58 1.58 0 0 0-.998-1.002l-.766-.249a.302.302 0 0 1 0-.57l.766-.248a1.58 1.58 0 0 0 .983-.998l.249-.765a.302.302 0 0 1 .57 0l.249.764a1.58 1.58 0 0 0 .999.999"
			></path>
		</svg>
	);
}

export function PhSparkleFill(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 256 256"
			{...props}
		>
			<defs>
				<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
					<stop
						offset="0%"
						style={{ stopColor: '#fddfa5', stopOpacity: 1 }}
					/>
					<stop
						offset="50%"
						style={{ stopColor: '#fda7a6', stopOpacity: 1 }}
					/>
					<stop
						offset="100%"
						style={{ stopColor: '#d0c1f0', stopOpacity: 1 }}
					/>
				</linearGradient>
			</defs>
			<rect
				x="0"
				y="0"
				width="100%"
				height="100%"
				rx="40" // Rounded corners
				ry="40" // Rounded corners
				fill="url(#grad1)" // Use the gradient fill
			/>
			<path
				fill="white"
				d="M208 144a15.78 15.78 0 0 1-10.42 14.94L146 178l-19 51.62a15.92 15.92 0 0 1-29.88 0L78 178l-51.62-19a15.92 15.92 0 0 1 0-29.88L78 110l19-51.62a15.92 15.92 0 0 1 29.88 0L146 110l51.62 19A15.78 15.78 0 0 1 208 144m-56-96h16v16a8 8 0 0 0 16 0V48h16a8 8 0 0 0 0-16h-16V16a8 8 0 0 0-16 0v16h-16a8 8 0 0 0 0 16m88 32h-8v-8a8 8 0 0 0-16 0v8h-8a8 8 0 0 0 0 16h8v8a8 8 0 0 0 16 0v-8h8a8 8 0 0 0 0-16"
			></path>
		</svg>
	);
}

export function FluentEmojiSparkle20Filled(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 20 20"
			{...props}
		>
			<defs>
				<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
					<stop
						offset="0%"
						style={{ stopColor: '#fddfa5', stopOpacity: 1 }}
					/>
					<stop
						offset="50%"
						style={{ stopColor: '#fda7a6', stopOpacity: 1 }}
					/>
					<stop
						offset="100%"
						style={{ stopColor: '#d0c1f0', stopOpacity: 1 }}
					/>
				</linearGradient>
			</defs>
			<rect
				x="0"
				y="0"
				width="100%"
				height="100%"
				rx="4" // Rounded corners
				ry="4" // Rounded corners
				fill="url(#grad1)" // Use the gradient fill
			/>
			<path
				fill="white"
				d="m15.226 1.353l-.348-1.07a.423.423 0 0 0-.799 0l-.348 1.07a2.2 2.2 0 0 1-1.377 1.397l-1.071.348a.42.42 0 0 0-.283.4V3.5a.42.42 0 0 0 .283.4l.424.134l.647.21a2.2 2.2 0 0 1 1.399 1.403l.348 1.07a.423.423 0 0 0 .8.001l.102-.313v-.008l.226-.695l.009-.013l.012-.037a2.2 2.2 0 0 1 .691-1.01l-.002-.002c.21-.172.449-.305.707-.39l1.072-.348a.423.423 0 0 0 0-.798l-.022-.006l-1.072-.348a2.2 2.2 0 0 1-1.398-1.397m3.791 6.612l.766.248l.015.004a.303.303 0 0 1 0 .57l-.765.248a1.58 1.58 0 0 0-1 .999l-.248.764a.302.302 0 0 1-.57 0v-.002l-.25-.765l-.036-.098a1.58 1.58 0 0 0-.964-.902l-.765-.248a.3.3 0 0 1-.202-.284a.3.3 0 0 1 .056-.175a.3.3 0 0 1 .146-.111l.765-.248q.045-.014.088-.033a1.58 1.58 0 0 0 .898-.966l.249-.764a.302.302 0 0 1 .57 0l.249.764a1.58 1.58 0 0 0 .999.999m-1.263 4.012a8 8 0 1 1-6.587-9.893l-.193.063a1.44 1.44 0 0 0-.957 1.132a1.42 1.42 0 0 0 .933 1.56l1.1.356a1.2 1.2 0 0 1 .465.292a1.2 1.2 0 0 1 .291.473l.347 1.067c.087.26.246.488.46.66l.024.02l.046.033a1.4 1.4 0 0 0 .441.209a1.3 1.3 0 0 0 .748 1.777l.79.257a.57.57 0 0 1 .357.363l.248.762a1.31 1.31 0 0 0 1.037.878q.226.034.45-.009M12.5 7.5a1 1 0 1 0 0 2a1 1 0 0 0 0-2m-5 0a1 1 0 1 0 0 2a1 1 0 0 0 0-2m-.611 5.015a.5.5 0 1 0-.778.629A5 5 0 0 0 10 15c1.57 0 2.973-.725 3.889-1.856a.5.5 0 1 0-.778-.63A4 4 0 0 1 10 14a4 4 0 0 1-3.111-1.485"
			></path>
		</svg>
	);
}

export function IcSharpTipsAndUpdates(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={16}
			height={16}
			viewBox="0 0 24 24"
			{...props}
		>
			{/* <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: "#fddfa5", stopOpacity: 1 }} />
          <stop offset="50%" style={{ stopColor: "#fda7a6", stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{ stopColor: "#d0c1f0", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        rx="4" // Rounded corners
        ry="4" // Rounded corners
        fill="url(#grad1)" // Use the gradient fill
      /> */}
			<path
				fill={getCtaColor()}
				d="M7 20h4c0 1.1-.9 2-2 2s-2-.9-2-2m-2-1h8v-2H5zm11.5-9.5c0 3.82-2.66 5.86-3.77 6.5H5.27c-1.11-.64-3.77-2.68-3.77-6.5C1.5 5.36 4.86 2 9 2s7.5 3.36 7.5 7.5m4.87-2.13L20 8l1.37.63L22 10l.63-1.37L24 8l-1.37-.63L22 6zM19 6l.94-2.06L22 3l-2.06-.94L19 0l-.94 2.06L16 3l2.06.94z"
			></path>
		</svg>
	);
}
