import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { createStyles, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  BreatheInsturctionTypography,
  CountdownTimerTypography,
  SubHeader,
  Title,
} from "../../Util/Components/Typography";
import { Button, Fade, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ScorePopup from "./ScorePopup";
import { Stop } from "@mui/icons-material";
import { getCtaColor, getCtaHoverColor } from "../../Util/Helper";
import breatheCloud from "../../assets/images/svg-files/breatheCloud.svg";

// onst useStyles = makeStyles((theme: Theme) =>
// 	createStyles({
// 		circle: {
// 			alignItems: 'center',
// 			justifyContent: 'center',
// 			position: 'absolute',
// 			backgroundColor: '#dbeee8',
// 			display: 'flex'
// 		},
// 		initCircle: {
// 			alignItems: 'center',
// 			justifyContent: 'center',
// 			position: 'absolute',
// 			backgroundColor: '#A6D5C5',
// 			display: 'flex'
// 		},
// 		centerCircle: {
// 			alignItems: 'center',
// 			justifyContent: 'center',
// 			display: 'flex',
// 			position: 'absolute',
// 			backgroundColor: '#A6D5C5',
// 			zIndex: 1
// 		},
// 		animatedCircle: {
// 			backgroundColor: '#c6e4da',
// 			position: 'absolute',
// 			display: 'flex',
// 			alignItems: 'center',
// 			justifyContent: 'center'
// 			// transition: 'all 6s ease-in-out',
// 		},
// 		exhaledState: {
// 			backgroundColor: '#A6D5C5',
// 			position: 'absolute',
// 			display: 'flex',
// 			alignItems: 'center',
// 			justifyContent: 'center',
// 			transform: 'scale(.5)'
// 		},
// 		inhaledState: {
// 			backgroundColor: '#c6e4d9',
// 			position: 'absolute',
// 			display: 'flex',
// 			alignItems: 'center',
// 			justifyContent: 'center',
// 			transform: 'scale(1)'
// 		},
// 		container: {
// 			position: 'relative'
// 		},

// 		inhale: {
// 			animation: '$inhaleAnimation 4s ease-in-out forwards' // Adjust the duration to 6 seconds
// 		},
// 		exhale: {
// 			animation: '$exhaleAnimation 4s ease-in-out forwards'
// 		},
// 		exhaleFully: {
// 			animation: '$exhaleAnimation 5s ease-in-out forwards',
// 			alignItems: 'center',
// 			justifyContent: 'center',
// 			position: 'absolute',
// 			backgroundColor: '#A6D5C5'
// 			// zIndex: 1
// 		},

// 		'@keyframes inhaleAnimation': {
// 			'0%': {
// 				transform: 'scale(.5)'
// 			},
// 			'100%': {
// 				transform: 'scale(1)'
// 			}
// 		},
// 		'@keyframes exhaleAnimation': {
// 			'0%': {
// 				transform: 'scale(1)'
// 			},
// 			'100%': {
// 				transform: 'scale(.5)'
// 			}
// 		}
// 	})
// );
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circle: {
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: "#EEECFA",
      display: "flex",
    },
    centerCircle: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      position: "absolute",
      backgroundColor: "#A699C0",
      zIndex: 1,
    },
    animatedCircle: {
      backgroundColor: "#D0C2F0",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    inhale: {
      animation: "$inhaleAnimation 5s ease-in-out forwards", // Adjust the duration to 6 seconds
      animationPlayState: (props: { isPlaying: boolean }) =>
        props.isPlaying ? "running" : "paused",
      // '&:hover': {
      // 	animationPlayState: 'running'
      // }
    },
    exhale: {
      animation: "$exhaleAnimation 5s ease-in-out forwards",
      animationPlayState: (props: { isPlaying: boolean }) =>
        props.isPlaying ? "running" : "paused",
      // animationPlayState: 'paused',
      // '&:hover': {
      // 	animationPlayState: 'running'
      // }
    },

    "@keyframes inhaleAnimation": {
      "0%": {
        transform: "scale(.5)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
    "@keyframes exhaleAnimation": {
      "0%": {
        transform: "scale(1)",
      },
      "100%": {
        transform: "scale(.5)",
      },
    },
  })
);

export const Breathe = (props: {
  numberOfBreaths: number;
  setCompleted: (value: boolean) => void;
  setOpenBreathingExercise: (value: boolean) => void;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const classes = useStyles({ isPlaying: isPlaying });
  const [circleState, setCircleState] = useState<"inhale" | "exhale" | null>(
    null
  );
  //   const [counter, setCounter] = useState(5);
  //   const { progressCount, setProgressCount } = props;
  const [complete, setComplete] = useState(false);

  //   const classes = useStyles();
  //   const [circleState, setCircleState] = useState<
  //     "inhale" | "hold" | "exhale" | null
  //   >(null);
  //   const [inhaleInterval, setinhaleInterval] = useState(4); // Default interval for inhaleing phase
  //   const [holdInterval, setHoldInterval] = useState(4);
  //   const [exhaleInterval, setexhaleInterval] = useState(4); // Default interval for exhaleing phase
  const [counter, setCounter] = useState(5);
  //   const [countUp, setCountUp] = useState(false);
  //   const [showPopup, setShowPopup] = useState(false);
  const [numBreaths, setNumBreaths] = useState(1);
  const [progressCount, setProgressCount] = useState(0);
  useEffect(() => {
    if (counter > 0) {
      const intervalId = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);

      // Clear the interval when the component is unmounted or when counter reaches 0
      return () => clearInterval(intervalId);
    } else {
      setTimeout(() => {
        if (!isPlaying) {
          return;
        }
        setCircleState((prev) => {
          // setCounter(4);
          return prev === "inhale" ? "exhale" : "inhale";
        });
        // setVisible((prev) => !prev);
        setCounter(5);
        if (circleState === "exhale") {
          setProgressCount(progressCount + 1);
          if (progressCount + 1 === 10) {
            setComplete(true);
          }
        }
      }, 100);
    }
  }, [counter, setCircleState]);

  //   useEffect(() => {
  //     if (counter === null) {
  //       return;
  //     } else if (counter > 0) {
  //       if (!showPopup) {
  //         const intervalId = setInterval(() => {
  //           setCounter((prevCounter) =>
  //             countUp ? prevCounter + 1 : prevCounter - 1
  //           );
  //         }, 1000);

  //         // Clear the interval when the component is unmounted or when counter reaches 0
  //         return () => clearInterval(intervalId);
  //       }
  //     } else {
  //       setTimeout(() => {
  //         setCircleState("inhale");
  //         setCountUp(false);
  //         setIsInhale(true);
  //         setCounter(4);
  //       }, 80);
  //     }
  //   }, [counter, setCircleState, exhaleInterval, holdInterval]);
  const [isInhale, setIsInhale] = useState<null | boolean>(null);
  if (progressCount === props.numberOfBreaths) {
    props.setOpenBreathingExercise(false);
    props.setCompleted(true);
  }
  //   useEffect(() => {
  //     const intervalDuration = (prev: string | null) => {
  //       if (prev === "inhale") return inhaleInterval * 1000;
  //       if (prev === "exhale") return exhaleInterval * 1000;
  //       return holdInterval * 1000;
  //     };

  //     const timer = setInterval(() => {
  //       if (circleState === null) return;
  //       setCircleState((prev) => {
  //         setCountUp(false);
  //         setCounter(4);
  //         if (prev === "hold") {
  //           setIsInhale(!isInhale);
  //           if (!isInhale) {
  //             setNumBreaths(numBreaths + 1);
  //           }
  //           return isInhale ? "exhale" : "inhale";
  //         }
  //         if (["inhale", "exhale"].includes(prev)) {
  //           return "hold";
  //         }
  //         return prev; // If 'hold' or null, keep the current state
  //       });
  //     }, intervalDuration(circleState));

  //     // Clear the interval after one minute
  //     setTimeout(() => {
  //       clearInterval(timer);
  //     }, 600000);

  //     // Cleanup the interval when the component unmounts
  //     return () => clearInterval(timer);
  //   }, [circleState, holdInterval, inhaleInterval, exhaleInterval]);
  const onStart = () => {
    setIsPlaying(true);
    setProgressCount(1);
  };
  return (
    <Grid
      container
      item
      xs
      justifyContent="center"
      alignItems="center"
      direction="column"
      height="100%"
      width="100%"
    >
      <Grid
        container
        item
        xs
        justifyContent="center"
        direction="column"
        alignItems="center"
        sx={{
          borderRadius: "8px",
        }}
      >
        <Grid
          container
          item
          xs
          justifyContent="center"
          alignItems="center"
          // height="140px"
          sx={{ position: "relative" }}
        >
          <Button
            disableRipple
            fullWidth
            onClick={onStart}
            sx={{
              height: "190px",
              width: "190px",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: isPlaying ? "white" : "#73A89919",
              },
            }}
          >
            <Grid
              className={classes.circle}
              sx={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
              }}
            />
            <Grid
              className={classes.centerCircle}
              sx={{
                width: "75px",
                height: "75px",
                borderRadius: "50%",
              }}
            >
              {isPlaying === false && (
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: 600,
                    textAlign: "center",
                    lineHeight: "72px",
                  }}
                >
                  {isPlaying === false ? "start" : circleState}
                </Typography>
              )}
              {isPlaying === true && counter > 0 && counter < 6 && (
                <Fade in={true} timeout={2000}>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 500,
                      textAlign: "center",
                      lineHeight: "72px",
                    }}
                  >
                    {circleState}
                  </Typography>
                </Fade>
              )}
            </Grid>
            {circleState === "inhale" && (
              <Grid
                className={`${classes.animatedCircle} ${classes.inhale}`}
                sx={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  animationPlayState: isPlaying ? "running" : "paused",
                }}
              />
            )}

            {circleState === "exhale" && (
              <Grid
                // elevation={3}
                className={`${classes.animatedCircle} ${classes.exhale}`}
                sx={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  animationPlayState: isPlaying ? "running" : "paused",
                }}
              />
            )}
            {/* {circleState === 'hold' && (
					<Grid
						// elevation={3}
						className={`${classes.animatedCircle} ${isInhale
							? classes.inhaledState
							: classes.exhaledState
							}`}
						sx={{
							width: '150px',
							height: '150px',
							borderRadius: '50%',
							animationPlayState: isHovering ? 'running' : 'paused'
						}}
					/>
				)} */}
          </Button>
        </Grid>
        {/* <Grid container marginTop="16px" justifyContent="center" alignItems="center">
                {
                    isPlaying ? <Typography sx={{ fontSize: '16px', fontFamily: 'arial', color: "#2c2c2c" }}>Round {progressCount} of 10</Typography> :
                        <ActionButton text="Start" endIcon={<PlayArrow />} onClick={() => setIsPlaying(true)} />
                }
            </Grid> */}
      </Grid>
      <Grid
        container
        item
        // xs
        // sx={{ backgroundColor: 'red' }}
        // height="148px"
        paddingTop="40px"
        // marginBottom="80px"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <InstructionGuide
          text={
            circleState === null
              ? "Sit or lay in a comfortable position"
              : `Round ${numBreaths} of ${props.numberOfBreaths}`
          }
        />
        {/* <StopButton /> */}
      </Grid>
    </Grid>
  );
};

export function BreathingFinished(props: { numberOfBreaths: number }) {
  const weekday = ["M", "T", "W", "T", "F", "S", "S"];
  return (
    <Grid
      container
      item
      xs
      justifyContent="center"
      alignItems="center"
      direction="column"
      height="100%"
      width="100%"
    >
      <Grid container item xs direction="column">
        <Typography
          sx={{
            color: "#0A090B",
            fontWeight: 450,
            fontSize: "24px",
          }}
        >
          Well Done!
        </Typography>
        <Typography
          sx={{
            color: "#4F4D55",
            fontSize: "14px",
            marginTop: "16px",
          }}
        >
          Now, let go of any lingering feelings of unease. We'll hold onto it
          for you in your Sage space.
        </Typography>
        <Grid
          container
          item
          xs
          alignContent="center"
          justifyContent="center"
          direction="column"
          width="100%"
        >
          {/* <Grid
						container
						item
						xs
						justifyContent="center"
						marginTop="48px"
					></Grid> */}
          <Grid
            container
            item
            xs
            marginTop="24px"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <img width="140px" src={breatheCloud} />
            <Typography sx={{ color: "#0A090B", fontSize: "24px" }}>
              1 Consecutive Day
            </Typography>
            <Typography
              sx={{
                color: "#4F4D55",
                fontSize: "14px",
                marginTop: "16px",
              }}
            >
              You've just taken {props.numberOfBreaths} fully conscious breaths.
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs
            alignItems="center"
            // margin="24px 0px"
          >
            {weekday.map((day, idx) => (
              <Grid container item xs direction="column" alignItems="center">
                <Grid
                  container
                  item
                  borderRadius="24px"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #F1F1F1",
                    width: "48px",
                    height: "48px",
                    backgroundColor: "#FFF4E1",
                  }}
                >
                  <Grid
                    container
                    item
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "32px",
                      height: "32px",
                      borderRadius: "16px",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <Typography
                      sx={{
                        // marginTop: '16px',
                        fontSize: "12px",
                        lineHeight: "20px",
                        fontWeight: 450,
                        textAlign: "center",
                        // marginBottom: '12px',
                        color: "#7F7D83",
                      }}
                    >
                      {day}
                    </Typography>
                    {/* <img src={check} /> */}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
const InstructionGuide = (props: { text: string }) => {
  return (
    <Grid container item xs justifyContent="center" paddingBottom="80px">
      <Typography
        sx={{
          fontWeight: 500,
          fontColor: "#4F4D55",
          fontSize: "24px",
          lineHeight: "28px",
        }}
      >
        {props.text}
      </Typography>
    </Grid>
  );
};

const StopButton = () => {
  return (
    <Grid container item xs justifyContent="center">
      <Button
        startIcon={<Stop />}
        sx={{
          height: "40px",
          "&:hover": {
            backgroundColor: getCtaHoverColor(),
            cursor: "pointer",
          },
          textTransform: "none",
          backgroundColor: getCtaColor(),
          color: "white",
        }}
      >
        <Typography>Stop</Typography>
      </Button>
    </Grid>
  );
};

export default Breathe;
