import { useSelector } from 'react-redux';
import { RootState } from '../../../../Util/Redux/Schema';
import { Cloud } from '@mui/icons-material';
import { Grid, Tooltip, Typography } from '@mui/material';
import {
	InsightsCard,
	distributePercentage,
	getSentimentColor
} from '../../../../Util/Helper';
import SectionHeaderTypography from '../../../atoms/SectionHeaderTypography';
import { PieChart } from '@mui/x-charts/PieChart';
import { ChartsLegend } from '@mui/x-charts';

export default function DominantEmotionsCard(props: { view: string }) {
	return (
		<InsightsCard insightType="Thoughts" title="Emotions">
			<Grid
				container
				item
				direction="column"
				padding="24px 40px"
				justifyContent="center"
			>
				<Grid container item xs alignItems="center">
					<SectionHeaderTypography title="Emotions" />
				</Grid>
				<Chart />
				<ProgressCountInsights />
			</Grid>
		</InsightsCard>
	);
}
const data = [
	{ value: 5, label: 'Anger', color: '#E64A4D80' },
	{ value: 10, label: 'Anxiety', color: '#F1956380' },
	{ value: 15, label: 'Joy', color: '#E8C75D80' },
	{ value: 20, label: 'Disgust', color: '#67905380' },
	{ value: 20, label: 'Sadness', color: '#5B89CA80' },
	{ value: 20, label: 'Fear', color: '#C19BD580' },
	{ value: 20, label: 'Ennui', color: '#3E477180' },

];
function Chart() {


	return (
		<PieChart
			marginLeft={40}
			width={330}
			height={300}
			display={false}
			slotProps={{
				legend: {
					hidden: true // This hides the legend
				}
			}}
			// tooltip={{
			//     formatter: (params) => `${params.name}: ${((params.value / total) * 100).toFixed(2)}%`,
			// }}
			series={[
				{
					data: data,
					innerRadius: 20,
					outerRadius: 100,
					paddingAngle: 3,
					cornerRadius: 5,
					// startAngle: -90,
					// endAngle: 180,
					cx: 150,
					cy: 150
				}
			]}
		>
			{/* <Tooltip /> */}
		</PieChart>
	);
}

export function ProgressCountInsights() {
	const previousThoughts = useSelector(
		(state: RootState) => state.thoughts.weeklyThoughts
	);
	const dailyThought = useSelector(
		(state: RootState) => state.thoughts.dailyThoughts
	);
	const thoughts = [...previousThoughts, ...dailyThought];
	const currentDate = new Date();
	const currentDay = (currentDate.getDay() - 1 + 7) % 7; // 0 is Monday, ..., 5 is Saturday, 6 is Sunday
	const daysInWeek = 7;
	const startOfWeek = new Date(currentDate);
	startOfWeek.setDate(currentDate.getDate() - currentDay); // Move to the beginning of the week (Monday)
	const totalPositive = thoughts.filter(
		(thought) => thought.sentiment !== 'negative'
	).length;
	const totalNegative = thoughts.length - totalPositive;

	function Stat(props: {
		paddingTop?: boolean;
		count: number;
		color: string;
		type: 'Joyful' | 'Harsh';
	}) {
		const colors = getSentimentColor(props.type);

		return (
			<Grid
				container
				item
				// xs
				alignItems="center"
				justifyContent="center"
				paddingTop={props.paddingTop ? '16px' : undefined}
			// sx={{ backgroundColor: 'red' }}
			>
				<Grid
					container
					item
					// xs={8}
					// xl={6}
					// sx={{ backgroundColor: 'red' }}
					alignItems="center"
					justifyContent="center"
				>
					<Grid container item xs alignItems="center">
						<Grid
							container
							item
							style={{
								width: '24px',
								height: '24px',
								backgroundColor: props.color,
								borderRadius: '4px',
								padding: '4px'
							}}
						/>
						<Typography
							sx={{
								color: '#4F4D55',
								fontSize: '14px',
								lineHeight: '24px',
								fontWeight: 600,
								marginLeft: '8px'
							}}
						>
							{props.type}
							{/* </b> {props.count} */}
							{/* thought {props.count > 1 && 's'} so far this week */}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		);
	}
	return (
		<Grid
			container
			item
		// marginTop="24px"
		// xs
		// direction="column"
		// height="310px"

		// alignItems="center"
		// sx={{ backgroundColor: 'red' }}
		>
			{/* <Typography>s */}
			<Grid container item xs>

				{
					data.slice(0, 4).map(d => (
						<Stat count={d.value} type={d.label} color={d.color} />
					))
				}
			</Grid>
			<Grid container item xs >
				{
					data.slice(4).map(d => (
						<Stat count={d.value} type={d.label} color={d.color} />
					))
				}
			</Grid>
		</Grid>
	);
}
