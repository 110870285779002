import { Typography } from '@mui/material';
import { secondaryColor } from '../Config';
import { styled } from '@mui/styles';

export const TitleTypography = styled(Typography)(() => ({
	cursor: 'default',
	fontFamily: 'arial',
	fontSize: '12px',
	lineHeight: '24px',
	color: '#345247',
	marginBottom: '8px',
	fontWeight: 550
}));

export const PasscodePrompt = styled(Typography)(({ theme }) => ({
	// paddingBottom: ,
	fontWeight: 'bold',
	fontSize: '50px',
	color: 'white',
	fontFamily: 'arial'
}));

export const Header = styled(Typography)(({ theme }) => ({
	paddingBottom: 2,
	fontWeight: 'bold',
	fontSize: 21,
	color: '#707070',
	fontFamily: 'arial'
}));

export const SubHeader = styled(Typography)(({ theme }) => ({
	paddingBottom: 2,
	// fontWeight: 'bold',
	fontSize: 17,
	color: '#707070',
	fontFamily: 'arial'
}));

export const CountdownTimerTypography = styled(Typography)(({ theme }) => ({
	paddingBottom: 2,
	fontWeight: '400',
	fontSize: 100,
	color: '#707070',
	fontFamily: 'arial'
}));

export const Title = styled(Typography)(({ theme }) => ({
	paddingBottom: 2,
	fontSize: 17,
	color: '#707070',
	fontFamily: 'arial',
	fontWeight: '550'
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	fontSize: 17,
	color: '#a4a4a4',
	fontFamily: 'arial',
	textDecoration: 'underline'
}));

export const TextTypography = styled(Typography)(({ theme }) => ({
	// fontWeight: 'bold',
	fontSize: 17,
	color: '#666666',
	fontFamily: 'arial'
}));

export const InstructionsStepTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: 14,
	color: '#333333',
	fontFamily: 'arial, sans-serif'
}));

export const InstructionTitleTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: 28,
	color: '#333333',
	fontFamily: 'arial, sans-serif'
}));

export const InstructionDescriptionTypography = styled(Typography)(
	({ theme }) => ({
		fontWeight: 400,
		fontSize: 16,
		color: '#3c4043',
		fontFamily: 'roboto, sans-serif'
	})
);

export const PlaylistTitleTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: 32,
	color: '#707070',
	fontFamily: 'arial, sans-serif'
}));

export const PlaylistHeaderypography = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: 13,
	color: '#707070',
	fontFamily: 'arial, sans-serif'
}));

export const PlaylistFrequencyTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: 14,
	color: '#707070',
	fontFamily: 'arial, sans-serif'
}));

export const PlaylistPurposeTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: 28,
	color: '#707070',
	fontFamily: 'arial, sans-serif'
}));

export const PlaylistChakraTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: 16,
	color: '#707070',
	fontFamily: 'roboto, sans-serif'
}));

export const PlaylistAffirmationTypography = styled(Typography)(
	({ theme }) => ({
		// fontWeight: 400,
		fontStyle: 'italic',
		fontSize: 16,
		color: '#707070',
		fontFamily: 'roboto, sans-serif'
	})
);
export const GoldenHourBoxTypography = styled(Typography)(({ theme }) => ({
	paddingBottom: 2,
	fontWeight: 'bold',
	fontSize: 17,
	color: secondaryColor,
	fontFamily: 'arial'
}));

export const StatsHeaderTypography = styled(Typography)(({ theme }) => ({
	paddingBottom: 2,
	fontWeight: 'bold',
	fontSize: 15,
	color: '#707070',
	fontFamily: 'arial'
}));

export const StatsCountTypography = styled(Typography)(({ theme }) => ({
	paddingBottom: 2,
	fontWeight: 'bold',
	fontSize: 21,
	color: '#707070',
	fontFamily: 'arial'
}));

export const BreatheInsturctionTypography = styled(Typography)(({ theme }) => ({
	// paddingBottom: 2,
	fontWeight: 'bold',
	fontSize: 21,
	color: '#707070',
	fontFamily: 'arial'
}));

export const TimerCounter = styled(Typography)(({ theme }) => ({
	// paddingBottom: 2,
	fontWeight: 400,
	fontSize: '21px',
	color: '#4E4E4E',
	fontFamily: 'Arial'
}));

export const CounterText = styled(Typography)(({ theme }) => ({
	// paddingBottom: 2,
	// fontWeight: "bold",
	fontSize: 18,
	color: '#444444',
	fontFamily: 'arial'
}));

export const JournalText = styled(Typography)(({ theme }) => ({
	// paddingBottom: 2,
	// fontWeight: "bold",
	fontSize: 15,
	color: '#7a7a7a',
	fontFamily: 'monospace'
}));

export const Text = styled(Typography)(({ theme }) => ({
	// paddingBottom: 2,
	// fontWeight: "bold",
	fontSize: 15,
	color: '#7a7a7a',
	fontFamily: 'arial'
}));

export const JournalTimeText = styled(Typography)(({ theme }) => ({
	// paddingBottom: 2,
	// fontWeight: "bold",
	fontSize: 12,
	color: '#7a7a7a',
	fontFamily: 'arial'
}));

export const StatsHeaderText = styled(Typography)(({ theme }) => ({
	// paddingBottom: 2,
	fontWeight: 'bold',
	fontSize: 21,
	color: '#7a7a7a',
	fontFamily: 'arial'
}));

export const StatsText = styled(Typography)(({ theme }) => ({
	// paddingBottom: 2,
	fontSize: 18,
	color: '#7a7a7a',
	fontFamily: 'arial'
}));

export const AddMoodText = styled(Typography)(({ theme }) => ({
	// paddingBottom: 2,
	// fontWeight: "bold",
	fontSize: 15,
	color: '#707070',
	fontFamily: 'arial',
	textDecoration: 'underline'
}));

export const WidgetHeader = styled(Typography)(({ theme }) => ({
	fontSize: 17,
	color: '#707070',
	fontFamily: 'arial',
	fontWeight: 'bold'
}));

export const TabTypography = styled(Typography)(({ theme }) => ({
	fontSize: 17,
	color: '#a1d0f9',
	fontFamily: 'arial',
	fontWeight: 'bold'
}));

export const PomodoroTimerTypography = styled(Typography)(({ theme }) => ({
	fontSize: 17,
	color: '#a1d0f9',
	fontFamily: 'arial',
	fontWeight: 'bold'
}));

export const CalendarText = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	color: '#3C3C3C',
	fontFamily: 'arial'
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
	fontSize: 15,
	color: '#707070',
	fontFamily: 'arial'
}));

export const StatsTitleText = styled(Typography)(({ theme }) => ({
	fontSize: 21,
	color: '#707070',
	fontFamily: 'arial',
	fontWeight: 'bold'
}));

export const SPScoreText = styled(Typography)(({ theme }) => ({
	fontSize: 42,
	fontWeight: 'bold',
	color: 'white',
	fontFamily: 'arial'
}));

export const QuestionText = styled(ButtonText)(({ theme }) => ({
	fontWeight: 'bold'
}));

export const AddNoteButtonText = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	lineHeight: '24px',
	fontWeight: 500,
	color: '#ADACB0',
	fontFamily: 'arial'
}));

export const ThoughtText = styled(Typography)(({ theme }) => ({
	// variant: "caption",
	fontSize: 13,
	color: '#444444'
}));
export const TaskItemText = styled(Typography)(({ theme }) => ({
	fontSize: 15,
	color: '#707070'
}));

export const DateText = styled(Typography)(({ theme }) => ({
	fontSize: 10,
	color: '#536371'
}));
export const WellnessHeader = styled(Typography)(({ theme }) => ({
	fontSize: 17,
	// color: '#A1A1A1',
	color: '#7a7a7a',
	fontFamily: 'arial',
	fontWeight: 'bold'
}));

export const WellnessText = styled(Typography)(({ theme }) => ({
	fontSize: 13,
	color: '#7a7a7a',
	fontWeight: 'bold',
	fontFamily: 'arial'
}));

export const SPCardLevel = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	color: '#355247',
	fontWeight: 'bold',
	fontFamily: 'arial'
}));

export const LoginText = styled(Typography)(({ theme }) => ({
	fontSize: 11,
	color: 'white',
	fontWeight: 'bold',
	fontFamily: 'arial'
}));

// const TextTypography = withStyles({
//     root: {
//       color: "#FFFFFF"
//     }
//   })(Typography);
