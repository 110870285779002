import {
	Air,
	FireTruck,
	LightModeRounded,
	LocalFireDepartmentRounded,
	WbSunny
} from '@mui/icons-material';
import { COLORS, getSentimentColor } from '../../Util/Helper';
import { Grid, Typography } from '@mui/material';

export default function NotebookLayout(props: {
	children?: React.ReactNode;
	title: string;
	label: string;
}) {
	return (
		<Grid
			container
			item
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
		>
			<Grid container>
				{props.label === 'Mind' ? (
					<WbSunny
						sx={{ color: getSentimentColor('positive').color }}
					/>
				) : props.label === 'Body' ? (
					<LocalFireDepartmentRounded
						sx={{ color: getSentimentColor('negative').color }}
					/>
				) : (
					<Air sx={{ color: getSentimentColor('reframed').color }} />
				)}
				{/* <WbSunny sx={{ color: getSentimentColor('positive').color }} /> */}
				<Typography
					sx={{
						marginLeft: '12px',
						cursor: 'default',
						fontFamily: 'arial',
						fontSize: '12px',
						lineHeight: '24px',
						fontWeight: 550,
						color: getSentimentColor(
							props.label === 'Mind'
								? 'positive'
								: props.label === 'Body'
								  ? 'negative'
								  : 'reframed'
						).text
					}}
				>
					{props.label}
				</Typography>
			</Grid>
			<Typography
				sx={{
					cursor: 'default',
					fontFamily: 'arial',
					fontSize: '12px',
					lineHeight: '24px',
					// fontWeight: 550,
					color: '#345247'
				}}
			>
				{props.title}
			</Typography>

			<Grid
				container
				item
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
			>
				{props.children}
			</Grid>
		</Grid>
	);
}

export function NotebookSmall(props: {
	children?: React.ReactNode;
	title: string;
	marginTop?: string;
}) {
	return (
		<Grid
			container
			marginTop={props.marginTop}
			border="1px solid #F1F1F1"
			borderRadius="16px"
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			sx={{
				backgroundColor: 'white',
				border: `1px solid ${COLORS.wellbeing.backgroundColor}`
			}}
		>
			<Grid
				container
				borderRadius="16px 16px 0 0"
				item
				alignItems="center"
				padding="8px"
				sx={{ backgroundColor: COLORS.wellbeing.backgroundColor }}
			>
				<Typography
					sx={{
						marginLeft: '8px',
						cursor: 'default',
						fontFamily: 'arial',
						fontSize: '14px',
						color: COLORS.wellbeing.text
					}}
				>
					{props.title}
				</Typography>
			</Grid>
			<Grid
				container
				item
				xs
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				padding="8px 12px"
				paddingRight="0px"
				sx={{
					backgroundColor: 'white',
					borderBottomLeftRadius: '16px',
					borderBottomRightRadius: '16px',
					borderTop: '2px solid transparent'
				}}
			>
				{props.children}
			</Grid>
		</Grid>
	);
}
