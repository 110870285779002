import {
	ADD_SAGE_THOUGHT,
	ADD_SAGE_THOUGHT_UUID_TO_THOUGHT,
	ADD_THOUGHT,
	ADD_THOUGHT_SENTIMENT,
	FETCH_GOLDEN_HOUR,
	FETCH_SENTIMENT_SCORE,
	FETCH_THOUGHTS,
	FETCH_THOUGHT_NOUNS,
	FETCH_USER,
	FILL_GRATITUDE_RESPONSE,
	FILL_SOCIAL_CHALLENGE_COMPLETED,
	GET_DAILY_THOUGHTS,
	GET_MONTHLY_GOLDEN_HOUR_STATS,
	GET_MONTHLY_THOUGHTS,
	GET_THOUGHT_COUNTS,
	GET_THOUGHT_NOUNS,
	GET_WEEKLY_GOLDEN_HOUR_STATS,
	GET_WEEKLY_THOUGHTS,
	MODIFY_TIMER_DURATION,
	REMOVE_THOUGHT,
	REMOVE_THOUGHT_EMOTIONS,
	SELECT_THOUGHT_EMOTIONS,
	SET_AUTHENTICATED,
	SET_AUTHORIZED,
	SET_CHECKIN_SUBMITTED,
	SET_EMOTIONS,
	SET_PIN_TOKEN,
	SET_SESSION_TOKEN,
	UPDATE_MEDITATION_TIME_REMAINING,
	UPDATE_THOUGHT
} from './Middleware';
import {
	Auth,
	Config,
	DailyCheckin,
	GoldenHour,
	RootState,
	SageThoughts,
	Stats,
	Thought,
	Thoughts,
	User,
	initialState
} from './Schema';
import {
	AuthAction,
	ConfigAction,
	DailyCheckinAction,
	GoldenHourAction,
	RootActions,
	SageThoughtsAction,
	StatsAction,
	ThoughtsAction,
	UserAction
} from './Action';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import { applyMiddleware } from 'redux';
import { combineReducers } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { stat } from 'fs';

export const configReducer = (
	state: Config = initialState.config,
	action: ConfigAction
) => {
	switch (action.type) {
		case MODIFY_TIMER_DURATION:
			return { ...state, config: { duration: action.payload.duration } };
		default:
			return state;
	}
};

const authReducer = (state: Auth = initialState.auth, action: AuthAction) => {
	switch (action.type) {
		case SET_SESSION_TOKEN:
			return { ...state, sessionToken: action.payload.token };
		case SET_PIN_TOKEN:
			return { ...state, pinToken: action.payload.token };
		case SET_AUTHORIZED:
			return { ...state, isAuthorized: action.payload.isAuthorized };
		case SET_AUTHENTICATED:
			return {
				...state,
				isAuthenticated: action.payload.isAuthenticated
			};
		default:
			return state;
	}
};

const wellbeingReducer = (
	state: GoldenHour = initialState.wellbeing,
	action: GoldenHourAction
) => {
	switch (action.type) {
		case FETCH_GOLDEN_HOUR:
			return action.payload.goldenhour;
		default:
			return state;
	}
};

const dailyCheckinReducer = (
	state: DailyCheckin = initialState.dailyCheckin,
	action: DailyCheckinAction
) => {
	switch (action.type) {
		case UPDATE_MEDITATION_TIME_REMAINING:
			return {
				...state,
				meditationTimeRemaining: action.payload.seconds
			};
		case SET_CHECKIN_SUBMITTED:
			return {
				...state,
				submitted: action.payload.isSubmitted
			};
		case FILL_GRATITUDE_RESPONSE:
			return {
				...state,
				gratitudes: action.payload.response
			};
		case SET_EMOTIONS:
			return {
				...state,
				computedTopEmotions: action.payload.computedTopEmotions
			};
		case FILL_SOCIAL_CHALLENGE_COMPLETED:
			return {
				...state,
				socialChallengeCompleted: action.payload.response
			};
		default:
			return state;
	}
};

const sageThoughtReducer = (
	state: SageThoughts = initialState.sageThoughts,
	action: SageThoughtsAction
) => {
	switch (action.type) {
		case ADD_SAGE_THOUGHT:
			const uuid = action.payload.thoughtUuid;
			return {
				...state,
				[uuid]: action.payload.sageThought
			};
		default:
			return state;
	}
};
const thoughtReducer = (
	state: Thoughts = initialState.thoughts,
	action: ThoughtsAction
) => {
	switch (action.type) {
		case ADD_THOUGHT:
			return {
				...state,
				dailyThoughts: [...state.dailyThoughts, action.payload.thought],
				weeklyThoughts: [
					...state.weeklyThoughts,
					action.payload.thought
				],
				monthlyThoughts: [
					...state.monthlyThoughts,
					action.payload.thought
				]
			};
		case SELECT_THOUGHT_EMOTIONS:
			return {
				...state,
				dailyThoughts: state.dailyThoughts.map((thought) =>
					thought.uuid === action.payload.uuid
						? {
								...thought,
								userSelectedEmotions:
									action.payload.userSelectedEmotions,
								sentiment: action.payload.sentiment
						  }
						: thought
				),
				weeklyThoughts: state.weeklyThoughts.map((thought) =>
					thought.uuid === action.payload.uuid
						? {
								...thought,
								userSelectedEmotions:
									action.payload.userSelectedEmotions,
								sentiment: action.payload.sentiment
						  }
						: thought
				),
				monthlyThoughts: state.monthlyThoughts.map((thought) =>
					thought.uuid === action.payload.uuid
						? {
								...thought,
								userSelectedEmotions:
									action.payload.userSelectedEmotions,
								sentiment: action.payload.sentiment
						  }
						: thought
				)
			};
		case REMOVE_THOUGHT_EMOTIONS:
			return {
				...state,
				dailyThoughts: state.dailyThoughts.map((thought) =>
					thought.uuid === action.payload.uuid
						? {
								...thought,
								userSelectedEmotions: []
						  }
						: thought
				),
				weeklyThoughts: state.weeklyThoughts.map((thought) =>
					thought.uuid === action.payload.uuid
						? {
								...thought,
								userSelectedEmotions: []
						  }
						: thought
				),
				monthlyThoughts: state.monthlyThoughts.map((thought) =>
					thought.uuid === action.payload.uuid
						? {
								...thought,
								userSelectedEmotions: []
						  }
						: thought
				)
			};
		case UPDATE_THOUGHT:
			return {
				...state,
				dailyThoughts: state.dailyThoughts.map((thought) =>
					thought.uuid === action.payload.uuid
						? { ...thought, ...action.payload.updatedThought }
						: thought
				),
				weeklyThoughts: state.weeklyThoughts.map((thought) =>
					thought.uuid === action.payload.uuid
						? { ...thought, ...action.payload.updatedThought }
						: thought
				),
				monthlyThoughts: state.monthlyThoughts.map((thought) =>
					thought.uuid === action.payload.uuid
						? { ...thought, ...action.payload.updatedThought }
						: thought
				)
			};
		case ADD_THOUGHT_SENTIMENT:
			return {
				...state,
				dailyThoughts: state.dailyThoughts.map((thought) =>
					thought.uuid === null
						? {
								...thought,
								uuid: action.payload.uuid,
								sentiment: action.payload.sentiment,
								computedTopEmotions:
									action.payload.computedTopEmotions
						  }
						: thought
				),
				weeklyThoughts: state.weeklyThoughts.map((thought) =>
					thought.uuid === null
						? {
								...thought,
								uuid: action.payload.uuid,
								sentiment: action.payload.sentiment,
								computedTopEmotions:
									action.payload.computedTopEmotions
						  }
						: thought
				),
				monthlyThoughts: state.monthlyThoughts.map((thought) =>
					thought.uuid === null
						? {
								...thought,
								uuid: action.payload.uuid,
								sentiment: action.payload.sentiment,
								computedTopEmotions:
									action.payload.computedTopEmotions
						  }
						: thought
				)
			};
		case REMOVE_THOUGHT:
			return {
				...state,
				dailyThoughts: state.dailyThoughts.filter(
					(thought) => thought.uuid === null
				),
				weeklyThoughts: state.weeklyThoughts.filter(
					(thought) => thought.uuid === null
				),
				monthlyThoughts: state.monthlyThoughts.filter(
					(thought) => thought.uuid === null
				)
			};
		case FETCH_THOUGHTS:
			return produce(state, (draft) => {
				draft.previousThoughts = action.payload.thoughts;
			});
		// return {
		// 	...state,
		// 	previousThoughts: cloneDeep(action.payload.thoughts)
		// };
		case GET_DAILY_THOUGHTS:
			return produce(state, (draft) => {
				draft.dailyThoughts = action.payload.dailyThoughts;
			});
		// return {
		// 	...state,
		// 	dailyThoughts: cloneDeep(action.payload.dailyThoughts)
		// };
		case GET_WEEKLY_THOUGHTS:
			return produce(state, (draft) => {
				draft.weeklyThoughts = action.payload.weeklyThoughts;
			});
		// return {
		// 	...state,
		// 	weeklyThoughts: cloneDeep(action.payload.weeklyThoughts)
		// };
		case GET_MONTHLY_THOUGHTS:
			return produce(state, (draft) => {
				draft.monthlyThoughts = action.payload.monthlyThoughts;
			});
		case ADD_SAGE_THOUGHT_UUID_TO_THOUGHT:
			return {
				...state,
				dailyThoughts: state.dailyThoughts.map((thought) =>
					thought.uuid === action.payload.thoughtUuid
						? {
								...thought,
								sageThoughtUuid: action.payload.sageThoughtUuid
						  }
						: thought
				)
			};
		default:
			return state;
	}
};

const userReducer = (state: User = initialState.user, action: UserAction) => {
	switch (action.type) {
		case FETCH_USER:
			return action.payload.user;
		default:
			return state;
	}
};

const statReducer = (
	state: Stats = initialState.stats,
	action: StatsAction
) => {
	switch (action.type) {
		case FETCH_THOUGHT_NOUNS:
			return {
				...state,
				nouns: action.payload.nouns
			};
		case FETCH_SENTIMENT_SCORE:
			return {
				...state,
				sentimentScore: action.payload.score
			};
		case GET_WEEKLY_GOLDEN_HOUR_STATS:
			return {
				...state,
				weeklyGoldenHour: [...action.payload.weeklyGoldenHour],
				weeklyFetched: true
			};
		case GET_MONTHLY_GOLDEN_HOUR_STATS:
			return {
				...state,
				monthlyGoldenHour: [...action.payload.monthlyGoldenHour],
				monthlyFetched: true
			};
		case GET_THOUGHT_NOUNS:
			if (action.payload.type === 'Week') {
				return {
					...state,
					weeklyThoughtNouns: {
						positive: action.payload.positiveThoughtNouns,
						neutral: action.payload.neutralThoughtNouns,
						negative: action.payload.negativeThoughtNouns
					}
				};
			} else if (action.payload.type === 'Month') {
				return {
					...state,
					monthlyThoughtNouns: {
						positive: action.payload.positiveThoughtNouns,
						neutral: action.payload.neutralThoughtNouns,
						negative: action.payload.negativeThoughtNouns
					}
				};
			} else {
				return {
					...state,
					allTimeThoughtNouns: {
						positive: action.payload.positiveThoughtNouns,
						neutral: action.payload.neutralThoughtNouns,
						negative: action.payload.negativeThoughtNouns
					}
				};
			}
		case GET_THOUGHT_COUNTS:
			return {
				...state,
				allTimeThoughtCounts: {
					positive: action.payload.numPositiveThoughts,
					neutral: action.payload.numNeutralThoughts,
					negative: action.payload.numNegativeThoughts
				}
			};
		default:
			return state;
	}
};

// Use RootState and ListAction to create the Store
const appReducer = combineReducers({
	// priorities: prioritiesReducer,
	config: configReducer,
	wellbeing: wellbeingReducer,
	auth: authReducer,
	dailyCheckin: dailyCheckinReducer,
	sageThoughts: sageThoughtReducer,
	thoughts: thoughtReducer,
	stats: statReducer,
	user: userReducer
});
export const rootReducer = (state: any, action: any) => {
	window.addEventListener('beforeunload', () => {
		// Check if it's the first time the page is loaded
		if (!localStorage.getItem('appInitialized')) {
			// Clear the localStorage
			localStorage.clear();
			// Set a flag to indicate that the app has been initialized
			localStorage.setItem('appInitialized', 'true');
		}
	});
	applyMiddleware(thunk as ThunkMiddleware<RootState, RootActions>);

	return appReducer(state, action);
};
