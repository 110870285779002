import '../../../../Animations/heartbeat.css';
import '../../../../App.css';

import { Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import { Close, RocketLaunchRounded } from '@mui/icons-material';
import { getCtaColor, getWellbeingImage } from '../../../../Util/Helper';

import Breathwork from './SpiritBreathwork';
import Checkin from './MindGratitude/Checkin';
import GoldStarWeekViewVertical from '../../InsightsPage/WellbeingTab/GoldStarWeekViewVertical';
import GoldenHourConsistencyChart from '../../../../routes/Insights/Components/GoldenHourConsistencyChart';
import GratitudeCheckinCard from '../../InsightsPage/WellbeingTab/GratitudeCheckin';
import MoveJournal from './BodyMoveJournal';
import NotebookLayout from '../../../templates/NotebookTemplate';
import { PhStarFill } from '../../../../assets/svgFiles';
import SectionHeaderTypography from '../../../atoms/SectionHeaderTypography';
import StatsView from './StatsView';
import { TitleTypography } from '../../../../Util/Components/Typography';
import WeeklyProgressTracker from '../../../organisms/Wellbeing/WeeklyProgressTracker';
import WellbeingTasksView from './TasksView';
import rocket from '../../../../assets/images/svg-files/rocket.svg';
import { useState } from 'react';

const Wellbeing = (props: { showStatsView?: boolean }) => {
	const [isTaskView, setIsTaskView] = useState(true);
	const [view, setView] = useState('Mind');
	const [progressCount, setProgressCount] = useState(null);
	return (
		<Grid
			container
			item
			xs
			direction="column"
			borderRadius="16px"
			sx={{ backgroundColor: 'white' }}
			border="1px solid #F3F2F3"
			padding="16px"
		>
			<Grid
				container
				direction="row"
				item
				marginBottom="16px"
				alignItems="center"
			>
				<Grid container item xs alignItems="center">
					<Typography
						sx={{
							cursor: 'default',
							fontFamily: 'arial',
							fontSize: '12px',
							lineHeight: '24px',
							color: '#345247',
							fontWeight: 550
						}}
					>
						Wellbeing
					</Typography>
				</Grid>
				<Grid
					container
					item
					xs
					justifyContent="flex-end"
					alignItems="center"
					// marginRight="12px"
					// sx={{ backgroundColor: 'red' }}
				>
					<Typography
						sx={{
							cursor: 'default',
							fontFamily: 'arial',
							fontSize: '12px',
							lineHeight: '24px',
							color: '#345247',
							textAlign: 'flex-end',
							marginRight: '8px'
						}}
					>
						1/7 Wisdom Points
					</Typography>
					<img src={rocket} width="24px" />
				</Grid>
			</Grid>
			<WeeklyProgressTracker />
			<Grid
				container
				item
				xs
				marginTop="32px"
				alignItems="flex-start"
				justifyContent="space-between"
				direction="column"
			>
				<NotebookLayout title="I am grateful for..." label="Mind">
					<Checkin />
				</NotebookLayout>
				<NotebookLayout
					title="What physical activities did you do today?"
					label="Body"
				>
					<MoveJournal />
				</NotebookLayout>
				<NotebookLayout
					title={
						progressCount === null
							? 'Take a break'
							: `Round ${progressCount} of 8`
					}
					label="Spirit"
				>
					<Breathwork
						completed={false}
						progressCount={progressCount}
						setProgressCount={setProgressCount}
					/>
				</NotebookLayout>
				{/* </Grid> */}

				{props.showStatsView && (
					<ViewStatsButton
						isTaskView={isTaskView}
						setIsTaskView={setIsTaskView}
					/>
				)}
			</Grid>
			{/* <WeeklyProgressTracker /> */}
		</Grid>
	);
};

const ViewStatsButton = (props: {
	isTaskView: boolean;
	setIsTaskView: (isTaskView: boolean) => void;
}) => {
	return (
		<Grid container item xs alignItems="center">
			<Grid container item xs direction="column">
				<Grid container item xs alignItems="center">
					<Grid container item xs justifyContent="flex-end">
						<IconButton
							onClick={() =>
								props.setIsTaskView(!props.isTaskView)
							}
						>
							{props.isTaskView ? <PhStarFill /> : <Close />}
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Wellbeing;

function BasicTabs(props: {
	selectedTab: string;
	setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}) {
	const { selectedTab, setSelectedTab } = props;
	const [value, setValue] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	const tabIndex = {
		0: 'Mind',
		1: 'Body',
		2: 'Spirit'
	};
	const TabButton = (props: { title: string; tabIndex: number }) => {
		const [hovered, setHovered] = useState(false);
		const onMouseEnter = () => {
			setHovered(true);
			// setImg(getWellbeingImage(tabIndex[props.tabIndex], true));
		};
		const onMouseLeave = () => {
			setHovered(false);
			// setImg(getWellbeingImage(props.tabIndex[props.tabIndex], false));
		};
		return (
			<Grid container item xs sx={{ borderRadius: '24px' }}>
				<Button
					fullWidth
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onClick={() => setSelectedTab(tabIndex[props.tabIndex])}
					sx={{
						margin: '2px',
						textTransform: 'none',
						fontFamily: 'arial',
						fontWeight:
							selectedTab === tabIndex[props.tabIndex]
								? 600
								: 450,
						fontSize: '16px',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '8px',
						// lineHeight: "22px",
						backgroundColor:
							selectedTab === tabIndex[props.tabIndex]
								? 'white'
								: '',
						color:
							selectedTab === tabIndex[props.tabIndex]
								? 'white'
								: '#A698C0',
						'&:hover': {
							backgroundColor: '#FAFBFC'
						}
					}}
					disableRipple
				>
					<Grid
						container
						item
						xs
						alignItems="center"
						justifyContent="center"
						// sx={{ backgroundColor: "red" }}
					>
						<Typography
							sx={{
								fontSize: '12px',
								color: '#A698C0',
								fontFamily: 'arial'
							}}
						>
							{props.title.toUpperCase()}
						</Typography>
					</Grid>
				</Button>
			</Grid>
		);
	};
	return (
		<Grid
			container
			item
			// xs
			alignItems="center"
			sx={{
				backgroundColor: '#EEECFA',
				padding: '2px 4px',
				borderRadius: '8px'
			}}
			justifyContent="flex-start"
		>
			<TabButton title="Mind" tabIndex={0} />
			<TabButton title="Body" tabIndex={1} />
			<TabButton title="Spirit" tabIndex={2} />
		</Grid>
	);
}
