import { Grid, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { headerHeight, sidebarWidth } from '../../Util/Config';

import Header from '../organisms/Header';
import { Lock } from '@mui/icons-material';
import Sidebar from '../organisms/Sidebar';
import { useNavigate } from 'react-router-dom';

type MainLayoutProps = {
	page: string;
	children: React.ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({ page, children }) => {
	// const [page, setPage] = React.useState("Love")
	const navigate = useNavigate();

	if (page === 'Lock') {
		navigate('/space');
	}
	const [collapse, setCollapse] = useState(true);
	return (
		<Grid
			container
			item
			xs
			direction="row"
			width="100vw"
			height="100vh"
			sx={{
				overflowX: 'hidden'
			}}
		>
			<Grid container item xs direction="column">
				<Header page={page} />
				<Grid
					container
					item
					xs
					justifyContent="center"
					alignItems="flex-start"
					marginTop={headerHeight}
					height="100%"
					width="100%" // `calc(100vh - ${headerHeight}px)`,
					style={{
						overflowY: 'hidden',
						backgroundImage:
							'linear-gradient(145deg, rgba(255, 244, 225, 0.42), rgba(217, 240, 231, 0.42), rgba(255, 227, 227, 0.42))'
						// backgroundColor: 'rgb(190, 228, 249, 0.42)'
						// backgroundImage:
						// 	'linear-gradient(145deg, rgba(255, 244, 225, 0.42), rgb(190, 228, 249, 0.42), rgba(255, 227, 227, 0.42))'
					}}
				>
					{/* <Grid container item xs={0.5} padding={{
						xl: '32px',
						lg: '32px',
						xs: '32px'
					}} sx={{ paddingLeft: '24px' }}>
						<Sidebar
							setCollapse={setCollapse}
							currentPage={page}
							setCurrentPage={setCurrentPage}
							collapse={collapse}
						/>
					</Grid> */}
					{children}
					{/* <Grid container item xs={0.5} justifyContent="flex-end" alignItems="flex-end" height="100%">
						<Grid container item justifyContent="flex-end">

							<Tooltip title="Lock screen">
								<IconButton
									sx={{
										color: '#AEB2BC',
										border: '1px solid #F8F8F8',
										width: '40px',
										height: '40px',
										marginRight: '12px'
									}}
								// onClick={onLock}
								>
									<Lock width="32px" height="32px" />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid> */}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default MainLayout;
