import Grid from '@mui/material/Grid';
import inhale from '../../../assets/images/inhale.png';
import hold from '../../../assets/images/hold.png';
import exhale from '../../../assets/images/exhale.png';
import Instruction from './Instruction';
import Preset from './Preset';

const Instructions = () => {
	return (
		<Grid container item xs justifyContent={'center'} alignItems={'center'}>
			<Instruction
				stepNumber={1}
				title="Inhale"
				description="through your nose for 4 seconds."
				imgSrc={inhale}
				currentStepNumber={1}
			/>
			<Instruction
				stepNumber={2}
				title="Hold"
				description="your breath for 7 seconds."
				imgSrc={hold}
			/>
			<Instruction
				stepNumber={3}
				title="Exhale"
				description="completely through your mouth for 8 seconds."
				imgSrc={exhale}
			/>
		</Grid>
	);
};

const DeepDiaphragmatic = (props: { setActivity: (x: string) => void }) => {
	return (
		<Preset
			setActivity={props.setActivity}
			title={'Diaphragmatic Breathing'}
			description="Deep diaphragmatic breathing promotes relaxation,
			reduces stress, and strengthens the diaphragm, enhancing overall 
			respiratory function and well-being. It also improves oxygenation and 
			helps alleviate muscle tension, particularly in the neck and shoulders."
			instructions={<Instructions />}
		/>
	);
};

export default DeepDiaphragmatic;
