import { Grid, Typography } from "@mui/material";

import ThoughtsEditor from "./ThoughtsEditor/ThoughtsEditor";
import Wellness from "./Wellbeing/Wellbeing";
import sageCircle from "../../../assets/images/sageCircle.png";

function CosmosBanner() {
  return (
    <Grid
      container
      item
      padding="8px"
      paddingLeft="16px"
      alignItems="center"
      sx={{
        backgroundColor: "#E4F2EC",
        borderRadius: "16px",
        height: "52px",
        boxShadow: "1", // Apply shadow effect
      }}
    >
      <img
        style={{
          borderRadius: "50%",
          padding: "4px",
        }}
        src={sageCircle}
        width="32px"
        alt="sage circle"
      />
      <Typography
        sx={{
          textAlign: "center",
          // marginLeft: '-32px',
          fontSize: "14px",
          lineHeight: "24px",
          fontWeight: 500,
          color: "#74A899",
          fontFamily: "arial",
        }}
      >
        Good morning! It's time to check in.
      </Typography>
    </Grid>
  );
}

export const Home = () => {
  return (
    <Grid
      container
      item
      width="1024px"
      height="768px"
      maxHeight="100%"
      direction="column"
      justifyContent="center"
    >
      <Grid container item xs width="100%">
        <ThoughtsEditor />
        <Grid
          container
          direction="column"
          item
          xs
          marginLeft="8px"
          borderRadius="16px"
        >
          <Wellness showStatsView={false} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
