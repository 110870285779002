import { useSelector } from 'react-redux';
import { RootState } from '../../../../Util/Redux/Schema';
import { Cloud, Lock } from '@mui/icons-material';
import { Divider, Grid, Tooltip, Typography } from '@mui/material';
import {
	InsightsCard,
	distributePercentage,
	getSentimentColor
} from '../../../../Util/Helper';
import SectionHeaderTypography from '../../../atoms/SectionHeaderTypography';
import { PieChart } from '@mui/x-charts/PieChart';
import { ChartsLegend } from '@mui/x-charts';
import TotalThoughtsCard from '../ThoughtsTab/TotalThoughtsCard';
import DominantEmotionsCard from '../ThoughtsTab/DominantEmotionsCard';

export default function CheckBackInCard() {
	return (
		<InsightsCard insightType="Thoughts" title="Emotions">

			<Grid
				container
				item
				xs
				// direction="column"
				justifyContent="center"
				alignItems="center"
				sx={{ backgroundColor: "white" }}

				padding="24px"
			>
				{/* <TotalThoughtsCard /> */}
				<Grid container item xs sx={{ background: '#F3F5FA', padding: '24px', borderRadius: '8px' }}>
					<Typography sx={{ fontFamily: 'arial', fontSize: '16px', fontWeight: 600, marginBottom: '8px' }}>
						Thought Summary
					</Typography>
					<Typography sx={{ fontFamily: 'arial', fontSize: '12px', lineHeight: '20px' }}>
						This week started on a high note with productive work and an energizing yoga session. Tuesday was stressful with tight deadlines, but a walk in the park helped unwind. Wednesday brought a more relaxed pace, allowing progress on a personal project and inspiration from a creative writing workshop. Thursday was busy yet controlled, with a successful brainstorming session and a new recipe tried for dinner. Friday ended the workweek with a sense of accomplishment and a casual get-together with colleagues. The weekend focused on self-care, with a visit to the farmer’s market, a refreshing hike, and a cozy night in. Sunday was spent preparing for the week ahead, enjoying a family brunch, and catching up on reading, leaving a feeling of readiness and rejuvenation.
						{/* Monday:
						Started the week with a productive morning, tackling work tasks efficiently. Felt energized after a yoga session in the evening, which set a positive tone for the rest of the week. Had a meaningful conversation with a friend over dinner about future plans.
						<br /> <br />
						Tuesday:
						Had a challenging day at work with several deadlines approaching. Managed to complete most tasks, though the stress was high. Took a long walk in the park after work to unwind, which helped clear my mind.
						<br /><br />
						Wednesday:
						A more relaxed day with fewer meetings at work. Focused on a personal project and made significant progress. Attended a virtual workshop on creative writing in the evening, which was inspiring.
						<br /><br />
						Thursday:
						Work was busy again, but felt more in control. Had a productive brainstorming session with the team. In the evening, tried a new recipe for dinner, which turned out great. Spent some time reading before bed.
						<br /><br />
						Friday:
						Ended the workweek on a high note with successful completion of a major project. Enjoyed a casual get-together with colleagues after work. Felt a sense of accomplishment and was excited for the weekend.
						<br /><br />
						Saturday:
						Relaxed day with a focus on self-care. Visited a local farmer’s market in the morning and bought fresh produce. Spent the afternoon hiking, which was refreshing. In the evening, watched a movie and had a quiet night in.
						<br /><br />
						Sunday:
						Used the day to prepare for the upcoming week. Organized the home and planned out work tasks. Had a cozy brunch with family and caught up on some personal reading. Felt ready and rejuvenated for the new week ahead. */}


					</Typography>
				</Grid>
				<DominantEmotionsCard />
				{/* <Lock
					style={{
						padding: '8px',
						backgroundColor: '#F8F8F8',
						color: '#DCDEE3',
						borderRadius: '8px',
						width: '32px',
						height: '32px'
					}}
				/>
				<Typography
					sx={{
						color: '#4F4D55',
						fontSize: '16px',
						marginTop: '16px',
						marginBottom: '4px'
					}}
				>
					In Progress
				</Typography>

				<Typography
					sx={{
						color: '#7F7D83',
						fontSize: '14px'
					}}
				>
					Please check back in at the end of the week
				</Typography> */}
			</Grid>
		</InsightsCard>
	);
}

function Chart() {
	const data = [
		{ value: 5, label: 'A', color: '#DFCCF7' },
		{ value: 10, label: 'B', color: '#AAE2E0' },
		{ value: 15, label: 'C', color: '#C2C7FB' },
		{ value: 20, label: 'D', color: '#F9F3A5' }
	];

	return (
		<PieChart
			marginLeft={40}
			width={330}
			height={300}
			display={false}
			slotProps={{
				legend: {
					hidden: true // This hides the legend
				}
			}}
			// tooltip={{
			//     formatter: (params) => `${params.name}: ${((params.value / total) * 100).toFixed(2)}%`,
			// }}
			series={[
				{
					data: data,
					innerRadius: 30,
					outerRadius: 100,
					paddingAngle: 5,
					cornerRadius: 5,
					// startAngle: -90,
					// endAngle: 180,
					cx: 150,
					cy: 150
				}
			]}
		>
			{/* <Tooltip /> */}
		</PieChart>
	);
}

export function ProgressCountInsights() {
	const previousThoughts = useSelector(
		(state: RootState) => state.thoughts.weeklyThoughts
	);
	const dailyThought = useSelector(
		(state: RootState) => state.thoughts.dailyThoughts
	);
	const thoughts = [...previousThoughts, ...dailyThought];
	const currentDate = new Date();
	const currentDay = (currentDate.getDay() - 1 + 7) % 7; // 0 is Monday, ..., 5 is Saturday, 6 is Sunday
	const daysInWeek = 7;
	const startOfWeek = new Date(currentDate);
	startOfWeek.setDate(currentDate.getDate() - currentDay); // Move to the beginning of the week (Monday)
	const totalPositive = thoughts.filter(
		(thought) => thought.sentiment !== 'negative'
	).length;
	const totalNegative = thoughts.length - totalPositive;

	function Stat(props: {
		paddingTop?: boolean;
		count: number;
		type: 'Joyful' | 'Harsh';
	}) {
		const colors = getSentimentColor(props.type);

		return (
			<Grid
				container
				item
				// xs
				alignItems="center"
				justifyContent="center"
				paddingTop={props.paddingTop ? '16px' : undefined}
			// sx={{ backgroundColor: 'red' }}
			>
				<Grid
					container
					item
					// xs={8}
					// xl={6}
					// sx={{ backgroundColor: 'red' }}
					alignItems="center"
					justifyContent="center"
				>
					<Grid container item xs alignItems="center">
						<Grid
							container
							item
							style={{
								width: '24px',
								height: '24px',
								backgroundColor: colors['backgroundColor'],
								color: colors['color'],
								borderRadius: '4px',
								padding: '4px'
							}}
						/>
						<Typography
							sx={{
								color: '#4F4D55',
								fontSize: '14px',
								lineHeight: '24px',
								fontWeight: 400,
								marginLeft: '8px'
							}}
						>
							<b>{props.type}: </b> {props.count} thought
							{props.count > 1 && 's'} so far this week
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		);
	}
	return (
		<Grid
			container
			item
			// marginTop="24px"
			// xs
			direction="column"
		// height="310px"

		// alignItems="center"
		// sx={{ backgroundColor: 'red' }}
		>
			{/* <Typography>s */}
			<Stat count={totalPositive} type="Happy" />
			<Stat count={totalNegative} type="Excited" paddingTop />
			<Stat count={totalNegative} type="Curious" paddingTop />
		</Grid>
	);
}
