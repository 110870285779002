import {
	Add,
	AddReaction,
	AddRounded,
	ArrowDownward,
	ArrowDropDownRounded,
	ChevronLeft,
	Circle,
	Cloud,
	CloudOutlined,
	CloudRounded,
	FlashOn,
	FlashOnRounded,
	LightMode,
	LightModeRounded,
	PlusOneRounded,
	Star,
	StarRounded,
	WaterDrop,
	WbSunnyOutlined,
	WbSunnyRounded
} from '@mui/icons-material';
import {
	AppDispatch,
	ReframedThought,
	RootState,
	Thought
} from '../../../Util/Redux/Schema';
import { ArcherContainer, ArcherElement } from 'react-archer';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Modal,
	Skeleton,
	Tooltip,
	Typography
} from '@mui/material';
import Breathe, { BreathingFinished } from '../../../Tools/Breathe/Main';
import { EmotionMenu, SageThoughtDropdown } from './EmotionSelect';
import {
	GradientSunIcon,
	HappyCloud,
	HappyCloudAnimated,
	LineMdPaintDropFilled,
	LineMdPaintDropFilledAnimated,
	LineMdSunnyFilled,
	LineMdSunnyFilledAnimated,
	LineMdSunnyFilledGreen,
	MeteoconsLightningBoltFill,
	MeteoconsLightningBoltFillAnimated,
	MeteoconsRainFill,
	MeteoconsRainFillAnimated,
	MingcuteBubbleFill,
	PhPlantFill,
	SadCloud,
	SadCloudAnimated
} from '../../../assets/svgFiles';
import React, { useEffect, useRef, useState } from 'react';
import {
	addThoughtSentiment,
	removeThoughtEmotions,
	selectThoughtEmotions
} from '../../../Util/Redux/Middleware';
import {
	formatTime,
	getCtaColor,
	getCtaHoverColor,
	getSentimentColor,
	getSentimentIcon,
	getTimeDifference
} from '../../../Util/Helper';
import {
	getSageThought,
	postEmotions
} from '../../../Util/Redux/AsyncMiddleware';

import BreathingModal from '../../../app/SageThatThought/Summary';
import SageWorksheet from '../../../app/SageThatThought/Worksheet';
import { Stack } from '@mui/system';
import { StyledMenu } from '../StyledDropdown';
import Xarrow from 'react-xarrows';
import addReaction from '../../../assets/images/svg-files/addReaction.svg';
import breathe from '../../../assets/images/breathe.svg';
import dropdown from '../../../assets/images/svg-files/dropdown.svg';
import { useDispatch } from 'react-redux';

const ThoughtEntryTemplate = (props: {
	icon: any;
	thought: string;
	header: any;
	body?: any;
}) => {
	return (
		// <ArcherElement
		// 	id="archerRoot"
		// 	relations={[
		// 		{
		// 			targetId: 'archerElemEnd',
		// 			targetAnchor: 'bottom',
		// 			sourceAnchor: 'top',
		// 			style: {
		// 				noCurves: true,
		// 				strokeColor: '#E6E6E6',
		// 				strokeWidth: 1.5,
		// 				lineStyle: 'straight'
		// 			}
		// 		}
		// 	]}
		// >
		<Grid
			container
			item
			xs
			direction="column"
			sx={{
				// backgroundColor: 'blue',
				marginTop: '16px',
				height: '100%',
				paddingLeft: '12px'
			}}
		>
			<Grid
				container
				item
				xs
				alignItems="flex-start"
				justifyContent="center"
				sx={{
					// marginBottom: '4px',
					// backgroundColor: 'red',
					width: '100%'
					// backgroundColor: 'blue'
				}}
				padding="4px 8px"
				// marginRight="4px"
			>
				<Grid
					container
					item
					xs={1}
					justifyContent="center"
					alignItems="flex-start"
					paddingTop="4px"
					// sx={{ backgroundColor: 'red' }}
				>
					{props.icon}
				</Grid>
				<Grid
					container
					item
					xs
					direction="column"
					alignItems="flex-start"
				>
					<Grid
						// sx={{ overflow: 'hidden' }}
						container
						item
						xs
						direction="column"
					>
						{props.header}
					</Grid>
					<Grid container item xs alignItems="center">
						<Typography
							sx={{
								cursor: 'default',
								fontFamily: 'arial',
								color: '#2c2c2c',
								fontSize: '14px',
								lineHeight: '20px',
								overflow: 'hidden'
							}}
						>
							{props.thought}
						</Typography>
					</Grid>
					<Grid
						container
						item
						xs
						// sx={{ bakcgroundColor: 'red' }}
						// justifyContent="flex-end"
					>
						{props.body}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		// </ArcherElement>
	);
};
const CloudEmotionSelect = (props: {
	setSelectedEmotions: (emotions: string[]) => void;
	thought: Thought;
	mutable: boolean;
	// boxRef: React.RefObject<HTMLDivElement>;
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		// setAnimate(true);
	};

	const [emotions, setEmotions] = useState(
		props.thought.userSelectedEmotions
	);
	const [animate, setAnimate] = useState(false);
	const handleClose = (emotions: string[]) => {
		if (emotions !== props.thought.userSelectedEmotions) {
			props.setSelectedEmotions(emotions);
			// Fix Me
			// setAnimate(true);
		}
		setAnchorEl(null);
	};

	const Icon = () =>
		props.thought.sentiment ? (
			props.thought.sentiment.toLowerCase() === 'positive' ? (
				<WbSunnyRounded
					sx={{ color: getSentimentColor('positive').color }}
				/>
			) : (
				// props.thought.sentiment?.toLowerCase() === 'negative' && (
				<ArcherElement id="archerElemEnd">
					<FlashOnRounded
						sx={{ color: getSentimentColor('negative').color }}
					/>
				</ArcherElement>
				// )
			)
		) : (
			<CircularProgress />
			// <Skeleton
			// 	style={{
			// 		borderRadius: '50%',
			// 		width: '24px',
			// 		height: '24px',
			// 		animation: 'wave'
			// 	}}
			// />
		);
	// (
	// 	animate ? (
	// 		<LineMdSunnyFilledAnimated />
	// 	) : (
	// 		<LineMdSunnyFilled />
	// 	)
	// ) : animate ? (
	// 	<MeteoconsLightningBoltFillAnimated />
	// ) : (
	// 	<MeteoconsLightningBoltFill />
	// );
	const isEmotionSelected =
		!open &&
		(emotions.length > 0 || !props.mutable) &&
		props.thought.sentiment !== null;
	return (
		<Grid
			container
			item
			xs={1}
			// alignItems="flex-start"
			// sx={{ backgroundColor: 'red' }
			width="48px"
			alignItems="center"
			justifyContent="center"
			sx={{
				'&:hover': {
					backgroundColor: 'transparent',
					cursor: isEmotionSelected ? 'default' : 'pointer'
				}
			}}
		>
			<Grid
				container
				item
				alignItems="center"
				// marginTop="4px"
				justifyContent="center"
				// ref={props.boxRef}
			>
				{isEmotionSelected ? (
					<Icon />
				) : (
					<Tooltip title="Add emotions" onClick={handleClick}>
						<Grid
							container
							item
							width="52px"
							// marginLeft="-8px"
							// sx={{ backgroundColor: 'red' }}
						>
							{/* <img src={dropdown} width="8px" /> */}
							<CloudRounded style={{ color: '#F2F1F2' }} />
							<ArrowDropDownRounded
								style={{ color: getCtaColor() }}
							/>
							{/* <AddRounded /> */}
						</Grid>
						{/* <img src={} */}
					</Tooltip>
				)}
				<StyledMenu
					anchorEl={anchorEl}
					open={open}
					onClose={() => handleClose(emotions)}
					sx={{
						maxHeight: '400px',

						width: '400px'
						// marginBottom: "48px",
					}}
				>
					<Grid
						container
						item
						xs
						direction="column"
						width="240px"
						// minHeight="210px"
						// marginBottom="48px"
						sx={{}}
					>
						<SageThoughtDropdown
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							emotions={emotions}
							setEmotions={setEmotions}
							options={props.thought.computedTopEmotions}
							thoughtUuid={props.thought.uuid}
							sentiment={props.thought.sentiment}
						/>
					</Grid>
				</StyledMenu>
			</Grid>
		</Grid>
	);
};
const MemoCloudEmotionSelect = React.memo(CloudEmotionSelect);

export default function ThoughtEntry(props: {
	thought: Thought;
	mutable?: boolean;
	dontShowReframed?: boolean;
	expandable?: boolean;
	showReframe: boolean;
}) {
	const defaultToTime = !props.mutable;
	const dispatch = useDispatch<AppDispatch>();
	const [showSageButton, setShowSageButton] = useState(
		props.mutable &&
			props.thought.sentiment?.toLowerCase() === 'negative' &&
			!props.thought.reframed
	);
	const setSelectedEmotions = async (emotions: string[]) => {
		const response = await postEmotions(props.thought.uuid, emotions);
		if (response !== null) {
			dispatch(
				selectThoughtEmotions(
					props.thought.uuid,
					emotions,
					response.data.sentiment
				)
			);
			setShowSageButton(
				response.data.sentiment?.toLowerCase() === 'negative'
			);
		}
	};
	const [expanded, setExpanded] = useState(false);
	const [hovered, setHovered] = useState(false);
	if (
		(props.thought.reframed || props.showReframe) &&
		props.expandable === undefined
		// &&
		// !props.mutable
	) {
		return (
			<>
				<Grid
					container
					onClick={() => setExpanded(!expanded)}
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					sx={
						{
							// backgroundColor: hovered ? '#F2F5F9' : undefined
						}
					}
					item
					xs
				>
					<Grid container>
						<ThoughtEntry
							thought={props.thought}
							mutable={props.mutable}
							showReframe={false}
							expandable={false}
						/>
					</Grid>
					<ReframedThoughtEntry
						defaultToTime={defaultToTime}
						reframedThought={{
							createdAt: props.thought.createdAt,
							thought:
								props.thought.reframed ||
								'Everything is great!',
							uuid: props.thought.uuid
						}}
						toggleExpanded={expanded}
						setToggleExpanded={setExpanded}
					/>
				</Grid>
			</>
		);
	}
	return (
		<ThoughtEntryTemplate
			icon={
				<MemoCloudEmotionSelect
					thought={props.thought}
					mutable={props.mutable || false}
					setSelectedEmotions={setSelectedEmotions}
					// boxRef={null}
				/>
			}
			header={
				<EmotionTimeTypography
					sentiment={props.thought.sentiment}
					selectedOptions={props.thought.userSelectedEmotions || []}
					defaultToTime={defaultToTime || false}
					computedTopEmotions={props.thought.computedTopEmotions}
					createdAt={props.thought.createdAt}
				/>
			}
			thought={props.thought.thought}
			body={
				showSageButton ? (
					<SageThoughtButton thought={props.thought} />
				) : undefined
			}
		/>
	);
}

export const ReframedThoughtEntry = (props: {
	reframedThought: ReframedThought;
	defaultToTime: boolean;
	toggleExpanded: boolean;
	setToggleExpanded: (toggle: boolean) => void;
	// boxRef: React.RefObject<HTMLDivElement>;
}) => {
	return (
		<ThoughtEntryTemplate
			thought={props.reframedThought.thought}
			icon={
				<ArcherElement
					id="archerRoot"
					relations={[
						{
							targetId: 'archerElemEnd',
							targetAnchor: 'bottom',
							sourceAnchor: 'top',
							style: {
								noCurves: true,
								strokeColor: '#E6E6E6',
								strokeWidth: 1.5,
								lineStyle: 'straight'
							}
						}
					]}
				>
					<StarRounded
						sx={{
							// width: '24px',
							// height: '24px',
							color: '#A9D4C0'
							// color: getCtaColor()
							// color: getSentimentColor('positive').color
						}}
					/>
				</ArcherElement>
			}
			// body={
			// 	<Grid container item xs justifyContent="flex-end">
			// 		{' '}
			// 		<ChevronLeft
			// 			sx={{ marginRight: '8px', transform: 'rotate(-90deg)' }}
			// 		/>
			// 	</Grid>
			// }
			header={
				<Grid
					container
					item
					xs
					alignItems="center"
					width="100%"
					sx={{ cursor: 'default' }}
				>
					{/* <TimeTypography
						defaultToTime={props.defaultToTime}
						createdAt={props.reframedThought.createdAt}
					/>
					<Circle
						sx={{
							paddingLeft: '8px',
							paddingRight: '8px',
							height: '4px',
							width: '4px',
							color: '#A8A8A8'
						}}
					/> */}
					<Grid
						container
						item
						// xs
						// justifyContent="center"
						width="210px"
						borderRadius="8px"
						// sx={{ backgroundColor: 'red' }}
					>
						<Typography
							sx={{
								fontSize: '12px',
								lineHeight: '18px',
								color: getCtaColor(),
								// paddingLeft: '4px',
								fontWeight: 600
								// textDecoration: 'underline'
								// color: "#A"
							}}
						>
							View Saged Thought Summary +
						</Typography>
					</Grid>
				</Grid>
			}
		/>
	);
};

const SageThoughtButton = (props: { thought: Thought }) => {
	const [openSageWorksheet, setOpenSageWorksheet] = useState(false);
	const [openBreathingOption, setOpenBreathingOption] = useState(false);
	const [openBreathingExercise, setOpenBreathingExercise] = useState(false);
	const [openBreathingFinishedExercise, setOpenBreathingFinishedExercise] =
		useState(false);
	const [numberOfBreaths, setNumberOfBreaths] = useState<number | null>(null);
	const dispatch = useDispatch<AppDispatch>();
	const onClickSageThatButton = async () => {
		dispatch(getSageThought(props.thought.uuid));
		setOpenSageWorksheet(true);
	};
	return (
		<Tooltip title="Click to sage your negative thought">
			<Grid
				container
				item
				// xs
				// height="28px"
				// alignItems="center"
				marginTop="8px"
				// justifyContent="center"
				sx={{
					backgroundColor: '#ECE7F9',
					// backgroundImage:
					// 	'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)',
					cursor: 'pointer'
				}}
				width="96px"
				padding="8px"
				borderRadius="20px"
			>
				<Button
					fullWidth
					onClick={onClickSageThatButton}
					disableRipple
					sx={{
						'&:hover': {
							backgroundColor: 'transparent',
							cursor: 'pointer'
						},
						backgroundColor: 'transparent',
						cursor: 'pointer',
						padding: '0',
						borderRadius: '24px',
						textTransform: 'none'
					}}
				>
					<Typography
						sx={{
							color: getCtaColor(),
							cursor: 'default',
							fontFamily: 'arial',
							fontSize: '12px',
							fontWeight: 600,
							lineHeight: '20px',
							cursor: 'pointer'
						}}
					>
						+ Introspect
					</Typography>
				</Button>
				{openSageWorksheet && (
					<SageWorksheet
						open={openSageWorksheet}
						setOpen={setOpenSageWorksheet}
						setCompleted={setOpenBreathingOption}
						setNumberOfBreaths={setNumberOfBreaths}
						thought={props.thought}
					/>
				)}

				{/* <BreathingModal
          open={openBreathingOption}
          setOpen={setOpenBreathingOption}
          setCompleted={setOpenBreathingExercise}
          setNumberOfBreaths={setNumberOfBreaths}
        /> */}
				<BreathingExerciseModal
					open={openBreathingExercise}
					setOpenBreathingExercise={setOpenBreathingExercise}
					setCompleted={setOpenBreathingFinishedExercise}
					numberOfBreaths={numberOfBreaths ?? 0}
				/>

				<BreathingFinishedModal
					open={openBreathingFinishedExercise}
					setOpen={setOpenBreathingFinishedExercise}
					numberOfBreaths={numberOfBreaths ?? 0}
				/>
				{/* <BreathingExerciseModal
				open={showCompleted}
				setOpen={setNumberOfBreaths}
				numberOfBreaths={numberOfBreaths ?? 0}
			/> */}
			</Grid>
		</Tooltip>
	);
};

const SageThought = () => {
	const [openBreathingOption, setOpenBreathingOption] = useState(true);
	const [openBreathingExercise, setOpenBreathingExercise] = useState(false);
	const [openBreathingFinishedExercise, setOpenBreathingFinishedExercise] =
		useState(false);
	const [numberOfBreaths, setNumberOfBreaths] = useState<number | null>(null);
	return (
		<Grid container item xs height="40px" alignItems="center">
			{/* Congratulations, you've successfully completed the first step of SAGE by sensing how you're feeling */}
			<SageWorksheet
				open={openBreathingOption}
				setOpen={setOpenBreathingOption}
				setOpenExercise={setOpenBreathingExercise}
				setNumberOfBreaths={setNumberOfBreaths}
			/>
			<BreathingExerciseModal
				open={openBreathingExercise}
				setOpenBreathingExercise={setOpenBreathingExercise}
				setOpenBreathingFinished={setOpenBreathingFinishedExercise}
				numberOfBreaths={numberOfBreaths ?? 0}
			/>

			<BreathingFinishedModal
				open={openBreathingFinishedExercise}
				setOpen={setOpenBreathingFinishedExercise}
				numberOfBreaths={numberOfBreaths ?? 0}
			/>
			{/* <BreathingExerciseModal
				open={showCompleted}
				setOpen={setNumberOfBreaths}
				numberOfBreaths={numberOfBreaths ?? 0}
			/> */}
		</Grid>
	);
};

const BreathingFinishedModal = (props: {
	open: boolean;
	setOpen: (value: boolean) => void;
	numberOfBreaths: number;
}) => {
	return (
		<Modal
			open={props.open}
			onClose={() => props.setOpen(false)}
			sx={{ zIndex: 2000, backdropFilter: 'blur(5px)' }}
		>
			<Grid
				style={{
					padding: '42px 64px',
					minWidth: '712px',
					minHeight: '558px',
					width: '30%',
					height: '50%',
					borderRadius: '16px',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor: 'white'
				}}
				container
				item
				xs
				alignItems="center"
				justifyContent="center"
			>
				<BreathingFinished numberOfBreaths={props.numberOfBreaths} />
			</Grid>
		</Modal>
	);
};
const BreathingExerciseModal = (props: {
	open: boolean;
	setOpenBreathingExercise: (value: boolean) => void;
	setCompleted: (value: boolean) => void;
	numberOfBreaths: number;
}) => {
	return (
		<Modal
			open={props.open}
			onClose={() => props.setOpenBreathingExercise(false)}
			sx={{ zIndex: 2000, backdropFilter: 'blur(5px)' }}
		>
			<Grid
				style={{
					padding: '120px',
					width: '90%',
					height: '90%',
					borderRadius: '16px',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor: 'white'
				}}
				container
				item
				xs
				alignItems="center"
				justifyContent="center"
			>
				<Breathe
					numberOfBreaths={props.numberOfBreaths}
					setCompleted={props.setCompleted}
					setOpenBreathingExercise={props.setOpenBreathingExercise}
				/>
			</Grid>
		</Modal>
	);
};

const EmotionTimeTypography = (props: {
	computedTopEmotions: string[];
	selectedOptions: string[];
	createdAt: string;
	defaultToTime: boolean;
	sentiment: string;
}) => {
	return (
		<Grid
			container
			item
			xs
			alignItems="center"
			width="100%"
			sx={{ cursor: 'default' }}
		>
			<TimeTypography
				defaultToTime={props.defaultToTime}
				createdAt={props.createdAt}
			/>
			{/* <Circle
				sx={{
					paddingLeft: '4px',
					paddingRight: '4px',
					height: '4px',
					width: '4px',
					color: '#A8A8A8'
				}}
			/>
			<Typography
				sx={{
					fontSize: '12px',
					lineHeight: '18px',
					color: getSentimentColor(props.sentiment).color,
					// paddingLeft: '4px',
					fontWeight: 600
					// color: "#A"
				}}
			>
				{props.sentiment?.toLowerCase() === 'negative'
					? 'Harsh Thought'
					: 'Joyful Thought'}
			</Typography> */}

			{props.selectedOptions.length > 0 && (
				<Circle
					sx={{
						paddingLeft: '4px',
						paddingRight: '4px',
						height: '4px',
						width: '4px',
						color: '#A8A8A8'
					}}
				/>
			)}

			{props.selectedOptions.length > 0 && (
				<Typography
					sx={{
						fontSize: '12px',
						lineHeight: '18px',
						color: '#ADACB0',
						paddingLeft: '4px'
					}}
				>
					{props.selectedOptions.join(', ')}
				</Typography>
			)}
		</Grid>
	);
};

const TimeTypography = (props: {
	defaultToTime: boolean;
	createdAt: string;
}) => {
	const [showFormattedTimeView, setShowFormattedTimeView] = useState(false);
	const nonDefaultTimeFn = props.defaultToTime
		? getTimeDifference
		: formatTime;
	const defaultTimeFn = props.defaultToTime ? formatTime : getTimeDifference;

	return (
		<Typography
			onMouseEnter={() => {
				!props.defaultToTime && setShowFormattedTimeView(true);
			}}
			onMouseLeave={() => {
				!props.defaultToTime && setShowFormattedTimeView(false);
			}}
			sx={{
				// cursor: "default",
				fontFamily: 'arial',
				color: '#A8A8A8',
				fontSize: '12px',
				lineHeight: '20px',
				paddingRight: '8px'
			}}
		>
			{(showFormattedTimeView ? nonDefaultTimeFn : defaultTimeFn)(
				props.createdAt
			)}
		</Typography>
	);
};
