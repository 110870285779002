import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { createStyles, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Button, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Stop } from "@mui/icons-material";
import { getCtaColor, getCtaHoverColor } from "../../../Util/Helper";
import breatheCloud from "../../../assets/images/svg-files/breatheCloud.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circle: {
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: "#dbeee8",
      display: "flex",
    },
    centerCircle: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      position: "absolute",
      backgroundColor: "#A6D5C5",
      zIndex: 1,
    },
    animatedCircle: {
      backgroundColor: "#c6e4da",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      position: "relative",
    },

    inhale: {
      animation: "$inhaleAnimation 4s ease-in-out forwards", // Adjust the duration to 6 seconds
      animationPlayState: (props: { isPlaying: boolean }) =>
        props.isPlaying ? "running" : "paused",
      // '&:hover': {
      // 	animationPlayState: 'running'
      // }
    },
    exhale: {
      animation: "$exhaleAnimation 4s ease-in-out forwards",
      animationPlayState: (props: { isPlaying: boolean }) =>
        props.isPlaying ? "running" : "paused",
      // animationPlayState: 'paused',
      // '&:hover': {
      // 	animationPlayState: 'running'
      // }
    },

    "@keyframes inhaleAnimation": {
      "0%": {
        transform: "scale(.5)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
    "@keyframes exhaleAnimation": {
      "0%": {
        transform: "scale(1)",
      },
      "100%": {
        transform: "scale(.5)",
      },
    },
  })
);

export const BreathingCircle = () => {
  const [isHovering, setIsHovering] = useState(false);
  const classes = useStyles({ isPlaying: isHovering });
  const [circleState, setCircleState] = useState<"inhale" | "exhale">("inhale");
  const [counter, setCounter] = useState(4);

  useEffect(() => {
    if (counter > 0) {
      const intervalId = setInterval(() => {
        if (isHovering) {
          setCounter((prevCounter) => prevCounter - 1);
        }
      }, 1000);

      // Clear the interval when the component is unmounted or when counter reaches 0
      return () => clearInterval(intervalId);
    } else {
      setTimeout(() => {
        setCircleState((prev) => {
          // setCounter(4);
          return prev === "inhale" ? "exhale" : "inhale";
        });
        setCounter(4);
      }, 80);
    }
  }, [counter, setCircleState, isHovering]);
  return (
    <Grid
      container
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        backgroundColor: "white",
        position: "absolute",
        right: "24px",
        bottom: "400px",
        width: "224px",
        transform: "rotate(12deg)",
        padding: "24px",
        borderRadius: "8px",
        border: "1.4px solid #F1F1F1",
        boxShadow: `0px 2px 12px -1px rgba(10, 9, 11, 0.1), 0px 2px 2px -1px rgba(10, 9, 11, 0.04), 0px 0px 0px 1px rgba(10, 9, 11, 0.05)`,
        "&:hover": {
          boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
        },
      }}
    >
      <Grid
        container
        item
        // xs
        justifyContent="center"
        alignItems="center"
        height="150px"
      >
        <Paper
          className={classes.circle}
          sx={{
            width: "150px",
            height: "150px",
            borderRadius: "50%",
          }}
        />
        <Paper
          className={classes.centerCircle}
          sx={{
            width: "75px",
            height: "75px",
            borderRadius: "50%",
          }}
        >
          {counter > 0 && counter < 5 && (
            <Typography
              sx={{
                color: "#4F4D55",
                fontSize: "12px",
                fontWeight: 500,
                textAlign: "center",
                lineHeight: "72px",
              }}
            >
              {circleState}
            </Typography>
          )}
        </Paper>
        {circleState === "inhale" && (
          <Paper
            className={`${classes.animatedCircle} ${classes.inhale}`}
            sx={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              animationPlayState: isHovering ? "running" : "paused",
            }}
          />
        )}

        {circleState === "exhale" && (
          <Paper
            // elevation={3}
            className={`${classes.animatedCircle} ${classes.exhale}`}
            sx={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              animationPlayState: isHovering ? "running" : "paused",
            }}
          />
        )}
        {/* {circleState === 'hold' && (
					<Paper
						// elevation={3}
						className={`${classes.animatedCircle} ${isInhale
							? classes.inhaledState
							: classes.exhaledState
							}`}
						sx={{
							width: '150px',
							height: '150px',
							borderRadius: '50%',
							animationPlayState: isHovering ? 'running' : 'paused'
						}}
					/>
				)} */}
      </Grid>
    </Grid>
  );
};

export default BreathingCircle;
