import { AppDispatch, RootState } from '../../../../Util/Redux/Schema';
import {
	Box,
	Button,
	Divider,
	Fade,
	Grid,
	IconButton,
	IconButtonClassKey,
	LinearProgressProps,
	Typography
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress';
import { PlayArrow, Replay, Start } from '@mui/icons-material';
import { Theme, createStyles } from '@mui/material/styles';
import { getCtaColor, getSentimentColor } from '../../../../Util/Helper';
import { useDispatch, useSelector } from 'react-redux';

import NotebookLayout from '../../../templates/NotebookTemplate';
import { makeStyles } from '@mui/styles';
import meditateWithClouds from '../../../../assets/images/svg-files/meditateWithClouds.svg';
import { styled } from '@mui/material/styles';
import { updateGoldenHourCompletion } from '../../../../Util/Redux/AsyncMiddleware';
import { updateMeditationTimeRemaining } from '../../../../Util/Redux/Middleware';

export default function Breathwork(props: {
	progressCount: number;
	setProgressCount: (count: number) => void;
	maxCount?: number;
	completed: boolean;
	size: string;
	// updateProgressCount
}) {
	return (
		<Grid
			container
			item
			direction="column"
			// sx={{ backgroundColor: '#F8F8F8', borderRadius: '8px' }}
		>
			{/* <Typography
				sx={{
					cursor: 'default',
					fontFamily: 'arial',
					fontSize: '12px',
					lineHeight: '24px',
					textAlign: 'center',
					marginTop: '16px',
					marginBottom: '-8px',
					// fontWeight: 550,
					marginLeft: '-8px',
					color: '#345247'
				}}
			>
				Round 1 of 8
			</Typography> */}
			<BreathingCircle
				progressCount={props.progressCount}
				setProgressCount={props.setProgressCount}
				maxCount={props.maxCount}
				size={props.size}
			/>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		circle: {
			alignItems: 'center',
			justifyContent: 'center',
			position: 'absolute',
			backgroundColor: '#F2F6FF',
			// getSentimentColor('reframed').backgroundColor,
			display: 'flex'
		},
		centerCircle: {
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
			position: 'absolute',
			backgroundColor: '#C4DEFF',
			// getSentimentColor('reframed').color,
			zIndex: 1
		},
		animatedCircle: {
			backgroundColor: '#D6E5FE',
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},

		inhale: {
			animation: '$inhaleAnimation 5s ease-in-out forwards', // Adjust the duration to 6 seconds
			animationPlayState: (props: { isPlaying: boolean }) =>
				props.isPlaying ? 'running' : 'paused'
			// '&:hover': {
			// 	animationPlayState: 'running'
			// }
		},
		exhale: {
			animation: '$exhaleAnimation 5s ease-in-out forwards',
			animationPlayState: (props: { isPlaying: boolean }) =>
				props.isPlaying ? 'running' : 'paused'
			// animationPlayState: 'paused',
			// '&:hover': {
			// 	animationPlayState: 'running'
			// }
		},

		'@keyframes inhaleAnimation': {
			'0%': {
				transform: 'scale(.5)'
			},
			'100%': {
				transform: 'scale(1)'
			}
		},
		'@keyframes exhaleAnimation': {
			'0%': {
				transform: 'scale(1)'
			},
			'100%': {
				transform: 'scale(.5)'
			}
		}
	})
);

export const BreathingCircle = (props: {
	progressCount: number;
	setProgressCount: Dispatch<SetStateAction<number>>;
	maxCount: number;
	size: string;
}) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const classes = useStyles({ isPlaying: isPlaying });
	const [circleState, setCircleState] = useState<'inhale' | 'exhale'>(
		'inhale'
	);
	const [counter, setCounter] = useState(5);
	const { progressCount, setProgressCount, maxCount, size } = props;
	const [complete, setComplete] = useState(false);
	const dispatch = useDispatch<AppDispatch>();
	const isSpiritCompleted = useSelector(
		(state: RootState) => state.wellbeing.spirit.completed
	);

	useEffect(() => {
		if (counter === null) {
			return;
		}
		if (counter > 0) {
			const intervalId = setInterval(() => {
				setCounter((prevCounter) => prevCounter + 1);
			}, 1000);

			// Clear the interval when the component is unmounted or when counter reaches 0
			return () => clearInterval(intervalId);
		} else {
			setTimeout(() => {
				if (!isPlaying) {
					return;
				}
				setCircleState((prev) => {
					// setCounter(4);
					return prev === 'inhale' ? 'exhale' : 'inhale';
				});
				setVisible((prev) => !prev);
				setCounter(1);
				if (circleState === 'exhale') {
					setProgressCount(progressCount + 1);
					if (progressCount === (props.maxCount || 8)) {
						setComplete(true);
						setIsPlaying(false);
						setProgressCount(null);

						if (!isSpiritCompleted)
							dispatch(
								updateGoldenHourCompletion('Spirit', true)
							);
					}
				}
			}, 100);
		}
	}, [counter, setCircleState]);
	const [visible, setVisible] = useState(false);
	const user = sessionStorage.getItem('username');
	console.log('HEY');
	if (complete) {
		return (
			<Grid
				container
				item
				xs
				justifyContent="center"
				alignItems="center"
				direction="column"
				sx={{
					borderRadius: '8px'
				}}
			>
				{/* {console.log('HI')} */}

				{/* <Typography
					sx={{
						cursor: 'default',
						fontFamily: 'arial',
						fontSize: '12px',
						lineHeight: '24px',
						textAlign: 'center',
						marginTop: '16px',
						marginBottom: '-8px',
						marginLeft: '-8px',
						color: '#345247'
					}}
				>
					Well done
				</Typography> */}
				<img src={meditateWithClouds} width="200px" />

				{/* <ActionButton text="Start again" endIcon={<Replay />} /> */}
			</Grid>
		);
	}

	const onStart = () => {
		setIsPlaying(true);
		setProgressCount(1);
		setCounter(1);
	};

	return (
		<Grid
			container
			item
			xs
			justifyContent="center"
			direction="column"
			alignItems="center"
			sx={{
				borderRadius: '8px'
			}}
		>
			{/* <Typography
				sx={{
					cursor: 'default',
					fontFamily: 'arial',
					fontSize: '12px',
					lineHeight: '24px',
					textAlign: 'center',
					marginTop: '16px',
					marginBottom: '-8px',
					// fontWeight: 550,
					marginLeft: '-8px',
					color: '#345247'
				}}
			>
				{`Round ${props.progressCount} of ${props.maxCount || 8}`}
			</Typography> */}
			<Grid
				container
				item
				xs
				justifyContent="center"
				alignItems="center"
				// height="140px"
				sx={{ position: 'relative' }}
			>
				<Button
					disableRipple
					fullWidth
					onClick={onStart}
					sx={{
						height: props.size === 'large' ? '360px' : '190px',

						width: props.size === 'large' ? '360px' : '190px',
						borderRadius: '50%',
						'&:hover': {
							// backgroundColor: isPlaying ? "white" : "#73A89919",
							backgroundColor: 'transparent'
						}
					}}
				>
					<Grid
						className={classes.circle}
						sx={{
							height: props.size === 'large' ? '290px' : '140px',
							width: props.size === 'large' ? '290px' : '140px',
							// height: '150px',
							borderRadius: '50%'
						}}
					/>
					<Grid
						className={classes.centerCircle}
						sx={{
							width: '75px',
							height: '75px',
							borderRadius: '50%'
						}}
					>
						{isPlaying === false && (
							<Typography
								sx={{
									color: 'white',
									fontSize: '12px',
									fontWeight: 800,
									textAlign: 'center',
									lineHeight: '72px'
								}}
							>
								{isPlaying === false ? 'breathe' : circleState}
							</Typography>
						)}
						{isPlaying === true && counter > 0 && counter < 6 && (
							<Fade in={true} timeout={3000}>
								<Typography
									sx={{
										color: 'white',
										fontSize: '12px',
										fontWeight: 500,
										textAlign: 'center',
										lineHeight: '72px'
									}}
								>
									{circleState}
								</Typography>
							</Fade>
						)}
					</Grid>
					{circleState === 'inhale' && (
						<Grid
							className={`${classes.animatedCircle} ${classes.inhale}`}
							sx={{
								// width: '150px',\
								width:
									props.size === 'large' ? '290px' : '140px',
								height:
									props.size === 'large' ? '290px' : '140px',
								borderRadius: '50%',
								animationPlayState: isPlaying
									? 'running'
									: 'paused'
							}}
						/>
					)}

					{circleState === 'exhale' && (
						<Grid
							// elevation={3}
							className={`${classes.animatedCircle} ${classes.exhale}`}
							sx={{
								width:
									props.size === 'large' ? '290px' : '140px',
								height:
									props.size === 'large' ? '290px' : '140px',
								borderRadius: '50%',
								animationPlayState: isPlaying
									? 'running'
									: 'paused'
							}}
						/>
					)}
					{/* {circleState === 'hold' && (
					<Grid
						// elevation={3}
						className={`${classes.animatedCircle} ${isInhale
							? classes.inhaledState
							: classes.exhaledState
							}`}
						sx={{
							width: '150px',
							height: '150px',
							borderRadius: '50%',
							animationPlayState: isHovering ? 'running' : 'paused'
						}}
					/>
				)} */}
				</Button>
			</Grid>
			{/* <Grid container marginTop="16px" justifyContent="center" alignItems="center">
                {
                    isPlaying ? <Typography sx={{ fontSize: '16px', fontFamily: 'arial', color: "#2c2c2c" }}>Round {progressCount} of 10</Typography> :
                        <ActionButton text="Start" endIcon={<PlayArrow />} onClick={() => setIsPlaying(true)} />
                }
            </Grid> */}
		</Grid>
	);
};

export const ActionButton = (props: {
	text: string;
	onClick: () => any;
	endIcon?: Element;
}) => {
	return (
		// <IconButton onClick={props.onClick}>
		//     {props.endIcon}
		// </IconButton>
		// <Box
		//     sx={{
		//         display: 'inline-block',
		//         padding: '8px', // Adjust padding to ensure inner content doesn't overlap the border
		//         background: 'linear-gradient(45deg, #D0C2F1, #A9D4C0, #FDA7A6)',
		//         borderRadius: '28px', // Adjust border radius as neededm=
		//         // height: '32px'
		//         width: '64px'
		//     }}
		// >
		//     <Box
		//         sx={{
		//             padding: '8px',
		//             background: '#fff', // Background of the inner content
		//             borderRadius: '20px', // Inner border radius
		//             display: 'flex',
		//             justifyContent: 'center',
		//             alignItems: 'center',
		//             backgroundClip: 'padding-box', // Ensures inner box padding is correctly clipped
		//             height: '20px'
		//         }}
		//     >
		//         <Typography sx={{ fontSize: '16px', fontFamily: 'arial' }}>{props.text}</Typography>
		//         {/* {children} */}
		//     </Box>
		// </Box>
		<Button
			onClick={props.onClick}
			style={{
				// backgroundImage:
				//     'linear-gradient(-90deg, #D0C2F1, #A9D4C0, #FDA7A6)',
				height: '32px',
				textTransform: 'none',
				backgroundColor: getCtaColor(),
				borderRadius: '16px'
			}}
			variant="contained"
			endIcon={props.endIcon}
		>
			<Typography>{props.text}</Typography>
		</Button>
	);
};
