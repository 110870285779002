import Grid from '@mui/material/Grid';
import inhale from '../../../assets/images/inhale.png';
import hold from '../../../assets/images/hold.png';
import exhale from '../../../assets/images/exhale.png';
import Instruction from './Instruction';
import Preset from './Preset';

const Instructions = () => {
	return (
		<Grid container item xs justifyContent={'center'} alignItems={'center'}>
			<Instruction
				stepNumber={1}
				title="Inhale"
				description="through your nose for 4 seconds."
				imgSrc={inhale}
				currentStepNumber={1}
			/>
			<Instruction
				stepNumber={2}
				title="Hold"
				description="your breath for 7 seconds."
				imgSrc={hold}
			/>
			<Instruction
				stepNumber={3}
				title="Exhale"
				description="completely through your mouth for 8 seconds."
				imgSrc={exhale}
			/>
		</Grid>
	);
};

const FourSevenEight = (props: { setActivity: (x: string) => void }) => {
	return (
		<Preset
			setActivity={props.setActivity}
			title={'4-7-8 Breathing'}
			description="The 4-7-8 breathing technique offers rapid stress
	reduction and anxiety management by promoting a
	deliberate, rhythmic pattern of breath, triggering the
	body's relaxation response. Its extended exhalation is
	particularly effective for inducing calmness, making it
	a valuable tool for quick relaxation and improved sleep
	quality."
			instructions={<Instructions />}
		/>
	);
};

export default FourSevenEight;
