import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import { Box, Grid, Typography } from '@mui/material';
import { Subtitle, Title } from '../Util/Components/Typography';
import { ArrowDropDown } from '@mui/icons-material';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center'
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === 'light'
				? 'rgb(55, 65, 81)'
				: theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0'
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5)
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				)
			}
		}
	}
}));

export default function DropdownMenu(props: {
	menu: string[];
	selectedMenu: string;
	setSelectedMenu: (x: string) => void;
}) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (idx?: number) => {
		if (idx !== undefined) {
			props.setSelectedMenu(props.menu[idx]);
		}
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				disableElevation
				disableRipple
				sx={{
					textTransform: 'none',
					width: "108px",
					backgroundColor: '#EFF0F2',
					'&:hover': { backgroundColor: '#EFF0F2' }
				}}
				onClick={handleClick}
				endIcon={<ArrowDropDown sx={{ color: "#4F4D55" }} />}
			>
				<Typography sx={{ color: "#4F4D55", fontSize: '14px', fontWeight: 450, lineHeight: '20px' }}>{props.selectedMenu}</Typography>
			</Button>
			<StyledMenu
				anchorEl={anchorEl}
				open={open}
				onClose={() => handleClose()}
			>
				{props.menu.map((name, idx) => (
					<MenuItem onClick={() => handleClose(idx)} disableRipple>
						{name}
					</MenuItem>
				))}
			</StyledMenu>
		</>
	);
}
