import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import sageLogo from "../../assets/images/sageLogo.png";
import { Alert, Button, TextField, Typography } from "@mui/material";
import { LoginText } from "../../Util/Components/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormControl } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { RootState } from "../../Util/Redux/Schema";
import { StaticDatePicker } from "@mui/lab";
import axios from "axios";
import { ButtonPrimary, ButtonSecondary } from "../../Util/Components/Button";
import { useAuth0 } from "@auth0/auth0-react";
import sageCircle from "../../assets/images/logo/sageCircle.svg";
import { fetchUser, setAuthenticated } from "../../Util/Redux/Middleware";

export function LoginSignUpButton(props: {
  text: string;
  marginLeft?: string;
  marginBottom?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      fullWidth={props.fullWidth}
      onClick={() => props.onClick && props.onClick()}
      sx={{
        borderRadius: "140px",
        marginLeft: props.marginLeft,
        marginBottom: props.marginBottom,
        width: "280px",
        height: "88px",
        "&:hover": {
          backgroundImage: "linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)",
        },
        // backgroundColor: getCtaColor(),
        backgroundImage: "linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)",
        textTransform: "none",
      }}
    >
      <Typography
        sx={{
          fontSize: "20px",
          lineHeight: "20px",
          fontWeight: 600,
          color: "#333333",
          letterSpacing: "-0.05px",
          fontFamily: "arial",
        }}
      >
        {props.text}
      </Typography>
    </Button>
  );
}
function Waitlist() {
  return (
    <Grid
      container
      item
      xs
      justifyContent="center"
      alignItems="center"
      height="100vh"
      direction="column"
    >
      <Grid
        container
        item
        xs={11}
        justifyContent="center"
        alignItems="center"
        direction="column"
        width="400px"
        height="452px"
      >
        <Grid container item justifyContent="center" marginBottom="24px">
          <img src={sageCircle} style={{ width: "76.22", height: "64px" }} />
          <img
            src={sageLogo}
            style={{
              marginLeft: "20px",
              width: "76.22",
              height: "64px",
            }}
          />
        </Grid>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "12px",
            lineHeight: "40px",
            letterSpacing: "-3%",
            marginBottom: "48px",
            marginTop: "24px",
            textAlign: "center",
            color: "#2c2c2c",
          }}
        >
          Thank you for your interest! <br /> We've added you to our waitlist.
        </Typography>
        {/* <LoginSignUpButton
					text="Log in or Sign up"
					onClick={handleLogin}
				/> */}
      </Grid>

      {/* <Grid
				container
				item
				xs
				justifyContent="space-between"
				alignItems="flex-end"
				padding="24px"
			>
				<Typography
					sx={{
						fontSize: '14px',
						color: '#4F4D55',
						fontWeight: 400,
						lineHeight: '20px',
						letterSpacing: '-0.05px'
					}}
				>
					{' '}
					©2024 Sage{' '}
				</Typography>
				<Typography
					sx={{
						fontSize: '14px',
						color: '#4F4D55',
						fontWeight: 400,
						lineHeight: '20px',
						letterSpacing: '-0.05px'
					}}
				>
					{' '}
					Privacy Policy{' '}
				</Typography>
			</Grid> */}
    </Grid>
  );
}

export default Waitlist;
