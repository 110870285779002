import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState, Thought } from '../../../../Util/Redux/Schema';
import {
    InsightsCard,
    distributePercentage,
    getSentimentColor
} from '../../../../Util/Helper';
import { Cloud } from '@mui/icons-material';
import SageCircle from '../SageCircles';
import TotalThoughtsCard from './TotalThoughtsCard';
import DominantEmotionsCard from './DominantEmotionsCard';
import ThoughtsRatioCard from './ThoughtsRatioCard';
import CheckBackInCard from './CheckBackIn';
import ThoughtsTab from '../ThoughtsTab/ThoughtsTab';

export default function WeeklySummary() {
    return (
        <Grid
            container
            item
            justifyContent="center"
            sx={{
                height: '100%',
                // maxHeight: '100%',
                borderRadius: '12px',
                border: '1px solid #F3F5FA',
                padding: '8px'
            }}
        >

            <Grid container item xs >
                <CheckBackInCard />
                {/* <ThoughtsTab view="a" /> */}
                {/* <PositivitySageCircles view={props.view} /> */}
            </Grid>
        </Grid>
    );
}
