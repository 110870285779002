import Grid from '@mui/material/Grid';
import inhale from '../../../assets/images/inhale.png';
import hold from '../../../assets/images/hold.png';
import exhale from '../../../assets/images/exhale.png';
import Instruction from './Instruction';
import Preset from './Preset';

const Instructions = () => {
	return (
		<Grid
			container
			item
			xs
			justifyContent={'center'}
			alignItems={'center'}
			direction="column"
		>
			<Grid
				container
				item
				xs
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Instruction
					stepNumber={1}
					title="Inhale"
					description="through your nose for 4 seconds."
					imgSrc={inhale}
					currentStepNumber={1}
				/>
				<Instruction
					stepNumber={2}
					title="Hold"
					description="your breath for 4 seconds."
					imgSrc={hold}
				/>
			</Grid>
			<Grid
				container
				item
				xs
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Instruction
					stepNumber={3}
					title="Exhale"
					description="through your mouth for 4 seconds."
					imgSrc={exhale}
				/>
				<Instruction
					stepNumber={4}
					title="Hold"
					description="your breath for 4 seconds."
					imgSrc={hold}
				/>
			</Grid>
		</Grid>
	);
};

const BoxBreathing = (props: { setActivity: (x: string) => void }) => {
	return (
		<Preset
			setActivity={props.setActivity}
			title={'Box Breathing'}
			description="The box breathing technique promotes lowered heart rates
			and heightened mental clarity by balancing the nervous
			system. Its structured inhale, hold, exhale, and pause
			pattern makes it a versatile and accessible practice for
			overall well-being."
			instructions={<Instructions />}
		/>
	);
};

export default BoxBreathing;
