import '../App.css';

import { AppDispatch, RootState } from '../Util/Redux/Schema';
import {
	Link,
	Navigate,
	Route,
	BrowserRouter as Router,
	Routes,
	useLocation,
	useNavigate,
	useSearchParams
} from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
	authenticateUser,
	setBearerTokenToAxios
} from '../Util/Redux/AsyncMiddleware';
// import Login from './Login2';
import store, { persistor } from '../Util/Redux/Store';

import AccountSettingsPage from '../components/pages/AccountSettingsPage';
import BreathePage from '../routes/Breathe';
import Dashboard from '../components/pages/Dashboard';
import InsightsPage from '../components/pages/InsightsPage/InsightsPage';
import LandingPage from '../components/pages/LandingPage/LandingPage';
import LockScreen from '../LockScreen';
import MeditatePage from '../routes/Meditate';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import Relaxpage from '../routes/Relax';
import Thoughtpage from '../components/pages/Vault/VaultsPage';
import TimerPage from '../routes/Timer';
import Waitlist from '../components/pages/WaitlistPage';
import WordCloud from '../Stats/WordCloud';
import axios from 'axios';
import { dispatch } from 'd3';
// import LawOfAttractionPage from '../pages/LawOfAttraction';
import { fetchUser } from '../Util/Redux/Middleware';
import { useAuth0 } from '@auth0/auth0-react';
// import { setBearerToken } from '../Util/Redux/AsyncMiddleware';

const AppRoutes = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { getAccessTokenSilently, isAuthenticated, user, isLoading, logout } =
		useAuth0();
	if (sessionStorage.getItem('isAuthenticated') !== 'True') {
		sessionStorage.setItem(
			'isAuthenticated',
			isAuthenticated ? 'True' : 'False'
		);
	}
	// logout();

	let authorized = sessionStorage.getItem('IsPinAuthorized') === 'True';
	useEffect(() => {
		const handleAuthenticatedUser = async () => {
			if (sessionStorage.getItem('bearerToken')) {
				setBearerTokenToAxios();
			}
			{
				try {
					if (isAuthenticated && user) {
						dispatch(fetchUser({ firstName: user.given_name }));
						sessionStorage.setItem(
							'username',
							user.given_name || ''
						);
						sessionStorage.setItem('userPic', user.picture || '');
						sessionStorage.setItem('isAuthenticated', 'True');
						const token = await getAccessTokenSilently();
						sessionStorage.setItem('bearerToken', token);
						setBearerTokenToAxios();
						// if (authenticated === null) {
						// 	dispatch(authenticateUser());
						// }
					}
				} catch (error) {
					console.error('Error handling authenticated user:', error);
				}
			}
		};
		handleAuthenticatedUser();
	}, [isAuthenticated, user, getAccessTokenSilently, dispatch]);
	if (isLoading && sessionStorage.getItem('username') === null) {
		return <div>Loading...</div>;
	}
	const authenticated = sessionStorage.getItem('isAuthenticated') === 'True';
	const pinAuthorized = sessionStorage.getItem('isPinAuthorized') === 'True';
	return (
		<Router>
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/waitlist" element={<Waitlist />} />
				<Route
					path="/space"
					element={
						authenticated ? <Dashboard /> : <Navigate to="/" />
					}
				/>
				<Route
					path="/space/gringotts"
					element={
						authenticated ? (
							<LockScreen />
						) : (
							<Navigate to="/waitlist" />
						)
					}
				/>
				<Route
					path="/space/settings"
					element={
						sessionStorage.getItem('isAuthenticated') === 'True' &&
						sessionStorage.getItem('IsPinAuthorized') ? (
							<AccountSettingsPage tab="settings" />
						) : (
							<Navigate
								to={
									sessionStorage.getItem(
										'isAuthenticated'
									) === 'True' || isAuthenticated
										? '/space/gringotts'
										: '/'
								}
							/>
						)
					}
				/>
				<Route
					path="/space/relax"
					element={
						sessionStorage.getItem('isAuthenticated') === 'True' &&
						authorized ? (
							<Relaxpage />
						) : (
							<Navigate
								to={
									sessionStorage.getItem(
										'isAuthenticated'
									) === 'True' || isAuthenticated
										? '/space/gringotts'
										: '/'
								}
							/>
						)
					}
				/>
				<Route
					path="/space/breathe"
					element={
						sessionStorage.getItem('isAuthenticated') === 'True' &&
						authorized ? (
							<BreathePage />
						) : sessionStorage.getItem('isAuthenticated') ===
								'True' || isAuthenticated ? (
							<Navigate to="/space/authorize" />
						) : (
							<Navigate to="/space/login" />
						)
					}
				/>
			</Routes>
		</Router>
	);
};

export default function App() {
	const loc = window.location;
	const baseUrl = `${loc.protocol}//${loc.hostname}${
		loc.hostname === 'localhost' ? ':8080' : ''
	}`;
	axios.defaults.baseURL = baseUrl;

	// const { login, getToken } = useAuth();
	// const navigate = useNavigate();
	// const [searchParams] = useSearchParams();

	// React.useEffect(() => {
	// 	const handleRedirect = async () => {
	// 		try {
	// 			const code = searchParams.get('code');
	// 			if (code) {
	// 				// Exchange the authorization code for an access token
	// 				await getToken();
	// 				navigate('/space/protected');
	// 			} else {
	// 				// No code, so redirect to login
	// 				await login();
	// 			}
	// 		} catch (error) {
	// 			console.error('Error handling redirect:', error);
	// 		}
	// 	};

	// 	handleRedirect();
	// }, [searchParams, getToken, login]);
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<AppRoutes />
			</PersistGate>
		</Provider>
	);
}
